import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import {
  Tab,
  AppBar,
  Tabs,
  makeStyles,
  Box
} from '@material-ui/core';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { language } from 'config';
import { FirebaseContext } from 'common';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    backgroundColor: 'red'
  },
  inkBar: {
    backgroundColor: 'yellow'
  }
}));

const BookingHistory = () => {
  const { api } = useContext(FirebaseContext);
  const {
    addLocalFare,
    updateFare,
    deleteFare,
    addOutstationFare,
    addRentalFare
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [local, setLocal] = useState([]);
  const [outStation, setOutstation] = useState([]);
  const [rental, setRental] = useState([]);
  const [value, setValue] = useState(0);
  const [cars, setCars] = useState({});
  const farelistdata = useSelector(state => state.faredata);
  const cartypelistdata = useSelector(state => state.cartypes);

  useEffect(() => {
    if (cartypelistdata.cars) {
      let obj = {};
      cartypelistdata.cars.map((car) => {
        let name = car.name.toLowerCase();;
        obj[name] = car.name;
        return name;
      });
      setCars(obj);
    }
  }, [cartypelistdata.cars]);

  const localColumns = [
    { title: language.car_type, field: 'carType', lookup: cars }, //cartype
    { title: language.fare_type, field: 'fareType', lookup: { 'min': "Minimum Fare", 'base': "Base Fare", 'km': "KM", 'minute': "Minutes", 'allowance': 'Allowance', 'night_allowance': 'Night Allowance' } }, //min, base, km, minute, allowances
    { title: language.trip_type, field: 'tripType', lookup: { 'local': "Local", 'outstation': "OutStation", 'rental': "Rental" } }, // local, outstation, rental
    { title: language.trip_startkm, field: 'tripStartKM', type: "numeric" }, // default 0
    { title: language.trip_endkm, field: 'tripEndKM', type: "numeric" }, // default 0
    { title: language.fare_title, field: 'fare', type: "numeric" }, // default 0
  ];

  const outstationColumns = [
    { title: language.car_type, field: 'carType', lookup: cars }, //cartype
    { title: language.fare_type, field: 'fareType', lookup: { 'min': "Minimum Fare", 'base': "Base Fare", 'km': "KM", 'minute': "Minutes", 'allowance': 'Allowance', 'night_allowance': 'Night Allowance' } }, //min, base, km, minute, allowances
    { title: language.trip_type, field: 'tripType', lookup: { 'local': "Local", 'outstation': "OutStation", 'rental': "Rental" } }, // local, outstation, rental
    { title: language.trip_day, field: 'dayTrip', type: "numeric" },
    { title: language.trip_km, field: 'tripKM', type: "numeric" },
    { title: language.trip_hours, field: 'tripHours', type: "numeric" },
    { title: language.trip_minutes, field: 'tripMinutes', type: "numeric" },
    { title: language.fare_title, field: 'fare', type: "numeric" }, // default 0
  ];

  const rentalColumns = [
    { title: language.car_type, field: 'carType', lookup: cars }, //cartype
    { title: language.fare_type, field: 'fareType' },
    { title: language.trip_type, field: 'tripType', lookup: { 'local': "Local", 'outstation': "OutStation", 'rental': "Rental" } }, // local, outstation, rental
    { title: language.trip_km, field: 'tripKM', type: "numeric" },
    { title: language.trip_hours, field: 'tripHours', type: "numeric" },
    { title: language.after_km, field: 'afterPerKM', type: "numeric" },
    { title: language.after_min, field: 'afterPerMin', type: "numeric" },
    { title: language.fare_title, field: 'fare', type: "numeric" }, // default 0
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (farelistdata.fare.fare) {
      const localData = farelistdata.fare.fare.filter(item => item.tripType === 'local');
      setLocal(localData);
      const outstationData = farelistdata.fare.fare.filter(item => item.tripType === 'outstation');
      setOutstation(outstationData);
      const rentalData = farelistdata.fare.fare.filter(item => item.tripType === 'rental');
      setRental(rentalData);
    }
  }, [farelistdata.fare.fare]);

  return (
    farelistdata.fare.loading ? <CircularLoading /> :
      <div className={classes.root}>
        <AppBar position="static" style={{ background: 'white' }}>
          <Tabs value={value} onChange={handleChange} indicatorColor={'primary'} textColor={'primary'}>
            <Tab label="Local" {...a11yProps(0)} />
            <Tab label="Rental" {...a11yProps(1)} />
            <Tab label="Outstation" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MaterialTable
            title={""}
            columns={localColumns}
            data={local}
            options={{
              sorting: true,
              actionsColumnIndex: 0,
              pageSize: 10
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  newData['createdByAdmin'] = true;
                  newData['usertype'] = 'fleetadmin';
                  newData['createdAt'] = new Date().toISOString();
                  dispatch(addLocalFare(newData));
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  resolve();
                  dispatch(updateFare(newData));
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteFare(oldData.id));
                  }, 600);
                })
              ,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MaterialTable
            title={""}
            columns={rentalColumns}
            data={rental}
            options={{
              sorting: true,
              actionsColumnIndex: 0,
              pageSize: 10
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  newData['createdByAdmin'] = true;
                  newData['usertype'] = 'fleetadmin';
                  newData['createdAt'] = new Date().toISOString();
                  dispatch(addRentalFare(newData));
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(updateFare(newData, oldData));
                  }, 600);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteFare(oldData.id));
                  }, 600);
                })
              ,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MaterialTable
            title={""}
            columns={outstationColumns}
            data={outStation}
            options={{
              sorting: true,
              actionsColumnIndex: 0,
              pageSize: 10
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  newData['createdByAdmin'] = true;
                  newData['usertype'] = 'fleetadmin';
                  newData['createdAt'] = new Date().toISOString();
                  dispatch(addOutstationFare(newData));
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(updateFare(newData, oldData));
                  }, 600);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteFare(oldData.id));
                  }, 600);
                })
              ,
            }}
          />
        </TabPanel>
      </div>
  );
}

export default BookingHistory;
