import { defaultFont } from "assets/jss/material-kit-react.js";

const headerTransparentStyle = theme => ({
    list: {
        ...defaultFont,
        fontSize: "14px",
        margin: 0,
        paddingLeft: "0",
        listStyle: "none",
        paddingTop: "0",
        paddingBottom: "0",
        color: "inherit"
    },
    listItem: {
        float: "left",
        color: "inherit",
        position: "relative",
        display: "block",
        width: "auto",
        margin: "0",
        padding: "0",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            "&:after": {
                width: "calc(100% - 30px)",
                content: '""',
                display: "block",
                height: "1px",
                marginLeft: "15px",
                backgroundColor: "#e5e5e5"
            }
        }
    },
    navLink: {
        "@media (max-width: 480px)": {
            color: "blue",
        },
        color: "#ffffff",
        position: "relative",
        fontFamily: 'Poppins',
        //padding: "0.9375rem",
        fontWeight: "400",
        fontSize: "18px",
        textTransform: "capitalize",
        borderRadius: "3px",
        lineHeight: "20px",
        margin: "5px",
        display: "inline-flex",
        "&:hover,&:focus": {
            color: "blue",
            background: "rgba(200, 200, 200, 0.2)",
            "@media (max-width: 480px)": {
                color: "black",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            marginLeft: "15px",
            marginBottom: "8px",
            marginTop: "8px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start"
            }
        }
    },
    button: {
        color: "blue",
        position: "relative",
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: "13px",
        textTransform: "uppercase",
        borderRadius: "3px",
        lineHeight: "20px",
        margin: "5px",
        display: "inline-flex",
        "&:hover,&:focus": {
            color: "white",
            background: "blue",
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            marginLeft: "15px",
            marginBottom: "8px",
            marginTop: "8px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start"
            }
        }
    }
});

export default headerTransparentStyle;
