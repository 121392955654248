import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import {
  Select,
  MenuItem,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  language
} from 'config';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
}));


export default function DriversStatus() {
  const classes = useStyles();
  const { api } = useContext(FirebaseContext);
  const {
    filterDriverStatus,
  } = api;
  //selected driver
  const [driver, setDriver] = useState(language.all_drivers);
  //dropdown drivers
  const usersdata = useSelector(state => state.usersdata);
  const [data, setData] = useState([]);
  const [hours, setHours] = useState(0);
  //driver status for table
  const statusdata = useSelector(state => state.statusdata);
  const [driverStatusData, setDriverStatusData] = useState([]);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();


  const getDateString = (date) => {
    let d = null;
    d = date ? new Date(date) : new Date();

    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      mins = d.getMinutes();
    if (month >= 1 & month <= 9)
      month = '0' + month.toString();
    if (day >= 0 & day <= 9)
      day = '0' + day.toString();
    if (hours >= 0 & hours <= 9)
      hours = '0' + hours.toString();
    if (mins >= 0 & mins <= 9)
      mins = '0' + mins.toString();

    return [year, month, day].join('-') + 'T' + [hours, mins].join(':');
  }
  const [selectedDate, setSelectedDate] = useState(getDateString());
  const [selectedLoginDate, setSelectedLoginDate] = useState("");
  const [selectedLogoutDate, setSelectedLogoutDate] = useState(getDateString());

  const handleDriverSelect = (event) => {
    setDriver(event.target.value);
    if (event.target.value === language.all_drivers) {
      dispatch(filterDriverStatus("", "", ""));
    } else {
      dispatch(filterDriverStatus(event.target.value, "", ""));
    }
  };

  const onDateChange = (event) => {
    var formatdate = new Date(event.target.value).toLocaleString();
    setSelectedDate(event.target.value);
    setSelectedLoginDate(formatdate);
  };

  const onLogoutDateChange = (event) => {
    var formatdate = new Date(event.target.value).toLocaleString();
    setSelectedLogoutDate(event.target.value);
    if (driver === language.all_drivers) {
      dispatch(filterDriverStatus("", selectedLoginDate, formatdate));
    } else {
      dispatch(filterDriverStatus(driver, selectedLoginDate, formatdate));
    }
  };

  useEffect(() => {
    if (usersdata.users) {
      const drv = usersdata.users.filter(user => user.usertype === 'driver' && user.approved === true && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin'));
      setData(drv);
    } else {
      setData([]);
    }
  }, [usersdata.users, auth.info.profile.usertype, auth.info.uid]);

  useEffect(() => {
    if (statusdata.driverstatus) {
      var rowInsert = false;
      var milliseconds = 0;
      statusdata.driverstatus.map((item, index) => {
        if (!rowInsert) {
          var inDate = new Date(item.date);
          var outDate = "";
          if (statusdata.driverstatus[index + 1]) {
            outDate = new Date(statusdata.driverstatus[index + 1].date);
            milliseconds += outDate.getTime() - inDate.getTime();
          }
          rowInsert = true;
        } else {
          rowInsert = false;
        }
        return "";
      });
      setHours(getTotalHours(milliseconds));
      setDriverStatusData(statusdata.driverstatus);
    } else {
      setDriverStatusData([]);
    }
  }, [statusdata]);

  const getTotalHours = (milliseconds) => {
    var seconds = Math.floor((milliseconds / 1000) % 60);
    var minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    var hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    var total = `00 : 00 : 00`;
    if (hours > 0 && minutes > 0 && seconds > 0) {
      var hr = hours < 10 ? `0${hours}` : `${hours}`;
      var mn = minutes < 10 ? `0${minutes}` : `${minutes}`;
      var sc = seconds < 10 ? `0${seconds}` : `${seconds}`;
      total = `${hr} : ${mn} : ${sc}`;
    } else if (hours === 0 && minutes > 0 && seconds > 0) {
      var min = minutes < 10 ? `0${minutes}` : `${minutes}`;
      var sec = seconds < 10 ? `0${seconds}` : `${seconds}`;
      total = `00 : ${min} : ${sec}`;
    } else if (hours === 0 && minutes === 0 && seconds > 0) {
      total = seconds < 10 ? `00 : 00 : 0${seconds}` : `00 : 00 : ${seconds}`;
    }
    return total.toString();
  }

  const columns = [
    { title: language.driver_name, field: 'driver_email', editable: 'never' },
    { title: language.type, field: 'type', editable: 'never' },
    { title: language.date, field: 'date', editable: 'never', render: rowData => rowData.date ? new Date(rowData.date).toLocaleString() : "" },
  ];

  return (
    usersdata.loading ? <CircularLoading /> :
      <div style={{ background: 'white' }}>
        <Grid container spacing={2}>
          <Grid item xs={5} sm={4}>
            {data ?
              <Select
                id="driver-native"
                value={driver}
                onChange={handleDriverSelect}
                variant="outlined"
                fullWidth
                className={driver === language.all_drivers ? classes.inputdimmed : classes.input}
              >
                <MenuItem value={language.all_drivers} key={language.all_drivers}>
                  {language.all_drivers}
                </MenuItem>
                {
                  data.map((drv) =>
                    <MenuItem key={drv.firstName + ' ' + drv.lastName} value={drv.email}>{drv.firstName + ' ' + drv.lastName}</MenuItem>
                  )
                }
              </Select>
              : null}
          </Grid>
          <Grid item xs={5} sm={4}>
            <TextField
              id="logintime-local"
              label={language.login_time}
              type="datetime-local"
              variant="outlined"
              fullWidth
              className={classes.commonInputStyle}
              InputProps={{
                className: classes.input
              }}
              value={selectedDate}
              onChange={onDateChange}
            />
          </Grid>
          <Grid item xs={5} sm={4}>
            <TextField
              id="logouttime-local"
              label={language.logout_time}
              type="datetime-local"
              variant="outlined"
              fullWidth
              className={classes.commonInputStyle}
              InputProps={{
                className: classes.input
              }}
              value={selectedLogoutDate}
              onChange={onLogoutDateChange}
            />
          </Grid>
        </Grid>
        {driverStatusData.length > 0 ?
          <MaterialTable
            title={"Total Hours: " + hours}
            options={{
              search: false,
              filtering: false,
              pageSize: 10
            }}
            columns={columns}
            style={{ textAlign: 'center' }}
            data={driverStatusData}
          />
          : null}
      </div>
  );
}
