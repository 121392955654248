import React, { useState, useEffect, useContext, useCallback } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CircularLoading from "../components/CircularLoading";
import { Grid, Button, TextField, Typography, Select, MenuItem, InputLabel } from '@material-ui/core';
import {
  features,
  language
} from 'config';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Transactions() {
  const { api, transactionsRef } = useContext(FirebaseContext);
  const {
    addTransaction,
    updateVehicleCommission
  } = api;

  const transdata = useSelector(state => state.transdata);
  const operatorsdata = useSelector(state => state.usersdata.users.filter(item => item.usertype === 'operator'));
  const vehiclesdata = useSelector(state => state.vehicledata);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState({
    operator: 'select',
    vehicle: 'select',
    commission: 0,
    credit: 0
  });
  const [clicked, setClicked] = useState(true);
  const [vehicle, setVehicle] = useState([]);

  const operatorRows = operatorsdata.map((item, index) => <MenuItem key={index} value={item.id}>{item.firstName + ' ' + item.lastName}</MenuItem>);

  const vehicleRows = vehicle && vehicle.length > 0 ? vehicle.map((item, index) => <MenuItem key={index} value={item.id}>{item.vehicleNo}</MenuItem>) : [];


  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    let transObj = {
      operatorId: data.operator,
      vehicleId: data.vehicle,
      commissionFee: data.credit,
      status: 'credit',
      date: new Date().toString()
    }
    dispatch(addTransaction(transObj));
    let vehicleInfo = vehiclesdata.vehicles.filter(item => item.id === data.vehicle);
    let total = data.commission - data.credit;
    if (vehicleInfo[0].commission_fees) {
      total = Number(vehicleInfo[0].commission_fees) - data.credit;
    }
    vehicleInfo[0].commission_fees = total;
    dispatch(updateVehicleCommission(vehicleInfo[0], vehicleInfo[0]));
    setData({
      operator: 'select',
      vehicle: 'select',
      commission: 0,
      credit: 0
    });
    alert('Amount credited');
  }

  const handleTextChange = (e) => {
    if (e.target.name === 'credit') {
      setClicked(false);
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    if (event.target.name === 'operator') {
      setData({ ...data, [event.target.name]: event.target.value, ['vehicle']: 'select', ['commission']: 0, ['credit']: 0 });
    } else if (event.target.name === 'vehicle') {
      let vehicleInfo = vehiclesdata.vehicles.filter(item => item.id === event.target.value);
      let transInfo = transdata.transactions.filter(item => item.vehicleId === event.target.value && item.operatorId === data.operator);
      let transComissionFees = getTransactionCommissionFees(transInfo);
      setData({ ...data, [event.target.name]: event.target.value, ['commission']: vehicleInfo[0].commission_fees ? Number(vehicleInfo[0].commission_fees) : transComissionFees, ['credit']: 0 });
    } else {
      setData({ ...data, [event.target.name]: event.target.value, ['commission']: 0, ['credit']: 0 });
    }
  };

  const getTransactionCommissionFees = (transArray) => {
    let transTotal = 0;
    transArray.map((transItem, index) => {
      if (index === 0) {
        transTotal = transItem.status === 'debit' ? Number(transItem.commissionFee) : -Number(transItem.commissionFee);
      } else {
        if (transItem.status === 'debit') {
          transTotal = transTotal + Number(transItem.commissionFee);
        } else {
          transTotal = transTotal - Number(transItem.commissionFee);
        }
      }
    });
    return transTotal;
  }

  useEffect(() => {
    if (data.operator !== 'select') {
      const arr = vehiclesdata.vehicles.filter(item => item.operator === data.operator);
      setVehicle(arr);
    }
  }, [data.operator]);


  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Typography component="h1" variant="h5">
            {'Select Operator'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={9} lg={4}>
          <Select
            id="operator"
            name="operator"
            required
            fullWidth
            value={data.operator}
            onChange={handleChange}
          >
            <MenuItem value={'select'}>Select Operator</MenuItem>
            {operatorRows}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 50 }}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Typography component="h1" variant="h5">
            {'Select Vehicle'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={9} lg={4}>
          <Select
            id="vehicle"
            name="vehicle"
            required
            fullWidth
            value={data.vehicle}
            onChange={handleChange}
          >
            <MenuItem value={'select'}>Select Vehicle</MenuItem>
            {vehicleRows}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 50, alignItems: 'center' }}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Typography component="h1" variant="h5">
            {'Total Commission'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={9} lg={4}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled
            id="commission"
            label={""}
            name="commission"
            autoComplete="commission"
            onChange={handleTextChange}
            value={data.commission}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 50, alignItems: 'center' }}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Typography component="h1" variant="h5">
            {'Credit Amount'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={9} lg={4}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="credit"
            label={""}
            name="credit"
            autoComplete="credit"
            onChange={handleTextChange}
            value={data.credit}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Button
            disabled={clicked}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {language.submit}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}