import React, { useContext } from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {
  language,
  features
} from 'config';
import assets from 'assets';
import { FirebaseContext } from 'common';

function AppMenu(props) {
  const { api } = useContext(FirebaseContext);
  const { isOpen, requestmodalclose } = props
  const {
    signOut
  } = api;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
  let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
  let isOperator = auth.info && auth.info.profile && auth.info.profile.usertype === 'operator';
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#414cf7' }}>
        <img style={{ marginTop: '20px', marginBottom: '20px', width: '120px', height: '120px' }} src={assets.logo192x192_white} alt="Logo" />
      </div>
      <Divider />
      <MenuList>
        {features.WebsitePagesEnabled ?
          <MenuItem component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.home}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isFleetAdmin || isOperator ?
          <MenuItem component={Link} to="/dashboard" onClick={requestmodalclose}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.dashboard_text}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/bookings" onClick={requestmodalclose}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.booking_history}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/upcomingbookings" onClick={requestmodalclose}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.upcomingbookinglable}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/addbookings" onClick={requestmodalclose}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.addbookinglable}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/riders" onClick={requestmodalclose}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.riders}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isFleetAdmin || isOperator ?
          <MenuItem component={Link} to="/drivers" onClick={requestmodalclose}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.drivers}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isFleetAdmin ?
          <MenuItem component={Link} to="/operators" onClick={requestmodalclose}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.operator}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isFleetAdmin || isOperator ?
          <MenuItem component={Link} to="/vehicles" onClick={requestmodalclose}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.vehicle}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isFleetAdmin ?
          <MenuItem component={Link} to="/driverstatus" onClick={requestmodalclose}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.driverstatus}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/fleetadmins" onClick={requestmodalclose}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.fleetadmins}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/cartypes" onClick={requestmodalclose}>
            <ListItemIcon>
              <CarIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.car_type}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/transactions" onClick={requestmodalclose}>
            <ListItemIcon>
            <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.transactions}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/cancelreasons" onClick={requestmodalclose}>
            <ListItemIcon>
              <CarIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.cancellation_reasons}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/earningreports" onClick={requestmodalclose}>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.earning_reports}</Typography>
          </MenuItem>
          : null}
        {isAdmin || isFleetAdmin ?
          <MenuItem component={Link} to="/driverearning" onClick={requestmodalclose}>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.driver_earning}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/addtowallet" onClick={requestmodalclose}>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.add_to_wallet}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/withdraws" onClick={requestmodalclose}>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.withdraws}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/promos" onClick={requestmodalclose}>
            <ListItemIcon>
              <OfferIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.promo}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/notifications" onClick={requestmodalclose}>
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.push_notification_title}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/settings" onClick={requestmodalclose}>
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.settings_title}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/fare" onClick={requestmodalclose}>
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.fare_title}</Typography>
          </MenuItem>
          : null}
        <MenuItem component={Link} to="/profile" onClick={requestmodalclose}>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.profile}</Typography>
        </MenuItem>
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;