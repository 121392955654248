import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
  features,
  language
} from 'config';
import { useDispatch, useSelector } from "react-redux";

import Add from '@material-ui/icons/Add';
import BorderColor from '@material-ui/icons/BorderColor';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularLoading from "../components/CircularLoading";
import Delete from '@material-ui/icons/Delete';
import { FirebaseContext } from 'common';
import FormControl from '@material-ui/core/FormControl';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import SearchIcon from "@material-ui/icons/Search";
import Select from '@material-ui/core/Select';
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  edit: {
    padding: 10,
    marginLeft: 10,
  },
  searchInput: {
    display: 'flex',
    flex: 0.2,
    "@media (max-width: 480px)": {
      display: 'block',
      flex: 0.9
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    textTransform: 'capitalize',
    width: 50,
    fontWeight: '700',
  },
  imgStyle: {
    height: 80,
    width: 80,
    marginRight: 10
  },
  paper2: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-20px',
    right: '-20px',
    overflow: 'scroll',
    "@media (max-width: 480px)": {
      right: 0,
      // margin: 10
    },
  },
  paper: {
    width: '30%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 10,
    "@media (max-width: 480px)": {
      width: '100%'
    }
  },
  paper1: {
    backgroundColor: '#ffffff',
    boxShadow: "10px 20px 30px #000000",
    padding: 3,
    "@media (max-width: 480px)": {
      width: 'auto'
    },
    width: '30%',
  },
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15
  },
  Modal1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sure: {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 15,
    marginLeft: 15
  },
  formControl: {
    display: 'flex',
    marginTop: 10,
  },
  join: {
    display: "flex"
  },
  grid: {
    paddingTop: 10,
    paddingBottom: 10
  },
  box1: {
    padding: 7,
    marginTop: 5,
    alignSelf: 'center',
  },
  button: {
    textTransform: 'capitalize',
    padding: 10,
    paddingRight: 30,
    paddingLeft: 30,
    marginBottom: 10,
    marginTop: 10,
    width: 100
  },
  split: {
    "@media (max-width: 480px)": {
      display: 'block',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  typoText1: {
    fontSize: 18,
    color: 'grey',
    fontWeight: '700',
    flex: 0.5,
    marginTop: 10
  },
  typoText2: {
    fontSize: 18,
    color: 'grey',
    flex: 0.5,
    marginTop: 10
  }
}));

const Drivers = props => {

  const { api, driverDocsRef, imageStorageRef, singleUserRef } = useContext(FirebaseContext);
  const {
    emailSignUp,
    editUser,
    deleteUser,
    checkUserExists
  } = api;
  const { className, ...rest } = props;

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [licenseFrontImage, setLicenseFrontImage] = useState([]);
  const [licenseBackImage, setLicenseBackImage] = useState([]);
  const [profileImage, setProfileImage] = useState([]);

  const [aadharFrontImage, setAadharFrontImage] = useState([]);
  const [aadharBackImage, setAadharBackImage] = useState([]);
  const [load, setLoad] = useState(false);

  const [licenseFrontLoad, setLicenseFrontLoad] = useState(false);
  const [licenseBackLoad, setLicenseBackLoad] = useState(false);
  const [profileLoad, setProfileLoad] = useState(false);

  const [aadharFrontLoad, setAadharFrontLoad] = useState(false);
  const [aadharBackLoad, setAadharBackLoad] = useState(false);

  const usersdata = useSelector(state => state.usersdata);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    driverLicense: '',
    aadharNumber: '',
    email: '',
    licenseBackImage: '',
    profileImage: '',
    dob: '',
    approved: true,
    driverActiveStatus: true,
    approvalStatus: 'pending',
    operator: '',
    mobile: '+91'
  });

  const [drvLcns, setDrvLcns] = useState('')
  const [letter, setLetter] = useState(true)
  const [isedit, setIsEdit] = useState(false)
  const [confirmdrvLcns, setConfirmDrvLcns] = useState('')
  const [error, setError] = useState(false)
  const [lengthError, setLengthError] = useState(false)
  const [driverLicenseData, setDriverLicenseData] = useState([]);
  const [driverId, setDriverId] = useState('');
  const [disable, setDisable] = useState(false);
  const [pressed, setPressed] = useState(false)
  const [operators, setOperators] = useState({});
  const refAf = React.useRef();
  const refAb = React.useRef();
  const refPf = React.useRef();
  const refLf = React.useRef();
  const refLb = React.useRef();

  const history = useHistory()

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace('/vehicles');
      }
    };
  }, [history])

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)"
  });

  useEffect(() => {
    if (auth.info.profile.usertype === 'operator') {
      setDisable(true)
    } else {
      setDisable(false)
    }
    if (usersdata.users) {
      const driver = usersdata.users.filter(user => user.usertype === 'driver' && (user.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin'));
      var resultData = []
      var arr = []
      var arr1 = []
      driver.filter((data) => {
        if (data.approvalStatus === 'pending') {
          arr.push(data)
        } else {
          arr1.push(data)
        }
      })
      if (arr1.length > 0) {
        arr1.map((item) => {
          resultData = [...arr, ...arr1]
        })
      }
      setData(resultData);
      // setData(driver);
    } else {
      setData([]);
    }
    if (usersdata.users && auth.info) {
      const driver = usersdata.users.filter(user => user.usertype === 'driver');
      let arr = []
      driver.map((item) => {
        let obj = {}
        obj[item.id] = item.driverLicense
        arr.push(obj)
        setDriverLicenseData(arr)
      })
    }

    const operator = usersdata.users.filter(user => user.usertype === 'operator' && (user.id === auth.info.uid || auth.info.profile.usertype === 'admin'));
    let opObj = {};
    operator.map((op) => {
      const fullname = op.firstName + ' ' + op.lastName;
      let name = op.id
      opObj[name] = fullname;
      return name;
    });
    setOperators(opObj);

  }, [usersdata.users, auth.info.profile.usertype, auth.info.uid]);

  const onLicenseChange = (event) => {
    setLicenseFrontLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'licenseFrontImage';
        await driverDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await driverDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setLicenseFrontImage(blob);
        setLicenseFrontLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onLicenseBackChange = (event) => {
    setLicenseBackLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'licenseBackImage';
        await driverDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await driverDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setLicenseBackImage(blob);
        setLicenseBackLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onProfileChange = (event) => {
    setProfileLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'profileImage';
        await driverDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await driverDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setProfileImage(blob);
        setProfileLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onAadharBackChange = (event) => {
    setAadharBackLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'aadharBackImage';
        await driverDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await driverDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setAadharBackImage(blob);
        setAadharBackLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onAadharFrontChange = (event) => {
    setAadharFrontLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'aadharFrontImage';
        await driverDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await driverDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setAadharFrontImage(blob)
        setAadharFrontLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const columns = [
    { title: 'Action' },
    { title: language.mobile, field: 'mobile' },
    { title: language.operator, field: 'operator' },
    { title: language.first_name, field: 'firstName' },
    { title: language.last_name, field: 'lastName' },
    { title: language.driver_licenseno, field: 'driverLicense' },
    // { title: language.confirmdriver_licenseno, field: 'confirmDriverLicense', editable: 'onAdd', hidden: true },
    { title: language.aadhar_number, field: 'aadharNumber' },
    { title: language.aadhar_front, field: 'aadharFrontImage' },
    { title: language.aadhar_back, field: 'aadharBackImage' },
    { title: language.license_front, field: 'licenseFrontImage' },
    { title: language.license_back, field: 'licenseBackImage' },
    { title: language.profile_image, field: 'profileImage' },
    { title: language.email, field: 'email' },
    { title: language.dob, field: 'dob' },
    { title: language.account_approve, field: 'approved' },
    { title: language.driver_active, field: 'driverActiveStatus' },
    { title: 'Approval Status', field: 'approvalStatus' }
  ];

  const lookup = (item, data) => {
    var name = ''
    Object.keys(data).map((res, i) => (res === item ? name = Object.values(data)[i] : ''))
    return name
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const emptyValue = () => {
    setValues({
      firstName: '',
      lastName: '',
      driverLicense: '',
      aadharNumber: '',
      email: '',
      licenseBackImage: '',
      profileImage: '',
      dob: '',
      mobile: '+91'
    })
    setProfileImage([])
    setLicenseFrontImage([])
    setLicenseBackImage([])
    setAadharFrontImage([])
    setAadharBackImage([])
    setDrvLcns('')
    setConfirmDrvLcns('')
    setIsEdit(false)
    setError(false)
    setLengthError(false)
  }

  const editOpenClose = () => {
    setEditOpen(false)
    emptyValue()
    setId('')
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
        if (profileImage.length !== 0) { deleteImageCancel(profileImage) }
        if (licenseFrontImage.length !== 0) { deleteImageCancel(licenseFrontImage) }
        if (licenseBackImage.length !== 0) { deleteImageCancel(licenseBackImage) }
        if (aadharFrontImage.length !== 0) { deleteImageCancel(aadharFrontImage) }
        if (aadharBackImage.length !== 0) { deleteImageCancel(aadharBackImage) }
        // if (liveImage.length !== 0) { deleteImageCancel(liveImage) }
        // if (nameVerification.length !== 0) { deleteImageCancel(nameVerification) }
      }, 600);
    })
  }

  const viewOpenClose = () => {
    setViewOpen(false)
    setId('')
  }

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setId('')
    emptyValue()
  }

  const handleOpenModal = (id) => {
    setDeleteModal(true);
    setId(id)
  };

  const connect = (item) => {
    setValues({
      ...values,
      mobile: item.mobile,
      firstName: item.firstName,
      lastName: item.lastName,
      driverLicense: item.driverLicense,
      aadharNumber: item.aadharNumber,
      aadharFrontImage: item.aadharFrontImage ? item.aadharFrontImage : '',
      aadharBackImage: item.aadharBackImage ? item.aadharBackImage : '',
      licenseFrontImage: item.licenseFrontImage ? item.licenseFrontImage : '',
      email: item.email,
      licenseBackImage: item.licenseBackImage ? item.licenseBackImage : '',
      profileImage: item.profileImage ? item.profileImage : '',
      dob: item.dob,
      approved: item.approved,
      driverActiveStatus: item.driverActiveStatus,
      approvalStatus: item.approvalStatus ? item.approvalStatus : 'pending',
      createdByAdmin: item.createdByAdmin ? item.createdByAdmin : false,
      usertype: item.usertype ? item.usertype : '',
      createdAt: item.createdAt ? item.createdAt : '',
      referralId: item.referralId ? item.referralId : '',
      fleetadmin: item.fleetadmin ? item.fleetadmin : '',
      operator: item.fleetadmin ? item.fleetadmin : '',
      queue: item.queue ? item.queue : false,
      carType: item.carType ? item.carType : '',
      priority: item.priority ? item.priority : ''
    });
    setProfileImage([])
    setLicenseFrontImage([])
    setLicenseBackImage([])
    setAadharFrontImage([])
    setAadharBackImage([])
    setId(item)
    setDrvLcns(item.driverLicense)
    setConfirmDrvLcns(item.driverLicense)
  }

  const checkLicense = (event, name) => {
    var driverLicense = event.target.value
    setLetter(false)
    var name1 = name === 'drvLcns' ? driverLicense : drvLcns
    var name2 = name === 'confirmdrvLcns' ? driverLicense : confirmdrvLcns
    if (name === 'drvLcns') {
      setDrvLcns(driverLicense)
    } else {
      setConfirmDrvLcns(driverLicense)
    }
    if (name1 === name2) {
      setError(false)
      setValues({
        ...values, driverLicense: driverLicense
      })
    } else {
      setError(true)
    }
    setLengthError(false)
    if (!isedit && auth.info.profile.usertype === 'operator') {
      var ids = ''
      driverLicenseData.map((item) => {
        if (Object.values(item)[0] ? Object.values(item)[0].toLowerCase() === driverLicense.toLowerCase() && name1 === name2 : '') {
          setDriverId(Object.keys(item)[0])
          ids = Object.keys(item)[0]
        }
      })
      if (name1 === name2 && ids !== '') {
        singleUserRef(ids).on('value', function (snapshot) {
          connect(snapshot.val())
          setDisable(true)
        })
      } else {
        setValues({
          driverLicense: driverLicense,
          firstName: '',
          lastName: '',
          aadharNumber: '',
          email: '',
          dob: '',
          approved: false,
          driverActiveStatus: true,
          mobile: '+91',
        })
        // setDate('')
        setProfileImage([])
        setLicenseFrontImage([])
        setLicenseBackImage([])
        setAadharFrontImage([])
        setAadharBackImage([])
        setDisable(name1.length >= 1 && name2.length >= 1 ? false : true)
        setLengthError(false)
      }
    }
  }

  const handleOpenEdit = (item) => {
    setIsEdit(true)
    setEditOpen(true);
    connect(item)
  };

  const handleOpenAdd = () => {
    setEditOpen(true);
    setValues({
      ...values,
      approved: true,
      driverActiveStatus: true,
      approvalStatus: 'pending'
    })
    setProfileImage([])
    setLicenseFrontImage([])
    setLicenseBackImage([])
    setAadharFrontImage([])
    setAadharBackImage([])
  }

  const handleOpenView = (item) => {
    setViewOpen(true)
    setId(item)
  }

  const formatDate = (item) => {
    var date = new Date(item); // Date 2011-05-09T06:08:45.178Z
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`
  }

  const aadharValidate = (newData) => {
    var aadhar = newData.aadharNumber
    var adharcardTwelveDigit = /^\d{12}$/;
    var adharSixteenDigit = /^\d{16}$/;
    if (aadhar != '') {
      if (aadhar.match(adharcardTwelveDigit)) {
        return true;
      }
      else if (aadhar.match(adharSixteenDigit)) {
        return true;
      }
      else {
        alert("Enter valid Aadhar Number");
        return false;
      }
    }
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.name === 'approved' || event.target.name === 'driverActiveStatus' ? event.target.checked : event.target.value
    });
  };

  const validation = (newData) => {
    var today = new Date();
    var birthDate = new Date(newData.dob)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (
      values.mobile === '' ||
      values.firstName === '' ||
      values.lastName === '' ||
      values.email === '' ||
      values.driverLicense === '' ||
      values.aadharNumber === '' ||
      values.dob === '' ||
      values.operator === '' ||
      drvLcns !== confirmdrvLcns
    ) {
      alert('Please Fill the Required fields')
    } else {
      if (values.driverLicense.length > 14) {
        if (aadharValidate(newData)) {
          if (age >= 21) {
            newData.dob = new Date(newData.dob).getTime();
            addNewDriver(newData)
          } else { alert('your age is greater than 21') }
        }
      } else {
        setLengthError(true)
      }
    }
  }

  const validateMobile = () => {
    let mobileValid = true;
    if (values.mobile.length < 13) {
      mobileValid = false;
      alert('Please enter a valid mobile number');
    }
    return mobileValid;
  }

  const addNewDriver = (newData) => {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        newData.licenseFrontImage = licenseFrontImage;
        newData.licenseBackImage = licenseBackImage;
        newData.profileImage = profileImage;
        newData.aadharFrontImage = aadharFrontImage;
        newData.aadharBackImage = aadharBackImage;
        // newData.liveImage = liveImage;
        // newData.nameVerification = nameVerification;
        // var today = new Date();
        // var birthDate = new Date(newData.dob)
        // var age = today.getFullYear() - birthDate.getFullYear();
        // var m = today.getMonth() - birthDate.getMonth();
        // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        //   age--;
        // }
        // if (age >= 21) {
        //   newData.dob = new Date(newData.dob).getTime();
        // } else { alert('your age is greater than 21') }
        if (validateMobile()) {
          checkUserExists(newData).then((res) => {
            if (res.users && res.users.length > 0) {
              setLoad(false)
              alert(language.user_exists);
              reject();
            }
            else if (res.error) {
              setLoad(false)
              alert(language.email_or_mobile_issue);
              reject();
            }
            else {
              newData['createdByAdmin'] = true;
              newData['usertype'] = 'driver';
              newData['createdAt'] = new Date().toISOString();
              newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
              let role = auth.info.profile.usertype;
              if (role === 'fleetadmin' || role === 'operator') {
                newData['fleetadmin'] = auth.info.uid;
              } else {
                newData['fleetadmin'] = newData.operator
              }
              emailSignUp(newData).then((res) => {
                console.log("res", res)
                setLoad(false)
                if (res.error) {
                  alert(res.error)
                } else {
                  setEditOpen(false);
                  setId('')
                  emptyValue()
                  resolve();
                }
                resolve();
              });
              //dispatch(addUser(newData));

            }
          });
        }
      }, 600);
    })
  }

  const updateDriver = (newData, oldData) => {

    new Promise(resolve => {
      setTimeout(() => {
        resolve();
        if (
          values.mobile === '' ||
          values.firstName === '' ||
          values.lastName === '' ||
          values.email === '' ||
          values.driverLicense === '' ||
          values.aadharNumber === '' ||
          values.dob === '' ||
          values.operator === '' ||
          drvLcns !== confirmdrvLcns
        ) {
          alert('Please Fill the Required fields')
        } else {
          var today = new Date();
          var birthDate = new Date(newData.dob)
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          if (values.driverLicense.length > 14) {
            if (aadharValidate(newData)) {
              if (age >= 21) {
                setLoad(true)
                newData.fleetadmin = newData.operator
                // newData.dob = new Date(newData.dob).getTime();
                // newData['createdByAdmin'] = true;
                // newData['usertype'] = 'driver';
                // newData['createdAt'] = new Date().toISOString();
                // newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                // let role = auth.info.profile.usertype;
                // if (role === 'fleetadmin' || role === 'operator') {
                //   newData['fleetadmin'] = auth.info.uid;
                // }

                if (profileImage.length !== 0) {
                  if (oldData.profileImage) {
                    imageStorageRef(oldData.profileImage).delete().then(async () => {
                      newData.profileImage = profileImage
                      dispatch(editUser(oldData.id, newData));
                    }).catch((error) => {
                      alert('error', error)
                    });
                  } else {
                    newData.profileImage = profileImage
                    dispatch(editUser(oldData.id, newData));
                  }
                }
                if (licenseFrontImage.length !== 0) {
                  if (oldData.licenseFrontImage) {
                    imageStorageRef(oldData.licenseFrontImage).delete().then(async () => {
                      newData.licenseFrontImage = licenseFrontImage
                      dispatch(editUser(oldData.id, newData));
                    }).catch((error) => {
                      alert('error', error)
                    });
                  } else {
                    newData.licenseFrontImage = licenseFrontImage
                    dispatch(editUser(oldData.id, newData));
                  }
                }
                if (licenseBackImage.length !== 0) {
                  if (oldData.licenseBackImage) {
                    imageStorageRef(oldData.licenseBackImage).delete().then(async () => {
                      newData.licenseBackImage = licenseBackImage
                      dispatch(editUser(oldData.id, newData));
                    }).catch((error) => {
                      alert('error', error)
                    });
                  } else {
                    newData.licenseBackImage = licenseBackImage
                    dispatch(editUser(oldData.id, newData));
                  }
                }
                if (aadharFrontImage.length !== 0) {
                  if (oldData.aadharFrontImage) {
                    imageStorageRef(oldData.aadharFrontImage).delete().then(async () => {
                      newData.aadharFrontImage = aadharFrontImage
                      dispatch(editUser(oldData.id, newData));
                    }).catch((error) => {
                      alert('error', error)
                    });
                  } else {
                    newData.aadharFrontImage = aadharFrontImage
                    dispatch(editUser(oldData.id, newData));
                  }
                }
                if (aadharBackImage.length !== 0) {
                  if (oldData.aadharBackImage) {
                    imageStorageRef(oldData.aadharBackImage).delete().then(async () => {
                      newData.aadharBackImage = aadharBackImage
                      dispatch(editUser(oldData.id, newData));
                    }).catch((error) => {
                      alert('error', error)
                    });
                  } else {
                    newData.aadharBackImage = aadharBackImage
                    dispatch(editUser(oldData.id, newData));
                  }
                }
                if (profileImage.length === 0 &&
                  licenseFrontImage.length === 0 &&
                  licenseBackImage.length === 0 &&
                  aadharFrontImage.length === 0 &&
                  aadharBackImage.length === 0) {
                  dispatch(editUser(oldData.id, newData));
                }
                setProfileImage([])
                setLicenseFrontImage([])
                setLicenseBackImage([])
                setAadharFrontImage([])
                setAadharBackImage([])
                setEditOpen(false);
                emptyValue()
                setId('')
                setLoad(false)
              }
              else {
                setLoad(false)
                alert('your age is greater than 21')
              }
            }
          } else {
            setLengthError(true)
          }
        }

      }, 600);
    })
  }

  const deleteRowDriver = (id) => {
    features.AllowCriticalEditsAdmin ?
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          setDeleteModal(false)
          emptyValue()
          setId('')
          dispatch(deleteUser(id));
        }, 600);
      })
      :
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          alert(language.demo_mode);
        }, 600);
      })
  }

  function deleteBody(id) {
    return (
      <div className={classes.paper1}>
        <div className={classes.sure}>
          Are you sure you Want to delete this row?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
          <Button
            className={classes.button}
            onClick={handleDeleteClose}
          >
            <p className={classes.text}>Cancel</p>
          </Button>
          <Button
            className={classes.button}
            onClick={() => deleteRowDriver(id)}
          >
            <p className={classes.text}>Delete</p>
          </Button>
        </div>
      </div>
    )
  }

  const deleteImage = (imgUrl, name) => {
    imageStorageRef(imgUrl).delete().then(async () => {
      if (name === 'aadharFrontImage') {
        setValues({
          ...values,
          aadharFrontImage: ''
        })
        setAadharFrontImage([])
        refAf.current.value = ""
      } else if (name === 'aadharBackImage') {
        setValues({
          ...values,
          aadharBackImage: ''
        })
        setAadharBackImage([])
        refAb.current.value = ""
      } else if (name === 'licenseFrontImage') {
        setValues({
          ...values,
          licenseFrontImage: ''
        })
        setLicenseFrontImage([])
        refLf.current.value = ""
      } else if (name === 'licenseBackImage') {
        setValues({
          ...values,
          licenseBackImage: ''
        })
        setLicenseBackImage([])
        refLb.current.value = ""
      } else {
        setValues({
          ...values,
          profileImage: ''
        })
        setProfileImage([])
        refPf.current.value = ""
      }
    }).catch((error) => {
      alert('error', error)
    });
  }

  function body(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 20 }}>
            <Typography style={{ fontSize: 20, color: 'grey', textAlign: 'center' }}>{item ? 'Update Drivers Data' : 'Add Drivers Data'}</Typography>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="License Number"
                  margin="dense"
                  name="driverLicense"
                  onChange={(event) => checkLicense(event, 'drvLcns')}
                  value={drvLcns}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Confirm License Number"
                  margin="dense"
                  name="driverLicense"
                  onChange={(event) => checkLicense(event, 'confirmdrvLcns')}
                  value={confirmdrvLcns}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container>
              {error || lengthError ?
                <Typography style={{ textAlign: 'center', color: 'red', marginTop: 10 }}>{lengthError ? 'Please check your license number (minimum 15 digit)' : 'License Number should be same'}</Typography>
                : null}
            </Grid>

            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter mobile No"
                  margin="dense"
                  name="mobile"
                  disabled={item ? true : false}
                  onChange={handleChange}
                  value={values.mobile}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Email"
                  margin="dense"
                  name="email"
                  type={'email'}
                  disabled={item ? true : false}
                  onChange={item ? '' : handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <FormControl variant="outlined" size='small' style={{ flex: 1, display: 'flex', marginTop: 15 }}>
              <InputLabel id="demo-simple-select-outlined-label">Select Operator</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name='operator'
                value={values.operator}
                required
                onChange={handleChange}
                label="Select Operator"
              >
                <MenuItem value="">
                  <em>Select Operator</em>
                </MenuItem>
                {Object.values(operators).map((name, i) => (
                  <MenuItem key={name} value={Object.keys(operators)[i]}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter First Name"
                  margin="dense"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                  disabled={disable}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Last Name"
                  margin="dense"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                  disabled={disable}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Aadhar Number"
                  margin="dense"
                  name="aadharNumber"
                  onChange={handleChange}
                  value={values.aadharNumber}
                  disabled={disable}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 10 }}
              >
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  name="dob"
                  type={'date'}
                  label='Date Of Birth'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={handleChange}
                  value={formatDate(values.dob)}
                  disabled={disable}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <FormControl variant="outlined" size='small' style={{ flex: 0.48, display: 'flex', marginTop: 15 }}>
              <InputLabel id="demo-simple-select-outlined-label">Approval Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name='approvalStatus'
                value={values.approvalStatus}
                required
                onChange={handleChange}
                disabled={disable}
                label="Approval Status"
              >
                <MenuItem value={'pending'}>Pending</MenuItem>
                <MenuItem value={'approved'}>Approved</MenuItem>
                <MenuItem value={'blocked'}>Blocked</MenuItem>
              </Select>
            </FormControl>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography style={{ marginTop: 10 }}>Aadhar Front Image:</Typography>
              {(values.aadharFrontImage && values.aadharFrontImage.length > 0 || aadharFrontImage && aadharFrontImage.length > 0) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => {
                  if (isedit) {
                    deleteImage(aadharFrontImage && aadharFrontImage.length > 0 ? aadharFrontImage : values.aadharFrontImage, 'aadharFrontImage')
                  } else {
                    setAadharFrontImage([])
                    setValues({
                      ...values, aadharFrontImage: ''
                    })
                    refAf.current.value = ""
                  }
                }}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.aadharFrontImage && values.aadharFrontImage.length > 0 ? (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.aadharFrontImage} className={classes.imgStyle} alt='licenseBackImage' />
                  {!disable && item ?
                    <div className={classes.box1}>
                      {aadharFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                      <input type='file'
                        name='aadharFrontImage'
                        onChange={onAadharFrontChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {aadharFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='aadharFrontImage'
                      required
                      ref={refAf}
                      onChange={onAadharFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography style={{ marginTop: 10 }}>Aadhar Back Image:</Typography>
              {(values.aadharBackImage && values.aadharBackImage.length > 0 || aadharBackImage && aadharBackImage.length > 0) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => {
                  if (isedit) {
                    deleteImage(aadharBackImage && aadharBackImage.length > 0 ? aadharBackImage : values.aadharBackImage, 'aadharBackImage')
                  } else {
                    setAadharBackImage([])
                    setValues({
                      ...values, aadharBackImage: ''
                    })
                    refAb.current.value = ""
                  }
                }}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.aadharBackImage && values.aadharBackImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.aadharBackImage} className={classes.imgStyle} alt='aadharBackImage' />
                  {!disable && item ?
                    <div className={classes.box1}>
                      {aadharBackLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                      <input type='file'
                        name='aadharBackImage'
                        onChange={onAadharBackChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {aadharBackLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='aadharBackImage'
                      required
                      ref={refAb}
                      onChange={onAadharBackChange}
                    />
                  </div>
                </Grid>
              </Grid>
            }
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography style={{ marginTop: 10 }}>license Front Image:</Typography>
              {(values.licenseFrontImage && values.licenseFrontImage.length > 0 || licenseFrontImage && licenseFrontImage.length > 0) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => {
                  if (isedit) {
                    deleteImage(licenseFrontImage && licenseFrontImage.length > 0 ? licenseFrontImage : values.licenseFrontImage, 'licenseFrontImage')
                  } else {
                    setLicenseFrontImage([])
                    setValues({
                      ...values, licenseFrontImage: ''
                    })
                    refLf.current.value = ""
                  }
                }}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.licenseFrontImage && values.licenseFrontImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.licenseFrontImage} className={classes.imgStyle} alt='licenseFrontImage' />
                  {!disable && item ?
                    <div className={classes.box1}>
                      {licenseFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                      <input type='file'
                        name='licenseFrontImage'
                        onChange={onLicenseChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {licenseFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='licenseFrontImage'
                      required
                      ref={refLf}
                      onChange={onLicenseChange}
                    />
                  </div>
                </Grid>
              </Grid>
            }
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography style={{ marginTop: 10 }}>license Image:</Typography>
              {(values.licenseBackImage && values.licenseBackImage.length > 0 || licenseBackImage && licenseBackImage.length > 0) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => {
                  if (isedit) {
                    deleteImage(licenseBackImage && licenseBackImage.length > 0 ? licenseBackImage : values.licenseBackImage, 'licenseBackImage')
                  } else {
                    setLicenseBackImage([])
                    setValues({
                      ...values, licenseBackImage: ''
                    })
                    refLb.current.value = ""
                  }
                }}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.licenseBackImage && values.licenseBackImage.length > 0 ? (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.licenseBackImage} className={classes.imgStyle} alt='licenseBackImage' />
                  {!disable && item ?
                    <div className={classes.box1}>
                      {licenseBackLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                      <input type='file'
                        name='licenseBackImage'
                        onChange={onLicenseBackChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {licenseBackLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='licenseBackImage'
                      required
                      ref={refLb}
                      onChange={onLicenseBackChange}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography style={{ marginTop: 10 }}>Profile Image:</Typography>
              {(values.profileImage && values.profileImage.length > 0 || profileImage && profileImage.length > 0) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => {
                  if (isedit) {
                    deleteImage(profileImage && profileImage.length > 0 ? profileImage : values.profileImage, 'profileImage')
                  } else {
                    setProfileImage([])
                    setValues({
                      ...values, profileImage: ''
                    })
                    refLb.current.value = ""
                  }
                }}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.profileImage && values.profileImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.profileImage} className={classes.imgStyle} alt='profileImage' />
                  {!disable && item ?
                    <div className={classes.box1}>
                      {profileLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                      <input type='file'
                        name='profileImage'
                        onChange={onProfileChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {profileLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='profileImage'
                      required
                      onChange={onProfileChange}
                    />
                  </div>
                </Grid>
              </Grid>
            }
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex' }}
              >
                <Typography style={{ alignSelf: 'center' }}>Account Approved:</Typography>
                <Checkbox
                  name="approved"
                  onChange={handleChange}
                  checked={values.approved}
                  disabled={disable && item ? true : false}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex' }}
              >
                <Typography style={{ alignSelf: 'center' }}>Driver Active Status:</Typography>
                <Checkbox
                  onChange={handleChange}
                  name="driverActiveStatus"
                  checked={values.driverActiveStatus}
                  disabled={disable && item ? true : false}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
            </Grid>
            <Button
              type='submit'
              color="primary"
              variant="contained"
              disabled={
                licenseFrontLoad === false &&
                  licenseBackLoad === false &&
                  profileLoad === false &&
                  aadharFrontLoad === false &&
                  aadharBackLoad === false ? false : true
              }
              size='small'
              className={classes.button}
              onClick={() => { id === '' ? validation(values) : updateDriver(values, item) }}
            >
              {id === '' ? 'Add' : 'Update'}
            </Button>
            <Button
              type='submit'
              variant="contained"
              size='small'
              className={classes.button}
              style={{ marginLeft: 20 }}
              onClick={() => {
                setEditOpen(false)
                setId('')
                emptyValue()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )
  };

  // const namings = (item) => {
  //   var names = []
  //   columns.map((res) => {
  //     if (res.field === item) {
  //       names.push(res.title)
  //     }
  //   })
  //   return names[0]
  // }
  // const dataValues = (item) => {
  //   var names = []
  //   columns.map((res) => {
  //     console.log(res, item)
  //     // if (res.field === item) {
  //     //   console.log(item)
  //     //   names.push(item)
  //     // }
  //   })
  //   // console.log(names)
  //   // return names[0]
  // }

  function viewModal(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 10 }}>
            <Typography style={{ marginTop: 10, fontSize: 20, color: 'grey', textAlign: 'center' }}>Driver Information</Typography>
            {/* <div style={{ display: 'flex' }}>
              <div>
                {Object.keys(item).map((res) => {
                  return (
                    <Grid
                      item
                      style={{ display: 'flex', marginTop: 10, flex: 1 }}
                    >
                      <Typography className={classes.typoText1}>{typeof res === 'string' ? namings(res) : null}</Typography>
                    </Grid>
                  )
                })}
              </div>
              <div>
                {Object.values(item).map((resp) => {
                  return (
                    <Grid
                      item
                      style={{ display: 'flex', marginTop: 10, flex: 1 }}
                    >
                      <Typography className={classes.typoText2}>:{typeof resp === 'string' ? dataValues(resp) : null}</Typography>
                    </Grid>
                  )
                })}
              </div>
            </div> */}
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>First Name</Typography>
              <Typography className={classes.typoText2}>: {item.firstName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Last Name</Typography>
              <Typography className={classes.typoText2}>: {item.lastName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Aadhar Number</Typography>
              <Typography className={classes.typoText2}>: {item.aadharNumber}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Date of Birth</Typography>
              <Typography className={classes.typoText2}>: {formatDate(item.dob)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Driver License Number</Typography>
              <Typography className={classes.typoText2}>: {item.driverLicense}</Typography>
            </Grid>
            {item.aadharFrontImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Aadhar Front Image</Typography>
                :<img src={item.aadharFrontImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.aadharBackImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Aadhar Back Image</Typography>
                :<img src={item.aadharBackImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.licenseFrontImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>license Front Image</Typography>
                :<img src={item.licenseFrontImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.licenseBackImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>license Image</Typography>
                :<img src={item.licenseBackImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.profileImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Profile Image</Typography>
                :<img src={item.profileImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Account Approved</Typography>
              <Typography className={classes.typoText2}>: {item.approved ? 'Approved' : 'Not Approved'}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Driver Active Status</Typography>
              <Typography className={classes.typoText2}>: {item.driverActiveStatus ? 'Active' : 'Inactive'}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Approval Status</Typography>
              <Typography className={classes.typoText2}>: {item.approvalStatus === 'pending' ? 'Waiting for Approval' : item.approvalStatus === 'approved' ? 'Approved' : 'Blocked'}</Typography>
            </Grid>
            <Button
              type='submit'
              color='primary'
              variant="contained"
              size='small'
              className={classes.button}
              onClick={() => {
                setViewOpen(false)
                setId('')
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </div >
    )
  };

  const date = (item) => {
    if (item !== '') {
      var date = new Date(item);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear()
    }
  }

  const preventRerender1 = (rowData) => {
    return (
      rowData ? <img alt='rc' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender2 = (rowData) => {
    return (
      rowData ? <img alt='insurance' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender3 = (rowData) => {
    return (
      rowData ? <img alt='permit' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender4 = (rowData) => {
    return (
      rowData ? <img alt='rc' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender5 = (rowData) => {
    return (
      rowData ? <img alt='insurance' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const requestSearch = (e) => {
    var value = e.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').trim()
    if (value === '') {
      const driver = usersdata.users.filter(user => user.usertype === 'driver' && (user.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin'));
      setData(driver);
    } else {
      const name = data.filter(e =>
        Object.values(e).join(" ").toLowerCase().match(value)
      )
      setData(name)
    }
  };

  const deleteImageCancel = (imgUrl) => {
    imageStorageRef(imgUrl).delete().then(async () => {
    }).catch((error) => {
      alert('error', error)
    });
  }

  return (
    load ? <CircularLoading /> :
      <div>
        <div className={classes.row}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => 
              handleOpenAdd()}
          >
            <Add fontSize='small' />
          </Button>
          <TextField
            className={classes.searchInput}
            onChange={requestSearch}
            label="Search Driver"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>

        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardContent className={classes.content}>

            <div className={classes.inner}>
              {isMobile ?
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Action</TableCell>
                      <TableCell align='center'>Mobile Number</TableCell>
                    </TableRow>
                  </TableHead>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    return (
                      <TableBody key={i}>
                        <TableRow hover>
                          <TableCell align='center' size='medium' style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip
                              id="View"
                              title={'View'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenView(item)}
                              >
                                <VisibilityIcon fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Edit"
                              title={'Edit'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenEdit(item)}
                              >
                                <BorderColor fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Delete"
                              title={'Delete'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenModal(item.id)}
                              >
                                <Delete fontSize='medium' />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell align='center'>{item.mobile}</TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
                :
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((item, i) => {
                        return (
                          <TableCell align='center' key={i}>{item.title}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    return (
                      <TableBody key={i}>
                        <TableRow hover>
                          <TableCell align='center' size='medium' style={{ display: 'flex', alignItems: 'center', height: 150 }}>
                            <Tooltip
                              id="Edit"
                              title={'Edit'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenEdit(item)}
                                className={classes.edit}
                              >
                                <BorderColor fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Delete"
                              title={'Delete'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenModal(item.id)}
                                className={classes.edit}
                              >
                                <Delete fontSize='medium' />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell align='center'>{item.mobile}</TableCell>
                          <TableCell align='center'>{lookup(item.fleetadmin, operators)}</TableCell>
                          <TableCell align='center'>{item.firstName}</TableCell>
                          <TableCell align='center'>{item.lastName}</TableCell>
                          <TableCell align='center'>{item.driverLicense}</TableCell>
                          <TableCell align='center'>{item.aadharNumber}</TableCell>
                          <TableCell align='center'>
                            {preventRerender1(item.aadharFrontImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender2(item.aadharBackImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender3(item.licenseFrontImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender4(item.licenseBackImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender5(item.profileImage)}
                          </TableCell>
                          <TableCell align='center'>{item.email}</TableCell>
                          <TableCell align='center'>{date(item.dob)}</TableCell>
                          <TableCell align='center'>
                            <Checkbox
                              name="approved"
                              disabled
                              checked={item.approved}
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </TableCell>
                          <TableCell align='center'>
                            <Checkbox
                              name="driverActiveStatus"
                              disabled
                              checked={item.driverActiveStatus}
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </TableCell>
                          <TableCell align='center'>{item.approvalStatus === 'pending' ? 'Waiting for Approval' : item.approvalStatus === 'approved' ? 'Approved' : 'Blocked'}</TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
              }
              <div>
                <Modal
                  open={viewOpen}
                  className={classes.Modal}
                  onClose={viewOpenClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {viewModal(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={editOpen}
                  className={classes.Modal}
                  onClose={editOpenClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={deleteModal}
                  className={classes.Modal1}
                  onClose={handleDeleteClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {deleteBody(id)}
                </Modal>
              </div>
            </div>

          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={data.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card >
      </div>
  );
};

Drivers.propTypes = {
  className: PropTypes.string,
};

export default Drivers;
