/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { useHistory } from 'react-router-dom';
import { language } from "config";
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  let history = useHistory();
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}
          >
            <ListItem className={classes.inlineBlock}>
              <a href="#" className={classes.block}>
                {language.copyright + ' '}&copy;{' ' + new Date().getFullYear() + ' ' + language.rights}
              </a>
            </ListItem>
          </Grid>
          <Grid item xs={12} lg={2}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {settings && settings.TwitterHandle ?
              <Tooltip
                id="instagram-twitter"
                title={language.follow_twitter}
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  href={settings.TwitterHandle}
                  target="_blank"
                  color="primary"
                  className={classes.button}
                >
                  <TwitterIcon fontSize="small" />
                </Button>
              </Tooltip>
              : null}
            <LinkedInIcon fontSize="small" className={classes.button} />
            {settings && settings.FacebookHandle ?
              <Tooltip
                id="instagram-facebook"
                title={language.follow_facebook}
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="primary"
                  href={settings.FacebookHandle}
                  target="_blank"
                  className={classes.button}
                >
                  <FacebookIcon style={{ marginRight: 10 }} fontSize="small" />
                </Button>
              </Tooltip>
              : null}
            <PinterestIcon fontSize="small" className={classes.button} />
          </Grid>
          <Grid item xs={12} lg={5}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
          // style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ListItem className={classes.inlineBlock}>
              {settings && settings.CompanyTerms ?
                <a href="/privacy-policy" style={{ cursor: 'pointer' }} className={classes.terms} >
                  {'Privacy Policy'}
                </a>
                : null}
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/terms" style={{ cursor: 'pointer' }} className={classes.terms}>
                {'Terms and Conditions'}
              </a>
            </ListItem>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};