import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import CircularLoading from "../components/CircularLoading";
import {
  features,
  language
} from 'config';
import { FirebaseContext } from 'common';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import BorderColor from '@material-ui/icons/BorderColor';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { useMediaQuery } from "react-responsive";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  edit: {
    padding: 10,
    marginLeft: 10,
  },
  searchInput: {
    display: 'flex',
    flex: 0.2,
    "@media (max-width: 480px)": {
      display: 'block',
      flex: 0.9
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    textTransform: 'capitalize',
    width: 50,
    fontWeight: '700',
  },
  imgStyle: {
    height: 80,
    width: 80,
    marginRight: 10
  },
  paper2: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-20px',
    right: '-20px',
    overflow: 'scroll',
    "@media (max-width: 480px)": {
      right: 0
    },
  },
  paper: {
    width: '30%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 10,
    "@media (max-width: 480px)": {
      width: '100%'
    }
  },
  paper1: {
    backgroundColor: '#ffffff',
    boxShadow: "10px 20px 30px #000000",
    padding: 3,
    "@media (max-width: 480px)": {
      width: 'auto'
    },
    width: '30%',
  },
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15
  },
  Modal1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sure: {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 15, marginLeft: 15
  },
  formControl: {
    display: 'flex',
    marginTop: 10,
  },
  join: {
    display: "flex"
  },
  grid: {
    paddingTop: 10,
    paddingBottom: 10
  },
  box1: {
    padding: 7,
    marginTop: 5,
    alignSelf: 'center',
  },
  button: {
    marginTop: 15,
    textTransform: 'capitalize',
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
    width: 100
  },
  split: {
    "@media (max-width: 480px)": {
      display: 'block',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  typoText1: {
    fontSize: 18,
    color: 'grey',
    fontWeight: '700',
    flex: 0.5,
    marginTop: 10
  },
  typoText2: {
    fontSize: 18,
    color: 'grey',
    flex: 0.5,
    marginTop: 10
  }
}));

const Operators = props => {
  const { api, imageStorageRef, operatorDocsRef } = useContext(FirebaseContext);
  const {
    emailSignUp,
    // addUser,
    editUser,
    deleteUser,
    checkUserExists
  } = api;
  const { className, ...rest } = props;

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [aadharFront, setAadharFront] = useState([]);
  const [aadharBack, setAadharBack] = useState([]);
  const [panFront, setPanFront] = useState([]);
  const [bankFront, setBankFront] = useState([]);

  const [aadharFrontLoad, setAadharFrontLoad] = useState(false);
  const [aadharBackLoad, setAadharBackLoad] = useState(false);
  const [panFrontLoad, setPanFrontLoad] = useState(false);
  const [bankFrontLoad, setBankFrontLoad] = useState(false);

  const usersdata = useSelector(state => state.usersdata);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [priorityModal, setPriorityModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [id, setId] = useState('');
  const [ptyValue, setPtyValue] = useState('');
  const [priorityLength, setPriorityLength] = useState([])

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)"
  });
  const [viewOpen, setViewOpen] = useState(false);

  const [values, setValues] = useState({
    mobile: '+91',
    firstName: '',
    lastName: '',
    pancardNumber: '',
    aadharNumber: '',
    password: '',
    email: '',
    companyName: '',
    priority: '',
    gstNumber: '',
    bankName: '',
    bankBranchCode: '',
    bankIFSCCode: ''
  });

  useEffect(() => {
    if (usersdata.users) {
      const operator = usersdata.users.filter(user => user.usertype === 'operator' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin'));
      setData(operator);
      var arr = []
      operator.map((item, i) => {
        arr.push(i + 1)
      })
      setPriorityLength(arr)
    } else {
      setData([]);
    }
  }, [usersdata.users, auth.info.profile.usertype, auth.info.uid]);

  const aadharFrontChange = (event) => {
    setAadharFrontLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'aadharFrontChange';
        await operatorDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await operatorDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setAadharFront(blob);
        setAadharFrontLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const aadharBackChange = (event) => {
    setAadharBackLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'aadharBackChange';
        await operatorDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await operatorDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setAadharBack(blob);
        setAadharBackLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const panFrontChange = (event) => {
    setPanFrontLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'panFrontChange';
        await operatorDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await operatorDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setPanFront(blob);
        setPanFrontLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const bankFrontChange = (event) => {
    setBankFrontLoad(true)
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        let timestamp = new Date().getTime() + 'bankFrontChange';
        await operatorDocsRef(auth.info.uid, timestamp).put(blob);
        blob = await operatorDocsRef(auth.info.uid, timestamp).getDownloadURL();
        setBankFront(blob);
        setBankFrontLoad(false)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const columns = [
    { title: 'Action' },
    { title: language.first_name, field: 'firstName' },
    { title: language.last_name, field: 'lastName' },
    { title: language.mobile, field: 'mobile' },
    { title: language.aadhar_number, field: 'aadharNumber' },
    { title: language.aadhar_front, field: 'aadharFrontImage' },
    { title: language.aadhar_back, field: 'aadharBackImage' },
    { title: language.pan_number, field: 'pancardNumber' },
    { title: language.pan_front, field: 'panFrontImage' },
    { title: language.bank_front, field: 'bankFrontImage' },
    { title: language.email, field: 'email' },
    // { title: language.password, field: 'password' },
    { title: language.company_name, field: 'companyName' },
    { title: 'Priority', field: 'priority' },
    { title: language.gst_number, field: 'gstNumber' },
    { title: language.bankName, field: 'bankName' },
    { title: language.bankBranchCode, field: 'bankBranchCode' },
    { title: language.bankIFSCCode, field: 'bankIFSCCode' },

  ];

  const deleteImageCancel = (imgUrl) => {
    imageStorageRef(imgUrl).delete().then(async () => {
    }).catch((error) => {
      alert('error', error)
    });
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const emptyValue = () => {
    setValues({
      firstName: '',
      lastName: '',
      pancardNumber: '',
      aadharNumber: '',
      email: '',
      companyName: '',
      gstNumber: '',
      bankName: '',
      bankBranchCode: '',
      bankIFSCCode: '',
      mobile: '+91',
      priority: ''
    })
    setBankFront([])
    setPanFront([])
    setAadharFront([])
    setAadharBack([])
  }

  const editOpenClose = () => {
    setEditOpen(false)
    emptyValue()
    setId('')
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
        if (aadharFront.length !== 0) { deleteImageCancel(aadharFront) }
        if (aadharBack.length !== 0) { deleteImageCancel(aadharBack) }
        if (panFront.length !== 0) { deleteImageCancel(panFront) }
        if (bankFront.length !== 0) { deleteImageCancel(bankFront) }
      }, 600);
    })
  }

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setId('')
    emptyValue()
  }

  const handlePriorityClose = () => {
    setPriorityModal(false)
    setValues({
      ...values,
      priority: ''
    })
  }

  const handleOpenModal = (id) => {
    setDeleteModal(true);
    setId(id)
  };

  const handleOpenPriority = (id) => {
    setPriorityModal(true);
    setPtyValue(id)
  };

  const handleOpenEdit = (item) => {
    console.log(item)
    setValues({
      ...values,
      mobile: item.mobile,
      firstName: item.firstName,
      lastName: item.lastName,
      pancardNumber: item.pancardNumber,
      aadharNumber: item.aadharNumber,
      aadharFrontImage: item.aadharFrontImage ? item.aadharFrontImage : '',
      aadharBackImage: item.aadharBackImage ? item.aadharBackImage : '',
      panFrontImage: item.panFrontImage ? item.panFrontImage : '',
      email: item.email,
      bankFrontImage: item.bankFrontImage ? item.bankFrontImage : '',
      // password: item.password,
      companyName: item.companyName,
      gstNumber: item.gstNumber,
      bankName: item.bankName,
      bankBranchCode: item.bankBranchCode,
      bankIFSCCode: item.bankIFSCCode,
      createdByAdmin: item.createdByAdmin ? item.createdByAdmin : false,
      usertype: item.usertype ? item.usertype : '',
      createdAt: item.createdAt ? item.createdAt : '',
      fleetadmin: item.fleetadmin ? item.fleetadmin : '',
      approved: item.approved ? item.approved : '',
      priority: item.priority ? item.priority : ''
    });
    setBankFront([])
    setPanFront([])
    setAadharFront([])
    setAadharBack([])
    setEditOpen(true);
    setId(item)
  };

  const history = useHistory()

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace('/vehicles');
      }
    };
  }, [history])

  const handleOpenAdd = () => {
    setEditOpen(true);
    setBankFront([])
    setPanFront([])
    setAadharFront([])
    setAadharBack([])
  }

  const handleChange = event => {
    if (event.target.name === 'priority') {
      var value = false
      var getItems = ''
      const operator = usersdata.users.filter(user => user.usertype === 'operator' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin'));
      operator.map((item) => {
        console.log(item)
        if (item.priority !== event.target.value) { } else {
          value = true
          getItems = item
        }
      })
      if (!value) {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      } else {
        handleOpenPriority(getItems)
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      }
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.name === 'approved' || event.target.name === 'driverActiveStatus' ? event.target.checked : event.target.value
      });
    }
  };

  const validation = (newData) => {
    if (
      values.mobile === '' ||
      values.firstName === '' ||
      values.lastName === '' ||
      values.email === '' ||
      values.pancardNumber === '' ||
      values.aadharNumber === '' ||
      values.priority === '' ||
      // values.companyName === '' ||
      // values.gstNumber === '' ||
      values.bankName === '' ||
      values.bankBranchCode === '' ||
      values.bankIFSCCode === '' ||
      values.password === ''
    ) {
      alert('Please Fill the Required fields')
    } else {
      addNewDriver(newData)
    }
  }

  const addNewDriver = (newData) => {
    //console.log(newData)
    new Promise((resolve, reject) => {
      setTimeout(() => {
        newData.aadharFrontImage = aadharFront;
        newData.aadharBackImage = aadharBack;
        newData.panFrontImage = panFront;
        newData.bankFrontImage = bankFront;
        checkUserExists(newData).then((res) => {
          if (res.operators && res.operators.length > 0) {
            alert(language.user_exists);
            reject();
          } else if (res.error) {
            alert(language.email_or_mobile_issue);
            reject();
          } else {
            newData['createdByAdmin'] = true;
            newData['usertype'] = 'operator';
            newData['createdAt'] = new Date().toISOString();
            let role = auth.info.profile.usertype;
            if (role === 'fleetadmin') {
              newData['fleetadmin'] = auth.info.uid;
            }
            emailSignUp(newData).then((res) => {
              if (res.error) {
                alert(res.error);
              } else {
                setEditOpen(false);
                setId('')
                emptyValue()
                resolve();
              }
              resolve();
            });

          }
        });
      }, 600);
    })
  }

  const updateDriver = (newData, oldData) => {
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
        if (
          values.mobile === '' ||
          values.firstName === '' ||
          values.lastName === '' ||
          values.email === '' ||
          values.pancardNumber === '' ||
          values.aadharNumber === '' ||
          values.priority === '' ||
          // values.companyName === '' ||
          // values.gstNumber === '' ||
          values.bankName === '' ||
          values.bankBranchCode === '' ||
          values.bankIFSCCode === ''
        ) {
          alert('Please Fill the Required fields')
        } else {
          // newData['createdByAdmin'] = true;
          // newData['usertype'] = 'operator';
          // newData['createdAt'] = new Date().toISOString();
          // let role = auth.info.profile.usertype;
          // if (role === 'fleetadmin') {
          //   newData['fleetadmin'] = auth.info.uid;
          // }
          if (aadharFront.length !== 0) {
            if (oldData.aadharFrontImage) {
              imageStorageRef(oldData.aadharFrontImage).delete().then(async () => {
                newData.aadharFrontImage = aadharFront
                dispatch(editUser(oldData.id, newData));
              }).catch((error) => {
                alert('error', error)
              });
            } else {
              newData.aadharFrontImage = aadharFront;
              dispatch(editUser(oldData.id, newData));
            }
          }

          if (aadharBack.length !== 0) {
            if (oldData.aadharBackImage) {
              imageStorageRef(oldData.aadharBackImage).delete().then(async () => {
                newData.aadharBackImage = aadharBack
                dispatch(editUser(oldData.id, newData));
              }).catch((error) => {
                alert('error', error)
              });
            } else {
              newData.aadharBackImage = aadharBack;
              dispatch(editUser(oldData.id, newData));
            }
          }

          if (panFront.length !== 0) {
            if (oldData.panFrontImage) {
              imageStorageRef(oldData.panFrontImage).delete().then(async () => {
                newData.panFrontImage = panFront;
                dispatch(editUser(oldData.id, newData));
              }).catch((error) => {
                alert('error', error)
              });
            } else {
              newData.panFrontImage = panFront;
              dispatch(editUser(oldData.id, newData));
            }
          }

          if (bankFront.length !== 0) {
            if (oldData.bankFrontImage) {
              imageStorageRef(oldData.bankFrontImage).delete().then(async () => {
                newData.bankFrontImage = bankFront;
                dispatch(editUser(oldData.id, newData));
              }).catch((error) => {
                alert('error', error)
              });
            } else {
              newData.bankFrontImage = bankFront;
              dispatch(editUser(oldData.id, newData));
            }
          }

          if (aadharFront.length === 0 &&
            aadharBack.length === 0 &&
            panFront.length === 0 &&
            bankFront.length === 0) {
            dispatch(editUser(oldData.id, newData));
          }

          const driver = usersdata.users.filter(user => user.usertype === 'driver' && (user.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin'));
          driver.map((item) => {
            if (item.fleetadmin === oldData.id) {
              item['priority'] = newData.priority
              dispatch(editUser(item.id, item));
            }
          })
          setBankFront([])
          setPanFront([])
          setAadharFront([])
          setAadharBack([])

          setEditOpen(false);
          emptyValue()
          setId('')
          setLoad(false)
        }
      }, 600);
    })
  }

  const deleteRowDriver = (id) => {
    features.AllowCriticalEditsAdmin ?
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          setDeleteModal(false)
          emptyValue()
          setId('')
          dispatch(deleteUser(id));
        }, 600);
      })
      :
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          alert(language.demo_mode);
        }, 600);
      })
  }

  function priorityAction(item) {
    return (
      <div className={classes.paper1}>
        <div className={classes.sure}>
          Are you sure you Want to change this priority, its will change {item.firstName}'s priority level to 0?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
          <Button
            className={classes.button}
            onClick={handlePriorityClose}
          >
            <p className={classes.text}>No</p>
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              item.priority = ''
              dispatch(editUser(item.id, item));
              setPriorityModal(false)
            }}
          >
            <p className={classes.text}>Yes</p>
          </Button>
        </div>
      </div>
    )
  }

  function deleteBody(id) {
    return (
      <div className={classes.paper1}>
        <div className={classes.sure}>
          Are you sure you Want to delete this row?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
          <Button
            className={classes.button}
            onClick={handleDeleteClose}
          >
            <p className={classes.text}>Cancel</p>
          </Button>
          <Button
            className={classes.button}
            onClick={() => deleteRowDriver(id)}
          >
            <p className={classes.text}>Delete</p>
          </Button>
        </div>
      </div>
    )
  }

  function body(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 20 }}>
            <Typography style={{ fontSize: 20, color: 'grey', textAlign: 'center' }}>{item ? 'Update Operators Data' : 'Add Operators Data'}</Typography>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter First Name"
                  margin="dense"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Last Name"
                  margin="dense"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter mobile No"
                  margin="dense"
                  name="mobile"
                  disabled={item ? true : false}
                  onChange={handleChange}
                  value={values.mobile}
                  variant="outlined"
                />
              </Grid>
              <Grid
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Aadhar Number"
                  margin="dense"
                  name="aadharNumber"
                  onChange={handleChange}
                  value={values.aadharNumber}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Typography>Aadhar Front Image:</Typography>
            {values.aadharFrontImage && values.aadharFrontImage.length > 0 ? (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.aadharFrontImage} className={classes.imgStyle} alt='bankFrontImage' />
                  <div className={classes.box1}>
                    {aadharFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='aadharFrontImage'
                      onChange={aadharFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {aadharFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='aadharFrontImage'
                      required
                      onChange={aadharFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <Typography>Aadhar Back Image:</Typography>
            {values.aadharBackImage && values.aadharBackImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.aadharBackImage} className={classes.imgStyle} alt='aadharBackImage' />
                  <div className={classes.box1}>
                    {aadharBackLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='aadharBackImage'
                      onChange={aadharBackChange}
                    />
                  </div>
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {aadharBackLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='aadharBackImage'
                      required
                      onChange={aadharBackChange}
                    />
                  </div>
                </Grid>
              </Grid>
            }
            <Typography>Pancard Front Image:</Typography>
            {values.panFrontImage && values.panFrontImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.panFrontImage} className={classes.imgStyle} alt='panFrontImage' />
                  {panFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                  <div className={classes.box1}>
                    <input type='file'
                      name='panFrontImage'
                      onChange={panFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {panFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='panFrontImage'
                      required
                      onChange={panFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
            }
            <Typography>Bank Passbook Image:</Typography>
            {values.bankFrontImage && values.bankFrontImage.length > 0 ? (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.bankFrontImage} className={classes.imgStyle} alt='bankFrontImage' />
                  <div className={classes.box1}>
                    {bankFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='bankFrontImage'
                      onChange={bankFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  <div className={classes.box}>
                    {bankFrontLoad ? <LinearProgress style={{ marginBottom: 10 }} /> : ''}
                    <input type='file'
                      name='bankFrontImage'
                      required
                      onChange={bankFrontChange}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid
              container
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Email"
                  margin="dense"
                  name="email"
                  type={'email'}
                  disabled={item ? true : false}
                  onChange={item ? '' : handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {item ?
              null :
              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: 5 }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Enter password"
                    margin="dense"
                    name="password"
                    type={'password'}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            }
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Pancard Number"
                  margin="dense"
                  name="pancardNumber"
                  onChange={handleChange}
                  value={values.pancardNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  fullWidth
                  label="Enter Company Name"
                  margin="dense"
                  name="companyName"
                  onChange={handleChange}
                  value={values.companyName}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <FormControl variant="outlined" size='small' style={{ flex: 0.48, display: 'flex', marginTop: 15 }}>
              <InputLabel id="demo-simple-select-outlined-label">Priority Level</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name='priority'
                value={values.priority}
                required
                onChange={handleChange}
                label="Priority Level"
              >
                <MenuItem value="">
                  <em>Select priority Level</em>
                </MenuItem>
                {priorityLength.map((name, i) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  fullWidth
                  label="Enter GST Number"
                  margin="dense"
                  name="gstNumber"
                  onChange={handleChange}
                  value={values.gstNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Bank Name"
                  margin="dense"
                  name="bankName"
                  onChange={handleChange}
                  value={values.bankName}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Bank Branch Code"
                  margin="dense"
                  name="bankBranchCode"
                  onChange={handleChange}
                  value={values.bankBranchCode}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Bank IFSC Code"
                  margin="dense"
                  name="bankIFSCCode"
                  onChange={handleChange}
                  value={values.bankIFSCCode}
                  variant="outlined"
                />
              </Grid>

            </Grid>
            <Button
              type='submit'
              color="primary"
              variant="contained"
              size='small'
              disabled={
                bankFrontLoad === false &&
                  panFrontLoad === false &&
                  aadharFrontLoad === false &&
                  aadharBackLoad === false ? false : true
              }
              className={classes.button}
              onClick={() => { id === '' ? validation(values) : updateDriver(values, item) }}
            >
              {id === '' ? 'Add' : 'Update'}
            </Button>
            <Button
              type='submit'
              variant="contained"
              size='small'
              className={classes.button}
              style={{ marginLeft: 20 }}
              onClick={() => {
                setEditOpen(false)
                setId('')
                emptyValue()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )
  };

  const viewOpenClose = () => {
    setViewOpen(false)
    setId('')
  }

  const handleOpenView = (item) => {
    setViewOpen(true)
    setId(item)
  }

  function viewModal(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 10 }}>
            <Typography style={{ marginTop: 10, fontSize: 20, color: 'grey', textAlign: 'center' }}>Operators Information</Typography>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>First Name</Typography>
              <Typography className={classes.typoText2}>: {item.firstName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Last Name</Typography>
              <Typography className={classes.typoText2}>: {item.lastName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Mobile Number</Typography>
              <Typography className={classes.typoText2}>: {item.mobile}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Aadhar Number</Typography>
              <Typography className={classes.typoText2}>: {item.aadharNumber}</Typography>
            </Grid>
            {item.aadharFrontImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Aadhar Front Image</Typography>
                :<img src={item.aadharFrontImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.aadharBackImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Aadhar Back Image</Typography>
                :<img src={item.aadharBackImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.panFrontImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Pancard Front Image</Typography>
                :<img src={item.panFrontImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.bankFrontImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Bank Passbook Image</Typography>
                :<img src={item.bankFrontImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Email</Typography>
              <Typography className={classes.typoText2}>: {item.email}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Pancard Number</Typography>
              <Typography className={classes.typoText2}>: {item.pancardNumber}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Company Name</Typography>
              <Typography className={classes.typoText2}>: {item.companyName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>priority Level</Typography>
              <Typography className={classes.typoText2}>: {item.priority}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>GST Number</Typography>
              <Typography className={classes.typoText2}>: {item.gstNumber}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Bank Name</Typography>
              <Typography className={classes.typoText2}>: {item.bankName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Bank Branch Code</Typography>
              <Typography className={classes.typoText2}>: {item.bankBranchCode}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Bank IFSC Code</Typography>
              <Typography className={classes.typoText2}>: {item.bankIFSCCode}</Typography>
            </Grid>
            <Button
              type='submit'
              color='primary'
              variant="contained"
              size='small'
              className={classes.button}
              onClick={() => {
                setViewOpen(false)
                setId('')
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </div >
    )
  };

  const preventRerender1 = (rowData) => {
    return (
      rowData ? <img alt='rc' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender2 = (rowData) => {
    return (
      rowData ? <img alt='insurance' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender3 = (rowData) => {
    return (
      rowData ? <img alt='permit' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender4 = (rowData) => {
    return (
      rowData ? <img alt='rc' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  // const onSearchChange = (event) => {
  //   if (event.target.value === '') {
  //     const operator = usersdata.users.filter(user => user.usertype === 'operator' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin'));
  //     setData(operator);
  //   } else {
  //     const result = data.filter(item => Object.values(item).includes(event.target.value))
  //     setData(result)
  //   }
  // }

  const requestSearch = (e) => {
    var value = e.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').trim()
    if (value === '') {
      const operator = usersdata.users.filter(user => user.usertype === 'operator' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin'));
      setData(operator);
    } else {
      const name = data.filter(e =>
        Object.values(e).join(" ").toLowerCase().match(value)
      )
      setData(name)
    }
  };

  return (
    load ? <CircularLoading /> :
      <div>
        <div className={classes.row}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOpenAdd()}
          >
            <Add fontSize='small' />
          </Button>
          <TextField
            className={classes.searchInput}
            onChange={requestSearch}
            label="Search Operators"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>

        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardContent className={classes.content}>

            <div className={classes.inner}>
              {isMobile ?
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Action</TableCell>
                      <TableCell align='center'>Mobile Number</TableCell>
                    </TableRow>
                  </TableHead>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    return (
                      <TableBody key={i}>
                        <TableRow hover>
                          <TableCell align='center' size='medium' style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip
                              id="View"
                              title={'View'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenView(item)}
                              >
                                <VisibilityIcon fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Edit"
                              title={'Edit'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenEdit(item)}
                              >
                                <BorderColor fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Delete"
                              title={'Delete'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenModal(item.id)}
                              >
                                <Delete fontSize='medium' />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell align='center'>{item.mobile}</TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
                :
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((item, i) => {
                        return (
                          <TableCell align='center' key={i}>{item.title}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    //console.log(item)
                    return (
                      <TableBody key={i}>
                        <TableRow hover>
                          <TableCell align='center' size='medium' style={{ display: 'flex', alignItems: 'center', height: 150 }}>
                            <Tooltip
                              id="Edit"
                              title={'Edit'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenEdit(item)}
                                className={classes.edit}
                              >
                                <BorderColor fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Delete"
                              title={'Delete'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenModal(item.id)}
                                className={classes.edit}
                              >
                                <Delete fontSize='medium' />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell align='center'>{item.firstName}</TableCell>
                          <TableCell align='center'>{item.lastName}</TableCell>
                          <TableCell align='center'>{item.mobile}</TableCell>
                          <TableCell align='center'>{item.aadharNumber}</TableCell>
                          <TableCell align='center'>
                            {preventRerender1(item.aadharFrontImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender2(item.aadharBackImage)}
                          </TableCell>
                          <TableCell align='center'>{item.pancardNumber}</TableCell>
                          <TableCell align='center'>
                            {preventRerender3(item.panFrontImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender4(item.bankFrontImage)}
                          </TableCell>
                          <TableCell align='center'>{item.email}</TableCell>

                          {/* <TableCell align='center'>{item.password}</TableCell> */}
                          <TableCell align='center'>{item.companyName}</TableCell>
                          <TableCell align='center'>{item.priority ? item.priority : 0}</TableCell>
                          <TableCell align='center'>{item.gstNumber}</TableCell>
                          <TableCell align='center'>{item.bankName}</TableCell>
                          <TableCell align='center'>{item.bankBranchCode}</TableCell>
                          <TableCell align='center'>{item.bankIFSCCode}</TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
              }
              <div>
                <Modal
                  open={viewOpen}
                  className={classes.Modal}
                  onClose={viewOpenClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {viewModal(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={editOpen}
                  className={classes.Modal}
                  onClose={editOpenClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={deleteModal}
                  className={classes.Modal1}
                  onClose={handlePriorityClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {deleteBody(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={priorityModal}
                  className={classes.Modal1}
                  onClose={handleDeleteClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {priorityAction(ptyValue)}
                </Modal>
              </div>
            </div>

          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={data.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card >
      </div>
  );
};

Operators.propTypes = {
  className: PropTypes.string,
};

export default Operators;
