import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
  features,
  language
} from 'config';
import { useDispatch, useSelector } from "react-redux";

import Add from '@material-ui/icons/Add';
import BorderColor from '@material-ui/icons/BorderColor';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularLoading from "../components/CircularLoading";
import Delete from '@material-ui/icons/Delete';
import { FirebaseContext } from 'common';
import FormControl from '@material-ui/core/FormControl';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import SearchIcon from "@material-ui/icons/Search";
import Select from '@material-ui/core/Select';
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  edit: {
    padding: 10,
    marginLeft: 10,
  },
  searchInput: {
    display: 'flex',
    flex: 0.2,
    "@media (max-width: 480px)": {
      display: 'block',
      flex: 0.9
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    textTransform: 'capitalize',
    width: 50,
    fontWeight: '700',
  },
  imgStyle: {
    height: 80,
    width: 80,
    marginRight: 10
  },
  paper2: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-20px',
    right: '-20px',
    overflow: 'scroll',
    "@media (max-width: 480px)": {
      right: 0,
    },
  },
  paper: {
    width: '30%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 10,
    "@media (max-width: 480px)": {
      width: '100%'
    }
  },
  paper1: {
    backgroundColor: '#ffffff',
    boxShadow: "10px 20px 30px #000000",
    padding: 3,
    "@media (max-width: 480px)": {
      width: 'auto'
    },
    width: '30%',
  },
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15
  },
  Modal1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sure: {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 15,
    marginLeft: 15,
  },
  formControl: {
    display: 'flex',
    marginTop: 10,
  },
  join: {
    display: "flex"
  },
  grid: {
    paddingTop: 10,
    paddingBottom: 10
  },
  box1: {
    padding: 7,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: 15,
    textTransform: 'capitalize',
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
    width: 100
  },
  split: {
    "@media (max-width: 480px)": {
      display: 'block',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  typoText1: {
    fontSize: 18,
    color: 'grey',
    fontWeight: '700',
    flex: 0.5,
    marginTop: 10
  },
  typoText2: {
    fontSize: 18,
    color: 'grey',
    flex: 0.5,
    marginTop: 10
  }
}));

const Vehicles = props => {

  const { api, imageStorageRef, singleVehicleRef } = useContext(FirebaseContext);
  const {
    addVehicle,
    editVehicle,
    deleteVehicle,
    checkVehicleExists
  } = api;
  const [isedit, setIsEdit] = useState(false)
  const [data, setData] = useState([]);
  const [operators, setOperators] = useState({});
  const [drivers, setDrivers] = useState({});
  const [cars, setCars] = useState({});
  const [rcImage, setRCImage] = useState([]);
  const [insuranceImage, setInsuranceImage] = useState([]);
  const [permitImage, setPermitImage] = useState([]);
  const vehiclesdata = useSelector(state => state.vehicledata);
  const usersdata = useSelector(state => state.usersdata);
  const cartypelistdata = useSelector(state => state.cartypes);
  const transdata = useSelector(state => state.transdata);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [id, setId] = useState('');
  const [called, setCalled] = useState(false);

  const refVf = React.useRef();
  const refVb = React.useRef();
  const refVs = React.useRef();
  const refIi = React.useRef();
  const refRc = React.useRef();
  const refPi = React.useRef();

  const [vehnum, setVehnum] = useState('')
  const [confirmvehnum, setConfirmvehnum] = useState('')
  const [error, setError] = useState(false)
  const [lengthError, setLengthError] = useState(false)
  const [driverEnable, setDriverEnable] = useState(false)
  const [letter, setLetter] = useState(true)

  const [vehicleNoData, setVehicleNoData] = useState([]);
  const [vehicleId, setVehicleId] = useState('');
  const [disable, setDisable] = useState(false);
  const [newData, setNewData] = useState([]);


  const isMobile = useMediaQuery({
    query: "(max-width: 786px)"
  });
  const [viewOpen, setViewOpen] = useState(false);

  const [values, setValues] = useState({
    vehicleNo: '',
    ownerName: '',
    vehicleChasisNo: '',
    vehicleEngineNo: '',
    vehicleModel: '',
    vehicleYear: '',
    color: '',
    carType: '',
    operator: '',
    driver: '',
    rcImage: '',
    insuranceImage: '',
    permitImage: '',
    insuranceExpiryDate: '',
    FCRenewDate: '',
    permitRenewDate: '',
    approvalStatus: false,
    mobile: '+91'
  });

  const columns = [
    { title: 'Action' },
    { title: language.mobile, field: 'mobile' },
    { title: language.operator, field: 'operator' },
    { title: language.vehicle_no, field: 'vehicleNo' },
    { title: language.owner_name, field: 'ownerName' },
    { title: language.vehicle_chasisno, field: 'vehicleChasisNo' },
    { title: language.vehicle_engineno, field: 'vehicleEngineNo' },
    { title: language.vehicle_front, field: 'vehicleFront' },
    { title: language.vehicle_back, field: 'vehicleBack' },
    { title: language.vehicle_side, field: 'vehicleSide' },
    { title: language.vehicle_model, field: 'vehicleModel' },
    { title: 'Vehicle Year', field: 'vehicleYear' },
    { title: language.color, field: 'color' },
    { title: language.car_type, field: 'carType' },
    { title: language.driver, field: 'driver' },
    { title: language.rc_image, field: 'rcImage' },
    { title: language.insurance, field: 'insuranceImage' },
    { title: language.permit, field: 'permitImage' },
    { title: language.i_expiry, field: 'insuranceExpiryDate' },
    { title: language.rc_renew, field: 'FCRenewDate' },
    { title: language.permit_renew, field: 'permitRenewDate' },
    { title: 'Approval Status', field: 'approvalStatus' },
    { title: 'Commission Fees', field: 'commission_fees' }
  ];

  const { className, ...rest } = props;

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [carFront, setCarFront] = useState([]);
  const [carBack, setCarBack] = useState([]);
  const [carSide, setCarSide] = useState([]);
  const [getNumber, setNumber] = useState({})

  const [idForDelete, setIdForDelete] = useState('')

  const history = useHistory()

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace('/vehicles');
      }
    };
  }, [history])

  useEffect(() => {
    if (usersdata.users) {
      const operator = usersdata.users.filter(user => user.usertype === 'operator' && (user.id === auth.info.uid || auth.info.profile.usertype === 'admin'));
      let opObj = {};
      let mobObj = {}
      operator.map((op) => {
        const fullname = op.firstName + ' ' + op.lastName;
        let name = op.id
        opObj[name] = fullname;
        mobObj[name] = op.mobile
        return name;
      });
      setNumber(mobObj)
      setOperators(opObj);

      if (auth.info.profile.usertype === 'operator') {

        const changeOperators1 = operator.filter(operator => operator.mobile.includes(auth.info.profile.mobile));
        let opObj1 = {};
        changeOperators1.map((op) => {
          const fullname = op.firstName + ' ' + op.lastName;
          let name = op.id
          opObj1[name] = fullname;
          return name;
        });
        setOperators(opObj1);

        const driver1 = usersdata.users.filter(user => user.usertype === 'driver' && user.fleetadmin === auth.info.uid);
        const changeDrivers1 = driver1.filter(driver => driver.fleetadmin === Object.keys(opObj1)[0])
        var obj = {}
        changeDrivers1.map((driver) => {
          const fullname = driver.firstName + ' ' + driver.lastName;
          let name = driver.id
          obj[name] = fullname;
          return name;
        });
        setDrivers(obj)
        setValues({
          ...values,
          mobile: auth.info.profile.mobile,
          operator: Object.keys(opObj)[0],
        });
      }
    }
  }, [usersdata.users, auth.info]);

  useEffect(() => {
    console.log("vehiclesdata.vehicles", vehiclesdata.vehicles)
    if (vehiclesdata.vehicles) {
      if (auth.info.profile.usertype === 'operator') {
        const vehicles = vehiclesdata.vehicles.filter(vehicle => vehicle.operator === auth.info.uid);
        console.log("vehicles", vehicles)
        setData(vehicles);
        // setDisable(true)
      } else {
        const vehicles = vehiclesdata.vehicles.filter(vehicle => vehicle.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin');
        var resultData = []
        var arr = []
        var arr1 = []
        vehicles.filter((data) => {
          if (!data.approvalStatus) {
            arr.push(data)
          } else {
            arr1.push(data)
          }
        })
        if (arr1.length > 0) {
          arr1.map((item) => {
            resultData = [...arr, ...arr1]
          })
        }
        setData(resultData);

        // setData(vehicles);
      }
    } else {
      setData([]);
    }

    if (vehiclesdata.vehicles && auth.info) {
      let arr = []
      vehiclesdata.vehicles.map((item) => {
        let obj = {}
        obj[item.id] = item.vehicleNo
        arr.push(obj)
        setVehicleNoData(arr)
      })
    }
  }, [vehiclesdata.vehicles, auth.info, vehicleId]);

  const carFrontChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setCarFront(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  console.log("vehicles data", data)

  const carSideChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setCarSide(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const carBackChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setCarBack(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onRCBookChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setRCImage(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onInsuranceChange = (event) => {
    if (event.target.files && event.target.files[0]) {

      var blob = '';
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setInsuranceImage(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const onPermitChange = (event) => {
    if (event.target.files && event.target.files[0]) {

      var blob = '';
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setPermitImage(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  useEffect(() => {
    if (cartypelistdata.cars) {
      let obj = {};
      cartypelistdata.cars.map((car) => {
        let name = car.name.toLowerCase();
        obj[name] = car.name;
        return name;
      });
      setCars(obj);
    }
  }, [cartypelistdata.cars]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const emptyValue = () => {
    setValues({
      ...values,
      vehicleNo: '',
      ownerName: '',
      vehicleChasisNo: '',
      vehicleEngineNo: '',
      vehicleModel: '',
      vehicleYear: '',
      color: '',
      carType: '',
      operator: auth.info.profile.usertype === 'operator' ? values.operator : '',
      driver: '',
      rcImage: '',
      insuranceImage: '',
      permitImage: '',
      insuranceExpiryDate: '',
      FCRenewDate: '',
      permitRenewDate: '',
      vehicleFront: '',
      vehicleBack: '',
      vehicleSide: '',
      mobile: auth.info.profile.usertype === 'operator' ? values.mobile : '+91'
    })
    setVehnum('')
    setConfirmvehnum('')
    setDisable(false)
    setIsEdit(false)
    setError(false)
    setLengthError(false)
    setCarFront([])
    setCarBack([])
    setCarSide([])
  }

  const editOpenClose = () => {
    setEditOpen(false)
    emptyValue()
    setId('')
  }

  const handleDeleteClose = () => {
    setDeleteModal(false)
    setId('')
    emptyValue()
  }

  const handleUpdate = () => {
    setUpdateModal(false)
    setId('')
    emptyValue()
    setEditOpen(false)
    // setNewData([])
  }

  const handleOpenModal = (item) => {
    setDeleteModal(true);
    setId(item.id)
    setIdForDelete(item.driver)
  };

  const handleOpenEdit = (item) => {
    const operator = usersdata.users.filter(user => user.usertype === 'operator' && (user.id === auth.info.uid || auth.info.profile.usertype === 'admin'));
    let opObj = {};
    var mobile = ''
    operator.map((op) => {
      const fullname = op.firstName + ' ' + op.lastName;
      let name = op.id
      opObj[name] = fullname;
      if (op.id === item.operator) {
        mobile = op.mobile
      }
      return name;
    });
    setOperators(opObj);
    setValues({
      ...values,
      mobile: mobile,
      operator: item.operator,
      vehicleNo: item.vehicleNo,
      ownerName: item.ownerName,
      vehicleChasisNo: item.vehicleChasisNo,
      vehicleEngineNo: item.vehicleEngineNo,
      vehicleFront: item.vehicleFront ? item.vehicleFront : '',
      vehicleBack: item.vehicleBack ? item.vehicleBack : '',
      vehicleSide: item.vehicleSide ? item.vehicleSide : '',
      vehicleModel: item.vehicleModel,
      vehicleYear: item.vehicleYear ? item.vehicleYear : '',
      color: item.color,
      carType: item.carType,
      driver: item.driver,
      rcImage: item.rcImage ? item.rcImage : '',
      insuranceImage: item.insuranceImage ? item.insuranceImage : '',
      permitImage: item.permitImage ? item.permitImage : '',
      insuranceExpiryDate: item.insuranceExpiryDate,
      FCRenewDate: item.FCRenewDate,
      permitRenewDate: item.permitRenewDate,
      approvalStatus: item.approvalStatus,
      createdByAdmin: item.createdByAdmin ? item.createdByAdmin : false,
      createdAt: item.createdAt ? item.createdAt : '',
      fleetadmin: item.fleetadmin ? item.fleetadmin : ''
    });
    const driver = usersdata.users.filter(user => user.usertype === 'driver' && (user.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin'));
    const changeDrivers = driver.filter(driver => driver.fleetadmin === item.operator)
    var obj = {}
    changeDrivers.map((driver) => {
      const fullname = driver.firstName + ' ' + driver.lastName;
      let name = driver.id
      obj[name] = fullname;
      return name;
    });
    setDrivers(obj)
    setEditOpen(true);
    setId(item)
    setIsEdit(true)
    setVehnum(item.vehicleEngineNo)
    setConfirmvehnum(item.vehicleEngineNo)
    setDisable(false)
  };

  const handleOpenAdd = () => {
    setEditOpen(true);
    // if (auth.info.profile.usertype === 'operator') {
    //   setDisable(true)
    // }
  }

  const formatDate = (item) => {
    var date = new Date(item); // Date 2011-05-09T06:08:45.178Z
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`
  }

  const handleChange = event => {
    if (event.target.name === 'mobile') {
      if (auth.info.profile.usertype === 'operator') {
        const operator = usersdata.users.filter(user => user.usertype === 'operator' && (user.id === auth.info.uid || auth.info.profile.usertype === 'admin'));
        const changeOperators = operator.filter(operator => operator.mobile.includes(event.target.value))
        let opObj = {};
        changeOperators.map((op) => {
          const fullname = op.firstName + ' ' + op.lastName;
          let name = op.id
          opObj[name] = fullname;
          return name;
        });
        setOperators(opObj);

        if (event.target.value.length >= 10) {
          setValues({
            ...values,
            operator: Object.keys(opObj)[0],
            mobile: event.target.value
          })
          const driver = usersdata.users.filter(user => user.usertype === 'driver' && (user.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin'));
          const changeDrivers = driver.filter(driver => driver.fleetadmin === Object.keys(opObj)[0])
          var obj = {}
          changeDrivers.map((driver) => {
            const fullname = driver.firstName + ' ' + driver.lastName;
            let name = driver.id
            obj[name] = fullname;
            return name;
          });
          setDrivers(obj)
        } else {
          setValues({
            ...values,
            operator: '',
            mobile: event.target.value,
            driver: ''
          })
        }
      } else {
        setValues({
          ...values,
          mobile: event.target.value,
        })
      }
    }
    else {
      if (event.target.name === 'insuranceExpiryDate' || event.target.name === 'FCRenewDate' || event.target.name === 'permitRenewDate') {
        validateDate(event.target.name, event.target.value)
      } else {
        if (event.target.name === 'vehicleChasisNo' || event.target.name === 'vehicleModel') {
          var regex = new RegExp("^[a-zA-Z0-9 ]+$");
          setValues({
            ...values,
            [event.target.name]: event.target.value !== '' ? regex.test(event.target.value) ? event.target.value : event.target.name === 'vehicleChasisNo' ? values.vehicleChasisNo : values.vehicleModel : event.target.value
          });
        } else {
          if (event.target.name === "operator") {
            const driver = usersdata.users.filter(user => user.usertype === 'driver' && (user.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin'));
            const changeDrivers = driver.filter(driver => driver.fleetadmin === event.target.value)
            var obj = {}
            changeDrivers.map((driver) => {
              const fullname = driver.firstName + ' ' + driver.lastName;
              let name = driver.id
              obj[name] = fullname;
              return name;
            });
            setDrivers(obj)
            console.log("drivers", changeDrivers)
          }
          setValues({
            ...values,
            [event.target.name]: event.target.name === 'approvalStatus' ? event.target.checked : event.target.value
          });
        }
      }
    }
  };

  const validateDate = (name, value) => {
    if (formatDate(new Date(value)) >= formatDate(new Date())) {
      setValues({
        ...values,
        [name]: value
      });
    } else {
      alert('Please select a valid date')
    }
  }

  const validation = (newData) => {
    if (
      values.mobile === '' ||
      values.vehicleNo === '' ||
      values.ownerName === '' ||
      values.color === '' ||
      values.vehicleModel === '' ||
      values.vehicleYear === '' ||
      values.carType === '' ||
      values.vehicleChasisNo === '' ||
      values.vehicleEngineNo === '' ||
      values.operator === '' ||
      values.driver === '' ||
      values.insuranceExpiryDate === '' ||
      values.FCRenewDate === '' ||
      values.permitRenewDate === '' ||
      vehnum !== confirmvehnum
    ) {
      alert('Please Fill the Required fields')
    } else {
      if (values.vehicleNo.length >= 8) {
        if (!disable) {
          addNewVehicle(newData)
        } else {
          var valid = false
          Object.keys(drivers).map((driver) => {
            if (driver !== newData.driver) {
              setDriverEnable(true)
            } else {
              valid = true
            }
          });
          if (valid) {
            setNewData(newData)
            setUpdateModal(true)
          } else {
            alert('Please choose driver field')
          }
        }
      } else {
        setLengthError(true)
      }
      // addNewVehicle(newData)
    }
  }

  const addNewVehicle = (newData) => {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        newData.rcImage = rcImage;
        newData.insuranceImage = insuranceImage;
        newData.permitImage = permitImage;
        newData.vehicleFront = carFront;
        newData.vehicleBack = carBack;
        newData.vehicleSide = carSide;
        newData.insuranceExpiryDate = newData.insuranceExpiryDate ? new Date(newData.insuranceExpiryDate).getTime() : '';
        newData.FCRenewDate = newData.FCRenewDate ? new Date(newData.FCRenewDate).getTime() : '';
        newData.permitRenewDate = newData.permitRenewDate ? new Date(newData.permitRenewDate).getTime() : '';
        checkVehicleExists(newData).then((res) => {
          if (res.vehicles && res.vehicles.length > 0) {
            alert(language.user_exists);
            reject();
          }
          else if (res.error) {
            alert(language.email_or_mobile_issue);
            reject();
          }
          else {
            newData['createdByAdmin'] = true;
            newData['createdAt'] = new Date().toISOString();
            let role = auth.info.profile.usertype;
            if (role === 'fleetadmin') {
              newData['fleetadmin'] = auth.info.uid;
            }
            setEditOpen(false);
            dispatch(addVehicle(newData));
            resolve();
            setId('')
            emptyValue()
          }
        });
      }, 600);
    })
  }

  const updateVehicle = (newData, oldData) => {
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
        if (
          values.mobile === '' ||
          values.mobile.length <= 12 ||
          values.vehicleNo === '' ||
          values.operator === undefined ||
          values.ownerName === '' ||
          values.color === '' ||
          values.vehicleModel === '' ||
          values.vehicleYear === '' ||
          values.carType === '' ||
          values.vehicleChasisNo === '' ||
          values.vehicleEngineNo === '' ||
          values.operator === '' ||
          values.driver === '' ||
          values.insuranceExpiryDate === '' ||
          values.FCRenewDate === '' ||
          values.permitRenewDate === '' ||
          vehnum !== confirmvehnum
        ) {
          alert('Please Fill the Required fields')
        } else {
          if (values.vehicleNo.length >= 8) {
            if (rcImage instanceof Blob) {
              newData.rcImage = rcImage;
            }
            if (insuranceImage instanceof Blob) {
              newData.insuranceImage = insuranceImage;
            }
            if (permitImage instanceof Blob) {
              newData.permitImage = permitImage;
            }
            if (carFront instanceof Blob) {
              newData.vehicleFront = carFront;
            }
            if (carBack instanceof Blob) {
              newData.vehicleBack = carBack;
            }
            if (carSide instanceof Blob) {
              newData.vehicleSide = carSide;
            }
            newData.insuranceExpiryDate = newData.insuranceExpiryDate ? new Date(newData.insuranceExpiryDate).getTime() : '';
            newData.FCRenewDate = newData.FCRenewDate ? new Date(newData.FCRenewDate).getTime() : '';
            newData.permitRenewDate = newData.permitRenewDate ? new Date(newData.permitRenewDate).getTime() : '';
            dispatch(editVehicle(oldData, newData));
            setEditOpen(false);
            emptyValue()
            setId('')
          } else {
            setLengthError(true)
          }
        }
      }, 600);
    })
  }

  const deleteRowVehicle = (id) => {
    features.AllowCriticalEditsAdmin ?
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          setDeleteModal(false)
          emptyValue()
          setId('')
          dispatch(deleteVehicle(id, idForDelete));
        }, 600);
      })
      :
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          alert(language.demo_mode);
        }, 600);
      })
  }

  const validationVehicle = () => {
    if (newData.operator === auth.info.uid) {
      alert('This driver assigned same operator')
      handleUpdate()
    } else {
      newData.operator = auth.info.uid
      var oldData = {
        driver: newData.driver,
        id: vehicleId
      }
      dispatch(editVehicle(oldData, newData));
      handleUpdate()
    }
    // dispatch(mobileSignIn(auth.verificationId, otp))
  }

  function UpdateBody() {
    return (
      <div className={classes.paper1}>
        <div className={classes.sure}>
          Are you sure you Want to change Operator?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
          <Button
            className={classes.button}
            onClick={() => setUpdateModal(false)}
          >
            <p className={classes.text}>No</p>
          </Button>
          <Button
            className={classes.button}
            onClick={() => validationVehicle()}
          >
            <p className={classes.text}>Yes</p>
          </Button>
        </div>
      </div>
    )
  }

  const addWithUpdateVehicle = (id) => {
    const vehicle = vehiclesdata.vehicles.filter(user => user.id === id);
    var data = vehicle[0]
    var oldData = {
      driver: data.driver,
      id: data.id
    }
    data.operator = ''
    data.approved = false
    data.approvalStatus = false
    dispatch(editVehicle(oldData, data));
    setDeleteModal(false)
    emptyValue()
    setId('')
  }

  function deleteBody(id) {
    return (
      <div className={classes.paper1}>
        <div className={classes.sure}>
          Are you sure you Want to delete this row?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
          <Button
            className={classes.button}
            onClick={handleDeleteClose}
          >
            <p className={classes.text}>Cancel</p>
          </Button>
          <Button
            className={classes.button}
            onClick={() => auth.info.profile.usertype === 'operator' ? addWithUpdateVehicle(id) : deleteRowVehicle(id)}
          >
            <p className={classes.text}>Delete</p>
          </Button>
        </div>
      </div>
    )
  }

  const deleteImage = (name) => {
    if (name === 'vehicleFront') {
      setCarFront([])
      setValues({
        ...values,
        vehicleFront: ''
      })
      refVf.current.value = ""
    } else if (name === 'vehicleBack') {
      setCarBack([])
      setValues({
        ...values,
        vehicleBack: ''
      })
      refVb.current.value = ""
    } else if (name === 'vehicleSide') {
      setCarSide([])
      setValues({
        ...values,
        vehicleSide: ''
      })
      refVs.current.value = ""
    } else if (name === 'rcImage') {
      setRCImage([])
      setValues({
        ...values,
        rcImage: ''
      })
      refRc.current.value = ""
    } else if (name === 'insuranceImage') {
      setInsuranceImage([])
      setValues({
        ...values,
        insuranceImage: ''
      })
      refIi.current.value = ""
    } else {
      setPermitImage([])
      setValues({
        ...values,
        permitImage: ''
      })
      refPi.current.value = ""
    }
  }

  const deleteUpdatedImage = (name, imgUrl) => {
    if (imgUrl) {
      imageStorageRef(imgUrl).delete().then(async () => {
        deleteImage(name)
      }).catch((error) => {
        alert('error', error)
      });
    } else {
      deleteImage(name)
    }
  }

  const connect = (item) => {
    const operator = usersdata.users.filter(user => user.usertype === 'operator');
    let opObj = {};
    operator.map((op) => {
      const fullname = op.firstName + ' ' + op.lastName;
      let name = op.id
      opObj[name] = fullname;
      return name;
    });
    setOperators(opObj);

    setValues({
      ...values,
      mobile: item.mobile,
      operator: item.operator,
      vehicleNo: item.vehicleNo,
      ownerName: item.ownerName,
      vehicleChasisNo: item.vehicleChasisNo,
      vehicleEngineNo: item.vehicleEngineNo,
      vehicleFront: item.vehicleFront ? item.vehicleFront : '',
      vehicleBack: item.vehicleBack ? item.vehicleBack : '',
      vehicleSide: item.vehicleSide ? item.vehicleSide : '',
      vehicleModel: item.vehicleModel,
      vehicleYear: item.vehicleYear,
      color: item.color,
      // approved: item.approved,
      carType: item.carType,
      driver: item.driver,
      rcImage: item.rcImage ? item.rcImage : '',
      insuranceImage: item.insuranceImage ? item.insuranceImage : '',
      permitImage: item.permitImage ? item.permitImage : '',
      insuranceExpiryDate: item.insuranceExpiryDate,
      FCRenewDate: item.FCRenewDate,
      permitRenewDate: item.permitRenewDate,
      createdByAdmin: item.createdByAdmin ? item.createdByAdmin : false,
      createdAt: item.createdAt ? item.createdAt : '',
      fleetadmin: item.fleetadmin ? item.fleetadmin : '',
      approvalStatus: item.approvalStatus
    })
    setCalled(true)
    setVehnum(item.vehicleEngineNo)
    setConfirmvehnum(item.vehicleEngineNo)
  }

  const checkVehicle = (event, name) => {
    var number = event.target.value
    setLetter(false)
    // var name1 = name === 'vehnum' ? number : vehnum
    // var name2 = name === 'confirmvehnum' ? number : confirmvehnum
    // if (name === 'vehnum') {
    //   setVehnum(number)
    // } else {
    //   setConfirmvehnum(number)
    // }
    // if (name1 === name2) {
    //   setError(false)
    setValues({
      ...values, vehicleNo: number
    })
    // } else {
    //   setError(true)
    // }
    if (!isedit && auth.info.profile.usertype === 'operator') {
      let id = ''
      vehicleNoData.map((item) => {
        if (Object.values(item)[0] ? Object.values(item)[0].toLowerCase() === number.toLowerCase() : '') {
          setVehicleId(Object.keys(item)[0])
          id = Object.keys(item)[0]
        }
      })
      if (id !== '') {
        singleVehicleRef(id).on('value', function (snapshot) {
          connect(snapshot.val())
          setDisable(true)
        })
      } else {
        setValues({
          ...values,
          vehicleNo: number,
          ownerName: '',
          vehicleChasisNo: '',
          vehicleEngineNo: '',
          vehicleModel: '',
          vehicleYear: '',
          color: '',
          carType: '',
          // operator: '',
          driver: '',
          insuranceExpiryDate: '',
          FCRenewDate: '',
          permitRenewDate: '',
          mobile: auth.info.profile.mobile
        })
        // setDisable(number >= 1 ? false : true)
        setLengthError(false)
        setCalled(false)
        setVehnum('')
        setConfirmvehnum('')
      }
    }
  }

  const checkEnginNumber = (event, name) => {
    var number = event.target.value
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (number !== '' && regex.test(number)) {
      var name1 = name === 'vehnum' ? number : vehnum
      var name2 = name === 'confirmvehnum' ? number : confirmvehnum
      if (name === 'vehnum') {
        setVehnum(number)
      } else {
        setConfirmvehnum(number)
      }
      if (name1 === name2) {
        setError(false)
        setValues({
          ...values, vehicleEngineNo: number !== '' ? regex.test(number) ? number : values.vehicleEngineNo : ''
        })
      } else {
        setError(true)
      }
    }
    else {
      if (name === 'vehnum') {
        setVehnum('')
      } else {
        setConfirmvehnum('')
      }
    }
  }

  function body(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 20 }}>
            <Typography style={{ fontSize: 20, color: 'grey', textAlign: 'center' }}>{item ? 'Update Vehicles Data' : 'Add Vehicles Data'}</Typography>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 1, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Vehicle No"
                  margin="dense"
                  name="vehicleNo"
                  onChange={(event) => checkVehicle(event, 'vehnum')}
                  value={values.vehicleNo}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {lengthError ?
              <Typography style={{ textAlign: 'left', color: 'red', marginTop: 10 }}>Please check your Vehicle number</Typography>
              : null}
            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                disabled={item ? true : false}
                // disabled={auth.info.profile.usertype === 'operator' ? true : disable ? true : false}
                label="Enter mobile No"
                margin="dense"
                name="mobile"
                onChange={handleChange}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <div className={classes.split}>
              <FormControl variant="outlined" size='small' style={{ flex: 0.48, display: 'flex', marginTop: 15 }}>
                <InputLabel id="demo-simple-select-outlined-label">Select Operator</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name='operator'
                  value={values.operator}
                  // disabled={true}
                  required
                  onChange={handleChange}
                  label="Select Operator"
                >
                  <MenuItem value="">
                    <em>Select Operator</em>
                  </MenuItem>
                  {Object.values(operators).map((name, i) => (
                    <MenuItem key={name} value={Object.keys(operators)[i]}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" size='small' style={{ flex: 0.48, display: 'flex', marginTop: 15 }}>
                <InputLabel id="demo-simple-select-outlined-label">Select Driver</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name='driver'
                  value={values.driver}
                  required
                  disabled={disable && !driverEnable}
                  onChange={handleChange}
                  label="Select Driver"
                >
                  <MenuItem value="">
                    <em>Select Driver</em>
                  </MenuItem>
                  {Object.values(drivers).map((name, i) => (
                    <MenuItem key={name} value={Object.keys(drivers)[i]}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Owner Name"
                  margin="dense"
                  name="ownerName"
                  disabled={disable}
                  onChange={handleChange}
                  value={values.ownerName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Enter Color"
                  margin="dense"
                  name="color"
                  disabled={disable}
                  onChange={handleChange}
                  value={values.color}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Vehicle Engine Number"
                  margin="dense"
                  disabled={disable}
                  name="vehicleEngineNo"
                  onChange={(event) => checkEnginNumber(event, 'vehnum')}
                  value={vehnum}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Confirm Engine Number"
                  margin="dense"
                  disabled={disable}
                  name="vehicleEngineNo"
                  onChange={(event) => checkEnginNumber(event, 'confirmvehnum')}
                  value={confirmvehnum}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {error ?
              <Typography style={{ textAlign: 'center', color: 'red', marginTop: 10 }}>{'Engin Number should be same'}</Typography>
              : null}
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                xs={12}
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  disabled={disable}
                  label="Vehicle Chasis Number"
                  margin="dense"
                  name="vehicleChasisNo"
                  onChange={handleChange}
                  value={values.vehicleChasisNo}
                  variant="outlined"
                />
              </Grid>
              <FormControl variant="outlined" size='small' style={{ flex: 0.48, display: 'flex', marginTop: 15 }}>
                <InputLabel id="demo-simple-select-outlined-label">Select Car Type</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name='carType'
                  value={values.carType}
                  required
                  onChange={handleChange}
                  disabled={disable}
                  label="Select Status"
                >
                  <MenuItem value="">
                    <em>Select Car Type</em>
                  </MenuItem>
                  {Object.values(cars).map((name) => (
                    <MenuItem key={name} value={name.toLowerCase()}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              container
              className={classes.split}
            >
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Vehicle Modal"
                  margin="dense"
                  name="vehicleModel"
                  disabled={disable}
                  onChange={handleChange}
                  value={values.vehicleModel}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ flex: 0.48, display: 'flex', marginTop: 5 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Vehicle Year"
                  margin="dense"
                  name="vehicleYear"
                  disabled={disable}
                  onChange={handleChange}
                  value={values.vehicleYear}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>Vehicle Front Image:</Typography>
              {(values.vehicleFront && values.vehicleFront.length > 0 || carFront instanceof Blob) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => deleteUpdatedImage('vehicleFront', values.vehicleFront)}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.vehicleFront && values.vehicleFront.length > 0 ? (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.vehicleFront} className={classes.imgStyle} alt='rcImage' />
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box1}>
                      <input type='file'
                        name='vehicleFront'
                        onChange={carFrontChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box}>
                      <input type='file'
                        name='vehicleFront'
                        required
                        ref={refVf}
                        onChange={carFrontChange}
                      />
                    </div> : <div>Choose File</div>}
                </Grid>
              </Grid>
            )}
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>Vehicle Back Image:</Typography>
              {(values.vehicleBack && values.vehicleBack.length > 0 || carBack instanceof Blob) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => deleteUpdatedImage('vehicleBack', values.vehicleBack)}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.vehicleBack && values.vehicleBack.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.vehicleBack} className={classes.imgStyle} alt='vehicleBack' />
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box1}>
                      <input type='file'
                        name='vehicleBack'
                        onChange={carBackChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box}>
                      <input type='file'
                        name='vehicleBack'
                        required
                        ref={refVb}
                        onChange={carBackChange}
                      />
                    </div> : <div>Choose File</div>}
                </Grid>
              </Grid>
            }
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>Vehicle Side Image:</Typography>
              {(values.vehicleSide && values.vehicleSide.length > 0 || carSide instanceof Blob) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => deleteUpdatedImage('vehicleSide', values.vehicleSide)}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.vehicleSide && values.vehicleSide.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.vehicleSide} className={classes.imgStyle} alt='vehicleSide' />
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box1}>
                      <input type='file'
                        name='vehicleSide'
                        onChange={carSideChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box}>
                      <input type='file'
                        name='vehicleSide'
                        required
                        ref={refVs}
                        onChange={carSideChange}
                      />
                    </div> : <div>Choose File</div>}
                </Grid>
              </Grid>
            }
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>RC book Image:</Typography>
              {(values.rcImage && values.rcImage.length > 0 || rcImage instanceof Blob) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => deleteUpdatedImage('rcImage', values.rcImage)}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.rcImage && values.rcImage.length > 0 ? (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.rcImage} className={classes.imgStyle} alt='rcImage' />
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box1}>
                      <input type='file'
                        name='rcImage'
                        onChange={onRCBookChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box}>
                      <input type='file'
                        name='rcImage'
                        required
                        ref={refRc}
                        onChange={onRCBookChange}
                      />
                    </div> : <div>Choose File</div>}
                </Grid>
              </Grid>
            )}
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>Insurance Image:</Typography>
              {(values.insuranceImage && values.insuranceImage.length > 0 || insuranceImage instanceof Blob) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => deleteUpdatedImage('insuranceImage', values.insuranceImage)}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.insuranceImage && values.insuranceImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.insuranceImage} className={classes.imgStyle} alt='insuranceImage' />
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box1}>
                      <input type='file'
                        name='insuranceImage'
                        onChange={onInsuranceChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box}>
                      <input type='file'
                        name='insuranceImage'
                        required
                        ref={refIi}
                        onChange={onInsuranceChange}
                      />
                    </div> : <div>Choose File</div>}
                </Grid>
              </Grid>
            }
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>Permit Image:</Typography>
              {(values.permitImage && values.permitImage.length > 0 || permitImage instanceof Blob) && !disable && auth.info.profile.usertype === 'operator' ?
                <Button onClick={() => deleteUpdatedImage('permitImage', values.permitImage)}>
                  <CancelIcon />
                </Button>
                : null}
            </div>
            {values.permitImage && values.permitImage.length > 0 ?
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.join}
                >
                  <img src={values.permitImage} className={classes.imgStyle} alt='permitImage' />
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box1}>
                      <input type='file'
                        name='permitImage'
                        onChange={onPermitChange}
                      />
                    </div> : null}
                </Grid>
              </Grid>
              :
              <Grid
                container
                className={classes.grid}
              >
                <Grid
                  item
                  xs={12}
                >
                  {(auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') && !disable ?
                    <div className={classes.box}>
                      <input type='file'
                        name='permitImage'
                        required
                        ref={refPi}
                        onChange={onPermitChange}
                      />
                    </div> : <div>Choose File</div>}
                </Grid>
              </Grid>
            }
            <Grid
              container
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  name="insuranceExpiryDate"
                  type={'date'}
                  label='Insurance Expiry Date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={handleChange}
                  disabled={disable}
                  value={formatDate(values.insuranceExpiryDate)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  name="FCRenewDate"
                  type={'date'}
                  label='FC Renew Date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={disable}
                  onChange={handleChange}
                  value={formatDate(values.FCRenewDate)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  name="permitRenewDate"
                  type={'date'}
                  disabled={disable}
                  label='Permit Renew Date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={handleChange}
                  value={formatDate(values.permitRenewDate)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {auth.info.profile.usertype === 'admin' ?
              <Grid
                item
                style={{ flex: 0.48, display: 'flex' }}
              >
                <Typography style={{ alignSelf: 'center' }}>Approval Status:</Typography>
                <Checkbox
                  name="approvalStatus"
                  onChange={handleChange}
                  checked={values.approvalStatus}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid> : null}
            <Button
              type='submit'
              color="primary"
              variant="contained"
              size='small'
              className={classes.button}
              onClick={() => { id === '' ? validation(values) : updateVehicle(values, item) }}
            >
              {id === '' && (auth.info.profile.usertype === 'operator' || auth.info.profile.usertype === 'admin') ? !disable || letter ? 'Add' : 'Update' : 'Update'}
              {/* {id === '' ? 'Add' : 'Update'} */}
            </Button>
            <Button
              type='submit'
              variant="contained"
              size='small'
              className={classes.button}
              style={{ marginLeft: 20 }}
              onClick={() => {
                setEditOpen(false)
                setId('')
                emptyValue()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div >
    )
  };

  const viewOpenClose = () => {
    setViewOpen(false)
    setId('')
  }

  const handleOpenView = (item) => {
    setViewOpen(true)
    setId(item)
  }

  function viewModal(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 10 }}>
            <Typography style={{ marginTop: 10, fontSize: 20, color: 'grey', textAlign: 'center' }}>Vehicles Information</Typography>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Mobile Number</Typography>
              <Typography className={classes.typoText2}>: {mobileLook(item, getNumber)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Vehicle Number</Typography>
              <Typography className={classes.typoText2}>: {item.vehicleNo}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Operator</Typography>
              <Typography className={classes.typoText2}>: {lookup(item.operator, operators)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Driver</Typography>
              <Typography className={classes.typoText2}>: {lookup(item.driver, drivers)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Owner Name</Typography>
              <Typography className={classes.typoText2}>: {item.ownerName}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Color</Typography>
              <Typography className={classes.typoText2}>: {item.color}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Vehicle Chasis Number</Typography>
              <Typography className={classes.typoText2}>: {item.vehicleChasisNo}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Vehicle Engine Number</Typography>
              <Typography className={classes.typoText2}>: {item.vehicleEngineNo}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Vehicle Modal</Typography>
              <Typography className={classes.typoText2}>: {item.vehicleModel}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Vehicle Year</Typography>
              <Typography className={classes.typoText2}>: {item.vehicleYear}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Car Type</Typography>
              <Typography className={classes.typoText2}>: {item.carType}</Typography>
            </Grid>
            {item.vehicleFront ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Vehicle Front Image</Typography>
                :<img src={item.vehicleFront} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.vehicleBack ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Vehicle Back Image</Typography>
                :<img src={item.vehicleBack} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.vehicleSide ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Vehicle Side Image</Typography>
                :<img src={item.vehicleSide} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.rcImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Rc Book Image</Typography>
                :<img src={item.rcImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.insuranceImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>insurance Image</Typography>
                :<img src={item.insuranceImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            {item.permitImage ?
              <Grid
                item
                style={{ display: 'flex', marginTop: 10, flex: 1 }}
              >
                <Typography className={classes.typoText1}>Permit Image</Typography>
                :<img src={item.permitImage} className={classes.imgStyle} style={{ flex: 0.5 }} alt='licenseBackImage' />
              </Grid>
              : null}
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Insurance Expiry Date</Typography>
              <Typography className={classes.typoText2}>: {formatDate(item.insuranceExpiryDate)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>FC Renew Date</Typography>
              <Typography className={classes.typoText2}>: {formatDate(item.FCRenewDate)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Permit Renew Date</Typography>
              <Typography className={classes.typoText2}>: {formatDate(item.permitRenewDate)}</Typography>
            </Grid>
            <Grid
              item
              style={{ display: 'flex', marginTop: 10, flex: 1 }}
            >
              <Typography className={classes.typoText1}>Approval Status</Typography>
              <Typography className={classes.typoText2}>: {item.approvalStatus ? 'Approved' : 'Not Approved'}</Typography>
            </Grid>
            <Button
              type='submit'
              color='primary'
              variant="contained"
              size='small'
              className={classes.button}
              onClick={() => {
                setViewOpen(false)
                setId('')
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </div >
    )
  };

  const lookup = (item, data) => {
    var name = ''
    Object.keys(data).map((res, i) => (res === item ? name = Object.values(data)[i] : ''))
    return name
  }

  const mobileLook = (item, data) => {
    var name = ''
    Object.keys(data).map((res, i) => (res === item.operator ? name = Object.values(data)[i] : ''))
    return name
  }

  const date = (item) => {
    if (item !== '') {
      var date = new Date(item);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear()
    }
  }

  const preventRerender1 = (rowData) => {
    return (
      rowData ? <img alt='rc' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender2 = (rowData) => {
    return (
      rowData ? <img alt='insurance' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender3 = (rowData) => {
    return (
      rowData ? <img alt='permit' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender4 = (rowData) => {
    return (
      rowData ? <img alt='rc' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender5 = (rowData) => {
    return (
      rowData ? <img alt='insurance' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const preventRerender6 = (rowData) => {
    return (
      rowData ? <img alt='permit' src={rowData} style={{ width: 100, height: 100 }} /> : null
    )
  }

  const requestSearch = (e) => {
    var value = e.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').trim().toLowerCase()
    if (value === '') {
      if (auth.info.profile.usertype === 'operator') {
        const vehicles = vehiclesdata.vehicles.filter(vehicle => vehicle.operator === auth.info.uid);
        setData(vehicles);
      } else {
        const vehicles = vehiclesdata.vehicles.filter(vehicle => vehicle.fleetadmin === auth.info.uid || auth.info.profile.usertype === 'admin');
        setData(vehicles);
      }
    } else {
      const name = data.filter(e => {
        var driver = lookup(e.driver, drivers)
        var operator = lookup(e.operator, operators)
        return Object.values(e).join(driver + ' ' + operator).toLowerCase().match(value)
      })
      setData(name)
    }
  };

  return (
    vehiclesdata.loading ? <CircularLoading /> :
      <div>
        <div className={classes.row}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOpenAdd()}
          >
            <Add fontSize='small' />
          </Button>

          <TextField
            className={classes.searchInput}
            onChange={requestSearch}
            label="Search Vehicles"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>

        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardContent className={classes.content}>

            <div className={classes.inner}>
              {isMobile ?
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Action</TableCell>
                      <TableCell align='center'>Mobile Number</TableCell>
                    </TableRow>
                  </TableHead>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    return (
                      <TableBody key={i}>
                        <TableRow hover>
                          <TableCell align='center' size='medium' style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip
                              id="View"
                              title={'View'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenView(item)}
                              >
                                <VisibilityIcon fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Edit"
                              title={'Edit'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenEdit(item)}
                              >
                                <BorderColor fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Delete"
                              title={'Delete'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenModal(item)}
                              >
                                <Delete fontSize='medium' />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell align='center'>{mobileLook(item, getNumber)}</TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
                :
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((item, i) => {
                        return (
                          <TableCell align='center' key={i}>{item.title}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                    return (
                      <TableBody key={i}>
                        <TableRow hover>
                          <TableCell align='center' size='medium' style={{ display: 'flex', alignItems: 'center', height: 150 }}>
                            <Tooltip
                              id="Edit"
                              title={'Edit'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenEdit(item)}
                                className={classes.edit}
                              >
                                <BorderColor fontSize='medium' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="Delete"
                              title={'Delete'}
                              placement={window.innerWidth > 959 ? "top" : "left"}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                onClick={() => handleOpenModal(item)}
                                className={classes.edit}
                              >
                                <Delete fontSize='medium' />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          {/* <TableCell align='center'>{(page * rowsPerPage) + (i + 1)}</TableCell> */}
                          <TableCell align='center'>{mobileLook(item, getNumber)}</TableCell>
                          <TableCell align='center'>{lookup(item.operator, operators)}</TableCell>
                          <TableCell align='center'>{item.vehicleNo}</TableCell>
                          <TableCell align='center'>{item.ownerName}</TableCell>
                          <TableCell align='center'>{item.vehicleChasisNo}</TableCell>
                          <TableCell align='center'>{item.vehicleEngineNo}</TableCell>
                          <TableCell align='center'>
                            {preventRerender4(item.vehicleFront)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender5(item.vehicleBack)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender6(item.vehicleSide)}
                          </TableCell>
                          <TableCell align='center'>{item.vehicleModel}</TableCell>
                          <TableCell align='center'>{item.vehicleYear}</TableCell>
                          <TableCell align='center'>{item.color}</TableCell>
                          <TableCell align='center'>{item.carType.charAt(0).toUpperCase() + item.carType.slice(1)}</TableCell>
                          <TableCell align='center'>{lookup(item.driver, drivers)}</TableCell>
                          <TableCell align='center'>
                            {preventRerender1(item.rcImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender2(item.insuranceImage)}
                          </TableCell>
                          <TableCell align='center'>
                            {preventRerender3(item.permitImage)}
                          </TableCell>
                          <TableCell align='center'>{date(item.insuranceExpiryDate)}</TableCell>
                          <TableCell align='center'>{date(item.FCRenewDate)}</TableCell>
                          <TableCell align='center'>{date(item.permitRenewDate)}</TableCell>
                          <TableCell align='center'>
                            <Checkbox
                              name="approvalStatus"
                              disabled
                              checked={item.approvalStatus}
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </TableCell>
                          <TableCell align='center'>{item.commission_fees ? parseFloat(item.commission_fees).toFixed(2) : 0}</TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
              }
              <div>
                <Modal
                  open={viewOpen}
                  className={classes.Modal}
                  onClose={viewOpenClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {viewModal(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={editOpen}
                  className={classes.Modal}
                  onClose={editOpenClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={deleteModal}
                  className={classes.Modal1}
                  onClose={handleDeleteClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {deleteBody(id)}
                </Modal>
              </div>
              <div>
                <Modal
                  open={updateModal}
                  className={classes.Modal1}
                  onClose={handleUpdate}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {UpdateBody()}
                </Modal>
              </div>
            </div>

          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={data.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card >
      </div>
  );
};

Vehicles.propTypes = {
  className: PropTypes.string,
};

export default Vehicles;
