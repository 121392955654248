import React, { useState, useEffect, useContext } from 'react';
import {
  Select,
  MenuItem,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField
} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { language } from 'config';
import { makeStyles } from '@material-ui/core/styles';
import UsersCombo from '../components/UsersCombo';
import { FirebaseContext } from 'common';
import BookAny from '../assets/img/bookAny.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: '100%'
  }, textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  items: {
    margin: 0,
    width: '98%',
    padding: 40,
    fontSize: 20,
    fontWeight: '600'
  },
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15
  },
  bodyPaper: {
    width: '30%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 10,
    height: 300,
    display: 'flex',
    alignSelf: 'center',
    "@media (max-width: 480px)": {
      width: '100%',
    }
  },
  buttonPaper: {
    marginTop: 15,
    textTransform: 'capitalize',
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
    width: 100
  },
  bodyPaper2: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-20px',
    right: '-20px',
    overflow: 'scroll',
    "@media (max-width: 480px)": {
      right: 0,
    },
  },
}));

export default function AddBookings(props) {
  const { api, singleUserRef, usersRef } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    checkUserExists,
    mobileSignUp,
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const cartypes = useSelector(state => state.cartypes.cars);
  const estimatedata = useSelector(state => state.estimatedata);
  const bookingdata = useSelector(state => state.bookingdata);
  const userdata = useSelector(state => state.usersdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [carType, setCarType] = useState(language.select_car);
  const [driverType, setDriverType] = useState(language.select_driver);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = React.useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const [selectedDriverDetails, setSelectedDriverDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [userCombo, setUserCombo] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState('local');
  const [passengerCount, setPassengerCount] = useState(language.no_passengers);
  const [bookType, setBookType] = useState(language.book_type);
  const [drivers, setDrivers] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const tripdata = useSelector(state => state.tripdata);
  const [carTypeObject, setCarTypeObject] = useState({})
  const [load, setLoad] = useState(false)
  const [FormOpen, setFormOpen] = useState(false);
  const auth = useSelector(state => state.auth);
  const estimate = useSelector(state => state.estimatedata.estimate);
  const [role, setRole] = useState(null);

  const getDateString = (date) => {
    let d = null;
    d = date ? new Date(date) : new Date();

    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      mins = d.getMinutes();
    if (month >= 1 & month <= 9)
      month = '0' + month.toString();
    if (day >= 0 & day <= 9)
      day = '0' + day.toString();
    if (hours >= 0 & hours <= 9)
      hours = '0' + hours.toString();
    if (mins >= 0 & mins <= 9)
      mins = '0' + mins.toString();

    return [year, month, day].join('-') + 'T' + [hours, mins].join(':');
  }

  const dateDiff = (date) => {
    const date1 = new Date();
    const date2 = new Date(date);
    const diffTime = date2 - date1;
    return diffTime / (1000 * 60);
  }

  const [selectedDate, setSelectedDate] = React.useState(getDateString());

  // const handleCarSelect = (event) => {
  //   setCarType(event.target.value);
  //   let carDetails = null;
  //   for (let i = 0; i < cartypes.length; i++) {
  //     if (cartypes[i].name === event.target.value) {
  //       carDetails = cartypes[i];
  //     }
  //   }
  //   setSelectedCarDetails(carDetails);
  // };

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
      }
      if (event.target.value === 'any') {
        carDetails = cartypes
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleDriverSelect = (event) => {
    setDriverType(event.target.value);
    let driverDetails = null;
    for (let i = 0; i < drivers.length; i++) {
      var name = drivers[i].firstName + ' ' + drivers[i].lastName;
      if (name === event.target.value) {
        driverDetails = drivers[i];
      }
    }
    setSelectedDriverDetails(driverDetails);
  };

  const handlePassengerCount = (event) => {
    setPassengerCount(event.target.value);
  };

  const handleBookType = (event) => {
    setBookType(event.target.value);
  };

  // const handleBookTypeSelect = (event) => {
  //   setBookingType(event.target.value);
  //   // if (bookingType === 'Book Later') {
  //   //   setSelectedDate(getDateString());
  //   // }
  // };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'rider') {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + user.mobile + ') ' + user.email,
            'pushToken': user.pushToken
          });
        }
      }
      setUsers(arr);
    }
  }, [estimatedata.estimate, userdata.users, estimateRequested]);

  useEffect(() => {
    if (estimatedata.estimate) {
      console.log(estimate)
      setCarTypeObject(estimate)
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [estimatedata.estimate, auth.info]);

  useEffect(() => {
    if (userdata.users) {
      const drv = userdata.users.filter(user => user.usertype === 'driver');
      setDrivers(drv);
    }
  }, [userdata.users]);

  useEffect(() => {
    console.log(pickupAddress && dropAddress && bookType)
    if (pickupAddress && dropAddress) {
      dispatch(getEstimate({
        platform: 'web',
        bookLater: false,
        bookingDate: null,
        pickup: pickupAddress,
        drop: dropAddress,
        carDetails: cartypes,
        bookType: bookType
      }));
    }
  }, [pickupAddress, dropAddress && bookType])

  const handleGetEstimate = (e) => {
    e.preventDefault();
    if (auth.info) {
      if (pickupAddress && dropAddress && selectedCarDetails && passengerCount !== language.no_passengers && userCombo) {
        setEstimateModalStatus(true);
      } else {
        setCommonAlert({ open: true, msg: language.select_proper })
      }
    } else {
      setCommonAlert({ open: true, msg: language.must_login })
    }
  };

  // const handleGetEstimate = (e) => {
  //   e.preventDefault();
  //   setEstimateRequested(true);
  //   if (userCombo && pickupAddress && dropAddress && selectedCarDetails && bookType !== language.book_type && passengerCount !== language.no_passengers) {
  //     if (bookingType === 'Book Now') {
  //       dispatch(getEstimate({
  //         pickup: pickupAddress,
  //         drop: dropAddress,
  //         carDetails: selectedCarDetails,
  //         bookType: bookType
  //       }));

  //     } else {
  //       if (bookingType === 'Book Later' && selectedDate) {
  //         if (dateDiff(selectedDate) >= 15) {
  //           dispatch(getEstimate({
  //             pickup: pickupAddress,
  //             drop: dropAddress,
  //             carDetails: selectedCarDetails,
  //             bookType: bookType
  //           }));
  //         } else {
  //           setCommonAlert({ open: true, msg: language.past_booking_error });
  //         }
  //       } else {
  //         setCommonAlert({ open: true, msg: language.select_proper });
  //       }
  //     }
  //   } else {
  //     setCommonAlert({ open: true, msg: language.select_proper })
  //   }
  // };

  // const confirmBooking = (e) => {
  //   e.preventDefault();
  //   setEstimateModalStatus(false);
  //   let bookingObject = {
  //     pickup: pickupAddress,
  //     drop: dropAddress,
  //     carDetails: selectedCarDetails,
  //     userDetails: {
  //       uid: userCombo.uid,
  //       profile: {
  //         firstName: userCombo.firstName,
  //         lastName: userCombo.lastName,
  //         mobile: userCombo.mobile,
  //         pushToken: userCombo.pushToken
  //       }
  //     },
  //     estimate: estimatedata.estimate,
  //     tripdate: new Date(selectedDate).toString(),
  //     bookLater: bookingType === 'Book Later' ? true : false,
  //     settings: settings,
  //     booking_type_web: true,
  //     bookType: bookType,
  //     passengerCount: passengerCount,
  //     driverDetails: selectedDriverDetails
  //   };

  //   dispatch(addBooking(bookingObject));
  // };
  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    var obj = {
      pickup: pickupAddress,
      drop: dropAddress,
      carTypeName: carType,
      carDetails: selectedCarDetails,
      userDetails: {
        uid: userCombo.uid,
        profile: {
          firstName: userCombo.firstName,
          lastName: userCombo.lastName,
          mobile: userCombo.mobile,
          pushToken: userCombo.pushToken
        }
      },
      estimate: carType === 'Mini' ? carTypeObject[0] : carType === 'Prime Sedan' ? carTypeObject[1] : carTypeObject,
      tripdate: new Date(selectedDate).toString(),
      bookLater: bookingType === 'Book Later' ? true : false,
      settings: settings,
      booking_type_web: true,
      bookType: bookType,
      passenger_count: passengerCount,
      driverDetails: selectedDriverDetails
    }
    console.log(obj)
    dispatch(addBooking(obj))

  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
    props.history.push('/bookings');
  };

  const clearForm = () => {
    setUserCombo(null);
    setPickupAddress(null);
    setDropAddress(null);
    setSelectedCarDetails(null);
    setSelectedDriverDetails(null);
    setCarType(language.select_car);
    setDriverType(language.select_driver);
    setBookingType(language.book_now);
    setBookType(language.book_type);
    setPassengerCount(language.no_passengers);
    setEstimateRequested(false);
  }

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const [riders, setRiders] = useState({})
  const [formValue, setFormValue] = useState({
    mobile: '',
    firstName: '',
    lastName: ''
  })
  const [data, setData] = useState({
    profile: {},
    uid: ''
  })

  useEffect(() => {
    if (usersdata.users) {
      const riders = usersdata.users.filter(user => user.usertype === 'rider');
      let obj = {};
      riders.map((rider) => {
        const fullname = rider.firstName + ' ' + rider.lastName;
        let name = rider.id
        obj[name] = fullname;
        return name;
      });
      setRiders(obj);
    }
  }, [usersdata.users]);

  const closeForm = () => {
    setFormOpen(false)
  }

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
    if (event.target.name === 'mobile') {
      if (event.target.value.length === 10) {
        let formattedNum = '+91' + event.target.value
        checkUserExists({ mobile: formattedNum }).then((res) => {
          if (res.users && res.users.length > 0) {
            const rider = Object.keys(riders).filter(item => res.users[0].uid === item)
            if (res.users[0].uid === rider[0]) {
              var arr = []
              singleUserRef(res.users[0].uid).once("value", snapshot => {
                var user = snapshot.val()
                arr.push({
                  'firstName': user.firstName,
                  'lastName': user.lastName,
                  'mobile': user.mobile,
                  'email': user.email,
                  'uid': res.users[0].uid,
                  'desc': user.firstName + ' ' + user.lastName + ' (' + user.mobile + ') ' + user.email,
                  'pushToken': user.pushToken
                });
                setUserCombo(arr[0])
                alert('You already have this rider')
                setFormOpen(false)
                setFormValue({
                  ...formValue,
                  mobile: ''
                })
              })
            }
          }
        });
      }
    }
  }

  const validation = () => {
    if (auth.info) {
      setLoad(true)
      let formattedNum = '+91' + formValue.mobile
      if (formattedNum.length > 8 && formValue.firstName !== '' && formValue.lastName !== '') {
        // checkUserExists({ mobile: formattedNum }).then((res) => {
        //   if (res.users && res.users.length > 0) {
        //     const rider = Object.keys(riders).filter(item => res.users[0].uid === item)
        //     if (res.users[0].uid === rider[0]) {
        //       setFormOpen(false)
        //       singleUserRef(res.users[0].uid).once("value", snapshot => {
        //         setData({
        //           ...data, profile: snapshot.val(), uid: res.users[0].uid
        //         })
        //         setLoad(false)
        //       })
        //     }
        //   }
        //   else {
        var data = {
          mobile: formValue.mobile,
          password: 'zeburider',
          usertype: 'rider'
        }
        mobileSignUp(data).then((res) => {
          if (res.error) {
            let error = res.error;
            let errtext = error.replace("email address", "mobile number");
            alert(errtext);
            setLoad(false)
          } else {
            setFormOpen(false)
            let obj = {
              firstName: formValue.firstName,
              lastName: formValue.lastName,
            }
            usersRef.child(res.uid).update(obj).then(() => {
              var arr = []
              singleUserRef(res.uid).once("value", snapshot => {
                var user = snapshot.val()
                arr.push({
                  'firstName': user.firstName,
                  'lastName': user.lastName,
                  'mobile': user.mobile,
                  'email': '',
                  'uid': res.uid,
                  'desc': user.firstName + ' ' + user.lastName + ' (' + user.mobile + ') ' + user.email,
                  'pushToken': user.pushToken
                });
                setUserCombo(arr[0])
                setFormValue({
                  mobile: ''
                })
                setLoad(false)
              })
            }).catch((error) => {
              setLoad(false)
              alert(error.message);
            });

          }
        });
      }
      //   });
      // } else {
      //   setLoad(false)
      //   alert(language.mobile_no_blank_error);
      // }
    } else {
      setCommonAlert({ open: true, msg: language.must_login })
    }
  }

  function body() {
    return (
      <div className={classes.bodyPaper}>
        <div className={classes.bodyPaper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 20 }}>
            <Typography style={{ fontSize: 20, color: 'grey', textAlign: 'center' }}>Add Rider</Typography>

            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                label="Enter Mobile Number"
                margin="dense"
                name="mobile"
                value={formValue.mobile}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                label="Enter First Name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                value={formValue.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                label="Enter Last Name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                value={formValue.lastName}
                variant="outlined"
              />
            </Grid>
            {!load ?
              <Button
                type='submit'
                color="success"
                variant="contained"
                size='small'
                className={classes.buttonPaper}
                onClick={() => validation()}
              >
                Add
              </Button> :
              <CircularProgress size={15} />}
            <Button
              type='submit'
              variant="contained"
              size='small'
              className={classes.buttonPaper}
              style={{ marginLeft: 20 }}
              onClick={() => {
                setFormOpen(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div >
    )
  };

  return (
    <div className={classes.container}>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
              {language.addbookinglable}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            {users ?
              <UsersCombo
                className={classes.items}
                placeholder={language.select_user}
                users={users}
                value={userCombo}
                onChange={(event, newValue) => {
                  setUserCombo(newValue);
                }}
              />
              : null}
            {/* <TextField
              required
              fullWidth
              label="Select User"
              // name="mobile"
              onChange={handleChange}
              value={data.uid === '' ? '' : formValue.mobile + ' ' + formValue.firstName}
              disabled={true}
              variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={12} sm={1} style={{ display: 'flex', alignItems: 'center' }}>
            <a href='#'
              onClick={() => setFormOpen(true)}
            >
              Add User
            </a>
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={language.pickup_location}
              value={pickupAddress}
              className={classes.items}
              onChange={
                (value) => {
                  setPickupAddress(value);
                }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete placeholder={language.drop_location}
              variant={"outlined"}
              value={dropAddress}
              className={classes.items}
              onChange={
                (value) => {
                  setDropAddress(value);
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              id="book-type-native"
              value={bookType}
              onChange={handleBookType}
              variant="outlined"
              fullWidth
              className={bookType === language.book_type ? classes.inputdimmed : classes.input}
            >
              <MenuItem key={language.book_type} value={language.book_type}>
                {language.book_type}
              </MenuItem>
              <MenuItem key={language.book_local} value={'local'}>
                {language.book_local}
              </MenuItem>
              <MenuItem key={language.book_outstation} value={'outstation'}>
                {language.book_outstation}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              id="passenger-count-native"
              value={passengerCount}
              onChange={handlePassengerCount}
              variant="outlined"
              fullWidth
              className={passengerCount === language.no_passengers ? classes.inputdimmed : classes.input}
            >
              <MenuItem key={language.no_passengers} value={language.no_passengers}>
                {language.no_passengers}
              </MenuItem>
              <MenuItem key={"1"} value={"1"}>
                {"1"}
              </MenuItem>
              <MenuItem key={"2"} value={"2"}>
                {"2"}
              </MenuItem>
              <MenuItem key={"3"} value={"3"}>
                {"3"}
              </MenuItem>
              <MenuItem key={"4"} value={"4"}>
                {"4"}
              </MenuItem>
              <MenuItem key={"5"} value={"5"}>
                {"5"}
              </MenuItem>
              <MenuItem key={"6"} value={"6"}>
                {"6"}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            {cartypes ?
              <Select
                id="car-type-native"
                value={carType}
                onChange={handleCarSelect}
                variant="outlined"
                fullWidth
                className={carType === language.select_car ? classes.inputdimmed : classes.input}
              >
                <MenuItem value={language.select_car} key={language.select_car}>
                  {language.select_car}
                </MenuItem>
                <MenuItem key={'any'} value={'any'}>
                  <img src={BookAny} className={classes.carphoto} alt="car types" />Book Any
                </MenuItem>
                {
                  cartypes.map((car) =>
                    <MenuItem key={car.name} value={car.name}>
                      <img src={car.selectedImage} className={classes.carphoto} alt="car types" />{car.name}
                    </MenuItem>
                  )
                }
              </Select>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {drivers ?
              <Select
                id="driver-native"
                value={driverType}
                onChange={handleDriverSelect}
                variant="outlined"
                fullWidth
                className={driverType === language.select_driver ? classes.inputdimmed : classes.input}
              >
                <MenuItem value={language.select_driver} key={language.select_driver}>
                  {language.select_driver}
                </MenuItem>
                {
                  drivers.map((driver) =>
                    <MenuItem key={driver.firstName} value={driver.firstName + ' ' + driver.lastName}>
                      {driver.firstName + ' ' + driver.lastName}
                    </MenuItem>
                  )
                }
              </Select>
              : null}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Select
              id="booking-type-native"
              value={bookingType}
              onChange={handleBookTypeSelect}
              className={classes.input}
              variant="outlined"
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem key={"Book Now"} value={"Book Now"}>
                {language.book_now}
              </MenuItem>
              <MenuItem key={"Book Later"} value={"Book Later"}>
                {language.book_later}
              </MenuItem>
            </Select>
          </Grid> */}
          {/* {bookingType === 'Book Later' ?
            <Grid item xs={12} sm={6} >
              <TextField
                id="datetime-local"
                label={language.booking_date_time}
                type="datetime-local"
                variant="outlined"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input
                }}
                value={selectedDate}
                onChange={onDateChange}
              />
            </Grid>
            : null} */}
          <Grid item xs={12} >
            <Button
              size="large"
              onClick={handleGetEstimate}
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
            >
              <i className="fas fa-car" />
              {language.book}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Modal
          open={FormOpen}
          className={classes.Modal}
          onClose={closeForm}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body()}
        </Modal>
      </div>
      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.estimate}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {settings ? settings.symbol : null} {estimatedata.estimate ? estimatedata.estimate.estimateFare : null} */}
            {settings ? settings.symbol : null} {carTypeObject && carTypeObject.length > 0 ? carType === 'Mini' ? carTypeObject[0].estimateFare : carType === 'Prime Sedan' ? carTypeObject[1].estimateFare : carTypeObject[0].estimateFare + '-' + carTypeObject[1].estimateFare : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.cancel}
          </Button>
          <Button onClick={confirmBooking} color="primary" autoFocus>
            {language.book_now}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog open={bookingdata.booking ? true : false} onClose={handleBookingAlertClose}>{bookingdata.booking ? language.booking_success + bookingdata.booking.booking_id : null}</AlertDialog>
      <AlertDialog open={bookingdata.error.flag} onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
      <AlertDialog open={estimatedata.error.flag} onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}