import { container } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "#5f6170",
    padding: "0.9375rem",
    fontWeight: 500,
    fontSize: "18px",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    fontFamily: "Poppins",
    letterSpacing: '1px',
    cursor: 'pointer',
    "&:hover": {
      color: "blue",
    },
  },
  button: {
    color: '#5f6170',
    "&:hover": {
      color: "blue",
    },
  },
  terms: {
    fontSize: '17px',
    letterSpacing: '1px',
    textDecoration: 'underline',
    color: "#5f6170",
    fontWeight: 400,
    fontFamily: "Poppins",
    display: "block",
    position: "relative",
    padding: '35px',
    "&:hover": {
      color: "blue",
    },
    // color: "#d4d5d7",
    // padding: "0.9375rem",
    // fontWeight: "500",
    // fontSize: "16px",
    // textDecoration: "underline",
    // position: "relative",
    // display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  middle: {
    float: "center!important",
    display: "block"
  },
  right: {
    padding: "0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: 'white',
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  'a:hover': {
    color: 'white',
    textDecoration: "none",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  }
};
export default footerStyle;