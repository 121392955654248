import {
  Circle,
  GoogleMap,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import React, { useEffect, useState } from 'react';

import MarkerComponent from './Marker';

class Map extends React.Component {

  render = () => {
    return (
      <GoogleMap
        defaultZoom={this.props.detail && this.props.detail.pickup ? 14 : 12}
        defaultCenter={{ lat: this.props.detail && this.props.detail.pickup ? parseFloat(this.props.detail.pickup.lat) : 9.925067, lng: this.props.detail && this.props.detail.pickup ? parseFloat(this.props.detail.pickup.lng) : 78.118863 }}
      >
        {this.props.detail && this.props.radius ?
          <Circle
            defaultCenter={{
              lat: parseFloat(this.props.detail.pickup.lat),
              lng: parseFloat(this.props.detail.pickup.lng)
            }}
            radius={this.props.radius}
            options={{ strokeColor: "grey", strokeWeight: 2 }}
          /> : null}
        {this.props.locations.map(marker => {
          var path = [];
          // let obj1 = {
          //   lat: marker.oldlat ? marker.oldlat : marker.lat,
          //   lng: marker.oldlng ? marker.oldlng : marker.lng,
          // }
          let obj2 = {
            lat: marker.lat,
            lng: marker.lng
          }
          // path.push(obj1);
          path.push(obj2);
          return (<MarkerComponent key={marker.id} locations={path} marker={marker} />);
        })}

      </GoogleMap>
    );
  };
}

const MapComponent = withScriptjs(withGoogleMap(Map));

export default MapComponent;
