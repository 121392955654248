import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton
} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useSelector, useDispatch } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertDialog from '../components/AlertDialog';
import { language } from 'config';
import { FirebaseContext } from 'common';
import { Grid, ListItem, List } from '@material-ui/core';
import footerLogo from '../assets/img/footertaxi.png';
import MiniBig from '../assets/img/mini_big.png';
import MiniSmall from '../assets/img/mini_small.png';
import MicroBig from '../assets/img/micro_big.png';
import MicroSmall from '../assets/img/micro_small.png';
import PrimesedanBig from '../assets/img/primesedan_big.png';
import PrimesedanSmall from '../assets/img/primesedan_small.png';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import RoomIcon from '@material-ui/icons/Room';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import Cab from '../assets/img/book_cab.png';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Map from '../assets/img/map.png';
import Appstore from '../assets/img/appstore1.png';
import Googleplay from '../assets/img/googleplay1.png';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Modal from '@material-ui/core/Modal';

import Card1 from '../assets/img/card1.png';
import Card2 from '../assets/img/card2.png';
import Card3 from '../assets/img/card3.png';
import Card4 from '../assets/img/card4.png';

import Feature from '../assets/img/feature.png';
import featureHide from '../assets/img/featureHide.png';
import Rupee from '../assets/img/rupee.png';
import Rupee2 from '../assets/img/rupee2.png';
import WifiActive from '../assets/img/wifiactive.png';
import User1 from '../assets/img/user1.png';
import BookAny from '../assets/img/bookAny.png';
import CircularProgress from '@material-ui/core/CircularProgress';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const { api, singleUserRef, usersRef } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
    checkUserExists,
    mobileSignUp,
    fetchAddressfromCoords,
    updateTripPickup,
    updateTripDrop,
    GetDistance
  } = api;

  const images = [
    { url: MicroBig },
    { url: MiniBig },
    { url: PrimesedanBig },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const { ...rest } = props;
  const cartypes = useSelector(state => state.cartypes.cars);
  const estimatedata = useSelector(state => state.estimatedata);
  const estimate = useSelector(state => state.estimatedata.estimate);
  const bookingdata = useSelector(state => state.bookingdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [carType, setCarType] = useState(language.select_car);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = React.useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const auth = useSelector(state => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [bookingType, setBookingType] = useState(language.book_type);
  const [role, setRole] = useState(null);
  const CollectionSize = images.length;
  const [index, setActiveStep] = React.useState(0);
  const [activeButton, setActiveButton] = useState(0)
  const usersdata = useSelector(state => state.usersdata);
  const tripdata = useSelector(state => state.tripdata);
  const [carTypeObject, setCarTypeObject] = useState({})
  const [load, setLoad] = useState(false)

  const [FormOpen, setFormOpen] = useState(false);

  const goToNextPicture = () => {
    if (index !== CollectionSize - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveButton((prevActiveStep) => prevActiveStep + 1)
    }
  };
  const goToNextPicture1 = () => {
    if (index !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveButton((prevActiveStep) => prevActiveStep - 1)
    }
  };

  const [selectedDate, setSelectedDate] = React.useState(GetDateString());

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
      }
      if (event.target.value === 'any') {
        carDetails = cartypes
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    // if (bookingType === 'Book Later') {
    //   setSelectedDate(GetDateString());
    // }
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate) {
      setCarTypeObject(estimate)
      // setEstimateModalStatus(true);
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [estimatedata.estimate, auth.info]);

  useEffect(() => {
    if (pickupAddress && dropAddress && bookingType) {
      dispatch(getEstimate({
        platform: 'web',
        bookLater: false,
        bookingDate: null,
        pickup: pickupAddress,
        drop: dropAddress,
        carDetails: cartypes,
        bookType: bookingType
      }));
    }
  }, [pickupAddress, dropAddress, bookingType])


  const handleGetEstimate = (e) => {
    e.preventDefault();
    if (auth.info) {
      if (pickupAddress && dropAddress && selectedCarDetails) {
        setEstimateModalStatus(true);
        // if (bookingType === 'Book Now') {
        // dispatch(getEstimate({
        //   platform: 'web',
        //   pickup: pickupAddress,
        //   drop: dropAddress,
        //   carDetails: selectedCarDetails,
        // }));
        // dispatch(getEstimate({
        //   platform: 'web',
        //   bookLater: false,
        //   bookingDate: null,
        //   pickup: pickupAddress,
        //   drop: dropAddress,
        //   carDetails: cartypes,
        //   bookType: bookingType
        // }));
        // } else {
        //   if (bookingType === 'Book Later' && selectedDate) {
        //     if (MinutesPassed(selectedDate) >= 15) {
        //       dispatch(getEstimate({
        //         platform: 'web',
        //         pickup: pickupAddress,
        //         drop: dropAddress,
        //         carDetails: selectedCarDetails,
        //       }));
        //     } else {
        //       setCommonAlert({ open: true, msg: language.past_booking_error });
        //     }
        //   } else {
        //     setCommonAlert({ open: true, msg: language.select_proper });
        //   }
        // }
      } else {
        setCommonAlert({ open: true, msg: language.select_proper })
      }
    } else {
      setCommonAlert({ open: true, msg: language.must_login })
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    // dispatch(addBooking({
    //   pickup: pickupAddress,
    //   drop: dropAddress,
    //   carDetails: selectedCarDetails,
    //   userDetails: auth.info,
    //   estimate: estimatedata.estimate,
    //   tripdate: new Date(selectedDate).toString(),
    //   bookLater: bookingType === 'Book Later' ? true : false,
    //   settings: settings,
    //   booking_type_web: true
    // }));
    var obj = {
      pickup: pickupAddress,
      drop: dropAddress,
      carTypeName: carType,
      carDetails: selectedCarDetails,
      userDetails: data,
      estimate: carType === 'Mini' ? carTypeObject[0] : carType === 'Prime Sedan' ? carTypeObject[1] : carTypeObject,
      tripdate: new Date(selectedDate).toString(),
      bookLater: bookingType === 'Book Later' ? true : false,
      settings: settings,
      booking_type_web: true,
      bookType: bookingType
    }
    // console.log(obj)
    dispatch(addBooking(obj))

  };


  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    props.history.push('/bookings');
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const slider = (item) => {
    setActiveStep(item);
    setActiveButton(item)
  }

  const button_Data = [
    {
      name: "Micro",
      value: "name"
    },
    {
      name: "Mini",
      value: "class"
    },
    {
      name: "Prime Sedan",
      value: "age"
    }
  ];

  const [riders, setRiders] = useState({})
  const [formValue, setFormValue] = useState({
    mobile: '',
    firstName: '',
    lastName: ''
  })
  const [data, setData] = useState({
    profile: {},
    uid: ''
  })

  useEffect(() => {
    if (usersdata.users) {
      const riders = usersdata.users.filter(user => user.usertype === 'rider');
      let obj = {};
      riders.map((rider) => {
        const fullname = rider.firstName + ' ' + rider.lastName;
        let name = rider.id
        obj[name] = fullname;
        return name;
      });
      setRiders(obj);
    }
  }, [usersdata.users]);

  const closeForm = () => {
    setFormOpen(false)
  }

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
    if (event.target.name === 'mobile') {
      if (event.target.value.length === 10) {
        let formattedNum = '+91' + event.target.value
        checkUserExists({ mobile: formattedNum }).then((res) => {
          if (res.users && res.users.length > 0) {
            const rider = Object.keys(riders).filter(item => res.users[0].uid === item)
            if (res.users[0].uid === rider[0]) {
              singleUserRef(res.users[0].uid).once("value", snapshot => {
                setFormValue({
                  ...formValue,
                  firstName: snapshot.val().firstName,
                  lastName: snapshot.val().lastName,
                  mobile: snapshot.val().mobile
                })
              })
            }
          }
        });
      } else {
        setFormValue({
          ...formValue,
          firstName: '',
          lastName: '',
          mobile: event.target.value
        })
      }
    }
  }

  const validation = () => {
    if (auth.info) {
      setLoad(true)
      let formattedNum = '+91' + formValue.mobile
      if (formattedNum.length > 8 && formValue.firstName !== '' && formValue !== '') {
        checkUserExists({ mobile: formattedNum }).then((res) => {
          if (res.users && res.users.length > 0) {
            const rider = Object.keys(riders).filter(item => res.users[0].uid === item)
            if (res.users[0].uid === rider[0]) {
              setFormOpen(false)
              singleUserRef(res.users[0].uid).once("value", snapshot => {
                setData({
                  ...data, profile: snapshot.val(), uid: res.users[0].uid
                })
                setLoad(false)
              })
            }
          }
          else {
            var data = {
              mobile: formValue.mobile,
              password: 'zeburider',
              usertype: 'rider'
            }
            mobileSignUp(data).then((res) => {
              if (res.error) {
                let error = res.error;
                let errtext = error.replace("email address", "mobile number");
                alert(errtext);
                setLoad(false)
              } else {
                setFormOpen(false)
                let obj = {
                  firstName: formValue.firstName,
                  lastName: formValue.lastName,
                }
                usersRef.child(res.uid).update(obj).then(() => {
                  singleUserRef(res.uid).once("value", snapshot => {
                    setData({
                      ...data, profile: snapshot.val(), uid: res.uid
                    })
                    setLoad(false)
                  })
                }).catch((error) => {
                  setLoad(false)
                  alert(error.message);
                });

              }
            });
          }
        });
      } else {
        alert(language.mobile_no_blank_error);
      }
    } else {
      setCommonAlert({ open: true, msg: language.must_login })
    }
  }

  function body() {
    return (
      <div className={classes.bodyPaper}>
        <div className={classes.bodyPaper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 20 }}>
            <Typography style={{ fontSize: 20, color: 'grey', textAlign: 'center' }}>Add Rider</Typography>

            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                label="Enter Mobile Number"
                margin="dense"
                name="mobile"
                value={formValue.mobile}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                label="Enter First Name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                value={formValue.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              style={{ flex: 1, display: 'flex', marginTop: 5 }}
            >
              <TextField
                required
                fullWidth
                label="Enter Last Name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                value={formValue.lastName}
                variant="outlined"
              />
            </Grid>
            {!load ?
              <Button
                type='submit'
                color="success"
                variant="contained"
                size='small'
                className={classes.buttonPaper}
                onClick={() => validation()}
              >
                Add
              </Button> :
              <CircularProgress size={15} />}
            <Button
              type='submit'
              variant="contained"
              size='small'
              className={classes.buttonPaper}
              style={{ marginLeft: 20 }}
              onClick={() => {
                setFormOpen(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div >
    )
  };

  return (
    <div style={{ backgroundColor: '#070930' }}>
      <Header
        color="white"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <div className={classes.carStyle}>
        {(cartypes && !role) || (cartypes && (role === 'rider' || role === 'admin')) ?
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={6} >
              <img src={Cab}
                className={classes.book_cab}
                alt="car types" />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container item xs style={{ marginTop: 10 }}>
                    <GpsFixedIcon fontSize='small' style={{ alignSelf: 'center', marginRight: 10 }} />
                    <GoogleMapsAutoComplete
                      placeholder={language.pickup_location}
                      variant={"filled"}
                      value={pickupAddress}
                      onChange={
                        (value) => {
                          setPickupAddress(value);
                          // let latlng = value.coords.lat + ',' + value.coords.lng;
                          // fetchAddressfromCoords(latlng).then((res) => {
                          //   if (res) {
                          //     dispatch(updateTripPickup({
                          //       lat: value.coords.lat,
                          //       lng: value.coords.lat,
                          //       add: res,
                          //       source: 'geolocation'
                          //     }));
                          //   }
                          // });
                        }
                      }
                      style={{ flex: 1 }}
                    />
                  </Grid>
                  <Grid container item xs style={{ marginTop: 20 }}>
                    <RoomIcon fontSize='small' style={{ alignSelf: 'center', marginRight: 10 }} />
                    <GoogleMapsAutoComplete
                      placeholder={language.drop_location}
                      variant={"filled"}
                      value={dropAddress}
                      onChange={
                        (value) => {
                          setDropAddress(value);
                          // let latlng = value.coords.lat + ',' + value.coords.lng;
                          // fetchAddressfromCoords(latlng).then((res) => {
                          //   if (res) {
                          //     dispatch(updateTripDrop({
                          //       lat: value.coords.lat,
                          //       lng: value.coords.lat,
                          //       add: res,
                          //       source: 'init'
                          //     }));
                          //   }
                          // });
                        }
                      }
                      style={{ flex: 1 }}
                    />
                  </Grid>
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid style={{ display: 'flex', }} item xs={6}>
                      <LocalTaxiIcon fontSize='small' style={{ alignSelf: 'center', marginRight: 10 }} />
                      <FormControl style={{ width: '95%' }}>
                        <Select
                          id="car-type-native"
                          value={carType}
                          onChange={handleCarSelect}
                          className={carType === language.select_car ? classes.inputdimmed : classes.input}
                        >
                          <MenuItem value={language.select_car} key={language.select_car}>
                            {language.select_car}
                          </MenuItem>
                          <MenuItem key={'any'} value={'any'}>
                            <img src={BookAny} className={classes.carphoto} alt="car types" />Book Any
                          </MenuItem>
                          {
                            cartypes.map((car) =>
                              <MenuItem key={car.name} value={car.name}>
                                <img src={car.selectedImage} className={classes.carphoto} alt="car types" />{car.name}
                              </MenuItem>
                            )
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <FormControl style={{ width: '95%' }}>
                        <Select
                          id="booking-type-native"
                          value={bookingType}
                          onChange={handleBookTypeSelect}
                          className={classes.input}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem key={language.book_type} value={language.book_type}>
                            {language.book_type}
                          </MenuItem>
                          <MenuItem key={language.book_local} value={'local'}>
                            {language.book_local}
                          </MenuItem>
                          <MenuItem key={language.book_outstation} value={'outstation'}>
                            {language.book_outstation}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 20 }}>
                    {/* {bookingType === 'Book Later' ?
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <TextField
                          id="datetime-local"
                          label={language.booking_date_time}
                          type="datetime-local"
                          variant="filled"
                          fullWidth
                          className={classes.commonInputStyle}
                          InputProps={{
                            className: classes.input
                          }}
                          value={selectedDate}
                          onChange={onDateChange}
                        />
                      </Grid>
                      : null} */}
                    {/* <Grid item xs={6}>
                      <Button
                        color="success"
                        size="lg"
                        rel="noopener noreferrer"
                        className={classes.items}
                        onClick={() => setFormOpen(true)}
                        style={{ height: bookingType === 'Book Later' ? 76 : 52 }}
                      >
                        <AccountCircleIcon fontSize='small' />
                        {'Rider'}
                      </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        color="success"
                        size="lg"
                        rel="noopener noreferrer"
                        className={classes.items}
                        disabled={
                          pickupAddress && dropAddress && selectedCarDetails ? false : true
                        }
                        onClick={handleGetEstimate}
                        style={{ height: bookingType === 'Book Later' ? 76 : 52, marginLeft: 5 }}
                      >
                        <LocalTaxiIcon fontSize='small' />
                        {language.book_now}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <div>
              <Modal
                open={FormOpen}
                className={classes.Modal}
                onClose={closeForm}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {body()}
              </Modal>
            </div>
          </Grid>
          :
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <br />
                <h1 className={classes.title}>{language.landing_slogan}</h1>
              </GridItem>
            </GridContainer>
          </div>
        }
      </div>
      {/* middle part */}
      <div style={{ backgroundColor: '#ffffff' }}>
        <div className={classes.middleStyle}>
          <Typography className={classes.typoStyle1}>
            Meet our comfortable cars
          </Typography>
          <Typography className={classes.typoStyle2}>
            The widest variety of cars to choose from
          </Typography>
          <Grid container
          >

            <Grid item xs={12} sm={6} lg={12} className={classes.buttonGrid}>
              {button_Data.map((item, index) => (
                <Button variant="contained" color="primary" key={index}
                  className={activeButton === index ? classes.button1 : classes.button}
                  onClick={() => slider(index)}
                >
                  {item.name}
                </Button>
              ))}
            </Grid>
          </Grid>
          <Grid className={classes.callcon2}>
            <Grid item xs={12} sm={1} style={{ alignSelf: 'center' }}>
              <IconButton
                size="small"
                onClick={goToNextPicture1}
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: 48 / 2,
                  color: '#ffffff',
                  backgroundColor: index !== 0 ? '#151846' : 'grey',
                  alignSelf: 'center',
                }}
                disabled={index === 0}
              >
                <KeyboardArrowLeftIcon
                  style={{
                    width: '48px',
                    height: '48px',
                    borderRadius: 48 / 2,
                    color: '#ffffff',
                    alignSelf: 'center'
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={10} style={{ alignSelf: 'center', justifyContent: 'center', display: 'flex' }}>
              <img
                src={images[index].url}
                className={classes.book_cab1}
                alt="car types"
              />
            </Grid>
            <Grid item xs={12} sm={1} style={{ alignSelf: 'center', justifyContent: 'flex-end', display: 'flex' }}>
              <IconButton
                size="small"
                onClick={goToNextPicture}
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: 48 / 2,
                  color: '#ffffff',
                  backgroundColor: index === CollectionSize - 1 ? 'grey' : '#151846',
                  alignSelf: 'center'
                }}
                disabled={index === CollectionSize - 1}
              >
                <KeyboardArrowRightIcon
                  style={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '24px',
                    color: '#ffffff',
                    alignSelf: 'center'
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classes.tabSection}>
            <Grid container spacing={1} >
              <Grid item xs className={classes.grid}>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: 20 }}>
                  <div>
                    <div className={classes.paper}>Micro</div>
                    <div className={classes.paper1}>Small fares for short rides</div>
                  </div>
                  <img className={classes.img} alt="complex" src={MicroSmall} />
                </div>
                <div style={{ margin: 20 }}>
                  <div className={classes.paper2}>Compact yet comfortable AC cars that seat up to 3 people and give you great value for your money. Small fares for short rides</div>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  <div>
                    <img src={Feature} className={classes.innerIcon} alt="car types" />
                    <img src={Rupee2} width="20" height="36" className={classes.innerIcon} alt="car types" />
                    <img src={User1} className={classes.innerIcon} alt="car types" />
                  </div>
                </div>
              </Grid>
              <Grid item xs className={classes.grid}>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: 20 }}>
                  <div>
                    <div className={classes.paper}>Mini</div>
                    <div className={classes.paper1}>Everyday dependable rides</div>
                  </div>
                  <img className={classes.img} alt="complex" src={MiniSmall} />
                </div>
                <div style={{ margin: 20 }}>
                  <div className={classes.paper2}>A regular comfortable AC hatchback that becomes your everyday dependable ride. An economical option for daily commute.
                  </div>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  <div>
                    <img src={featureHide} className={classes.innerIcon} alt="car types" />
                    <img src={Rupee} width="20" height="36" className={classes.innerIcon} alt="car types" />
                    <img src={User1} className={classes.innerIcon} alt="car types" />
                  </div>
                </div>
              </Grid>
              <Grid item xs className={classes.grid}>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: 20 }}>
                  <div>
                    <div className={classes.paper}>Prime Sedan</div>
                    <div className={classes.paper1}>Available Free Wi-Fi</div>
                  </div>
                  <img className={classes.img} alt="complex" src={PrimesedanSmall} />
                </div>
                <div style={{ margin: 20 }}>
                  <div className={classes.paper2}>Top rated drivers, and a hand-picked fleet of the best cars with extra legroom and boot space.
                  </div>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  <div>
                    <img src={featureHide} className={classes.innerIcon} alt="car types" />
                    <img src={Rupee2} width="20" height="36" className={classes.innerIcon} alt="car types" />
                    <img src={User1} className={classes.innerIcon} alt="car types" />
                    <img src={WifiActive} className={classes.innerIcon} alt="car types" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      {/* next part */}
      <div style={{ backgroundColor: '#ffffff' }}>
        <div className={classes.bgimageStyle}>
          <div className={classes.callcon1}>
            <Typography className={classes.whiteText}>
              We do Best than you Wish
            </Typography>
            <Typography className={classes.whiteSmall}>
              We created our taxi to help you to find the most dependable and
            </Typography>
            <Typography className={classes.whiteSmall}>
              highest quality taxi services, anytime and anywhere.
            </Typography>
          </div>
          <Grid container spacing={10} className={classes.callcon}>
            <Grid item xs={12} sm={6} className={classes.split}>
              <div style={{
                width: '113px',
                height: '111px',
                borderRadius: '22px',
                backgroundColor: '#ffffff',
                justifyContent: 'center', display: 'flex'
              }}>
                <img src={Card1} style={{
                  alignSelf: 'center', padding: 30
                }} alt="car types" />
              </div>
              <div>
                <div className={classes.address}>
                  Address Pickup
                </div>
                <div className={classes.whiteSmall1}>
                  We always pick up our clients on time, 24/7 availability.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.split}>
              <div style={{
                width: '113px',
                height: '111px',
                borderRadius: '22px',
                backgroundColor: '#ffffff',
                justifyContent: 'center', display: 'flex'
              }}>
                <img src={Card2} style={{
                  alignSelf: 'center', padding: 30
                }} alt="car types" />
              </div>
              <div>
                <div className={classes.address}>
                  Fast Booking
                </div>
                <div className={classes.whiteSmall1}>
                  Our customer can booking
                  fastly us through web or mobile
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.split}>
              <div style={{
                width: '113px',
                height: '111px',
                borderRadius: '22px',
                backgroundColor: '#ffffff',
                justifyContent: 'center', display: 'flex'
              }}>
                <img src={Card3} style={{
                  alignSelf: 'center', padding: 30
                }} alt="car types" />
              </div>
              <div>
                <div className={classes.address}>
                  Bonus for Rides
                </div>
                <div className={classes.whiteSmall1}>
                  We provides to our customers
                  for their first rides bonus
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.split}>
              <div style={{
                width: '113px',
                height: '111px',
                borderRadius: '22px',
                backgroundColor: '#ffffff',
                justifyContent: 'center', display: 'flex'
              }}>
                <img src={Card4} style={{
                  alignSelf: 'center', padding: 30
                }} alt="car types" />
              </div>
              <div>
                <div className={classes.address}>
                  GPS Searching
                </div>
                <div className={classes.whiteSmall1}>
                  whereever we offers the rides
                  customer can search through
                  GPS
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>

      {/* white part */}
      <div style={{ backgroundColor: '#ffffff' }}>
        <Grid container className={classes.callcon1}>
          <Grid item xs={12} sm={6} lg={6} style={{ marginTop: 150 }}>
            <Typography className={classes.orderTaxi}>
              Download the <font color="blue">ZEBU</font> App
              and Order Your Taxi Online
            </Typography>
            <Typography className={classes.orderTaxi1}>
              Download the app for exclusive deals and ease of booking
            </Typography>
            <Typography className={classes.darkText}>
              Download our app and enjoy the best service from ZEBU. All you have to do to begin using it is to enter your name and mobile phone number.
            </Typography>
            <Grid container style={{ marginTop: 50 }}>
              <Grid item xs={12} sm={4} style={{ marginRight: 10 }}>
                <img src={Googleplay}
                  className={classes.book_cab1}
                  alt="car types" />
              </Grid>
              <Grid item xs={12} sm={4} style={{ marginTop: 3.5 }}>
                <img src={Appstore}
                  className={classes.book_cab1}
                  alt="car types" />
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={6} lg={5} style={{ marginTop: 50 }}>
            <img src={Map}
              className={classes.book_cab2}
              alt="car types" />
          </Grid>
        </Grid>
      </div>

      {/* footer part */}
      <div className={classes.footerImageStyle}>
        <Grid container className={classes.con} spacing={3}>
          <Grid item xs={12} sm={6} style={{ marginTop: 80 }}>
            <img src={footerLogo} alt="car types" />
            <h4 className={classes.bookTaxi}>Call for booking Taxi</h4>
            <h3 className={classes.numberStyle}>+91 7708806434</h3>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 80 }}>
            <h4 className={classes.quickStyle}>Quick Links</h4>
            <List style={{ marginTop: 40 }}>
              <ListItem className={classes.listStyle}>About ZEBU</ListItem>
              <ListItem className={classes.listStyle}>Careers</ListItem>
              <ListItem className={classes.listStyle}>Offers</ListItem>
              <ListItem className={classes.listStyle}>Contact us</ListItem>
              <ListItem className={classes.listStyle}>Become a Driver</ListItem>
              <ListItem className={classes.listStyle}>Book a Ride</ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 80 }}>
            <h4 className={classes.quickStyle}>Inter City</h4>
            <List style={{ marginTop: 40 }}>
              <ListItem className={classes.listStyle}>MGR Bus Station</ListItem>
              <ListItem className={classes.listStyle}>Railway Station</ListItem>
              <ListItem className={classes.listStyle}>Arapalayam</ListItem>
              <ListItem className={classes.listStyle}>Periyar Nilayam</ListItem>
              <ListItem className={classes.listStyle}>Meenakshi Amman Temple</ListItem>
              <ListItem className={classes.listStyle}>Alagar Kovil</ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: 80 }}>
            <h4 className={classes.quickStyle}>Outstation</h4>
            <List style={{ marginTop: 40 }}>
              <ListItem className={classes.listStyle}>Chennai</ListItem>
              <ListItem className={classes.listStyle}>Coimbatore</ListItem>
              <ListItem className={classes.listStyle}>Tiruchy</ListItem>
              <ListItem className={classes.listStyle}>Tirunelveli</ListItem>
              <ListItem className={classes.listStyle}>Nagerkovil</ListItem>
              <ListItem className={classes.listStyle}>Kanyakumari</ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
      <Grid className={classes.gridContainer} container >
        <Grid container className={classes.con}>
          <Grid item xs={12} sm={4}>
            <ListItem className={classes.customerStyle}>24/7 Customer Support</ListItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ListItem className={classes.supportStyle}>Your Safety First</ListItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ListItem className={classes.ratedStyle}>Top Rated Driver-Partners</ListItem>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.estimate}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {settings ? settings.symbol : null} {carTypeObject && carTypeObject.length > 0 ? carType === 'Mini' ? carTypeObject[0].estimateFare : carType === 'Prime Sedan' ? carTypeObject[1].estimateFare : carTypeObject[0].estimateFare + ' - ' + carTypeObject[1].estimateFare : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.cancel}
          </Button>
          {/* <Button onClick={confirmBooking} color="primary" autoFocus>
            {language.book}
          </Button> */}
        </DialogActions>
      </Dialog>
      <AlertDialog open={bookingdata.booking ? true : false} onClose={handleBookingAlertClose}>{bookingdata.booking ? language.booking_success + bookingdata.booking.booking_id : null}</AlertDialog>
      <AlertDialog open={bookingdata.error.flag} onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
      <AlertDialog open={estimatedata.error.flag} onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}