import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import {
  Tab,
  AppBar,
  Tabs,
  makeStyles,
  Box
} from '@material-ui/core';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {
  features,
  dateStyle,
  language
} from 'config';
import { FirebaseContext } from 'common';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    backgroundColor: 'red'
  },
  inkBar: {
    backgroundColor: 'yellow'
  }
}));

const BookingHistory = () => {
  const { api } = useContext(FirebaseContext);
  const {
    cancelBooking,
    editBooking
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [role, setRole] = useState(null);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [drivers, setDrivers] = useState({});
  const usersdata = useSelector(state => state.usersdata);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTime = (estimateTime) => {
    let hour = estimateTime / 3600;
    var statement = "";
    if (hour > 1) {
      statement = parseFloat(hour).toFixed(2) + "h"
    } else {
      var min = parseFloat(hour).toFixed(2);
      var minarray = min.split('.');
      statement = minarray[1] + "m";
    }
    return statement;
  }

  useEffect(() => {
    if (usersdata.users) {
      let obj = {};
      const drv = usersdata.users.filter(user => user.usertype === 'driver');
      drv.map((driver) => {
        let name = driver.firstName + " " + driver.lastName;
        obj[name] = name;
        return name;
      });
      setDrivers(obj);
    }
  }, [usersdata.users]);

  const bookingstatus = {
    NEW: 'NEW',
    ACCEPTED: 'ACCEPTED',
    ARRIVED: 'ARRIVED',
    STARTED: 'STARTED',
    REACHED: 'REACHED',
    PENDING: 'PENDING',
    PAID: 'PAID',
    COMPLETE: 'COMPLETE',
    CANCELLED: 'CANCELLED'
  }


  const columns = [
    { title: language.booking_id, field: 'id', editable: 'never', },
    { title: language.booking_date, field: 'tripdate', editable: 'never', render: rowData => rowData.tripdate ? new Date(rowData.tripdate).toLocaleString(dateStyle) : null },
    { title: language.car_type, field: 'carType', editable: 'never' },
    { title: language.customer_name, field: 'customer_name', editable: 'never' },
    { title: language.pickup_address, field: 'pickupAddress', editable: 'never' },
    { title: language.drop_address, field: 'dropAddress', editable: 'never' },
    { title: language.assign_driver, field: 'driver_name', lookup: drivers },
    { title: language.booking_status, field: 'status', lookup: bookingstatus, render: rowData => rowData.status },
    { title: language.cancellation_reason, field: 'reason', editable: 'never' },
    { title: language.otp, field: 'otp', editable: 'never', render: rowData => rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? <span>{rowData.otp}</span> : null },
    { title: language.book_type, field: 'book_type', editable: 'never' },
    { title: language.no_passengers, field: 'passenger_count', editable: 'never' },
    { title: language.trip_cost, field: 'trip_cost', type: 'numeric', render: rowData => rowData.status === 'PAID' || rowData.status === 'COMPLETE' ? "₹" + rowData.trip_cost : "₹" + rowData.estimate },
    { title: language.trip_start_time, field: 'trip_start_time', type: "datetime" },
    { title: language.trip_end_time, field: 'trip_end_time', type: "datetime" },
    { title: language.total_time, field: 'total_trip_time', type: 'numeric', render: rowData => rowData.status === 'PAID' || rowData.status === 'COMPLETE' ? getTime(rowData.total_trip_time) : getTime(rowData.estimateTime) },
    { title: language.distance, field: 'distance', type: 'numeric', render: rowData => rowData.status === 'PAID' || rowData.status === 'COMPLETE' ? rowData.distance + "km" : rowData.estimateDistance + "km" },
    { title: language.vehicle_no, field: 'vehicle_number' },
    { title: language.trip_cost_driver_share, field: 'driver_share' },
    { title: language.convenience_fee, field: 'convenience_fees' },
    { title: language.discount_ammount, field: 'discount' },
    { title: language.Customer_paid, field: 'customer_paid' },
    { title: language.payment_mode, field: 'payment_mode', editable: 'never' },
    { title: language.payment_gateway, field: 'gateway', editable: 'never' },
    { title: language.cash_payment_amount, field: 'cashPaymentAmount' },
    { title: language.card_payment_amount, field: 'cardPaymentAmount' },
    { title: language.wallet_payment_amount, field: 'usedWalletMoney' },
    { title: language.comments, field: 'comments' }
  ];
  const [data, setData] = useState([]);
  const [run, setRun] = useState([]);
  const [tomorrow, setTomorrow] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState('');
  const bookinglistdata = useSelector(state => state.bookinglistdata);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      const upcoming = bookinglistdata.bookings.filter(item => {
        var dateOne = new Date();
        var datetwo = new Date(item.tripdate);
        return datetwo.getTime() > dateOne.getTime();
      });
      setData(upcoming);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      const run = bookinglistdata.bookings.filter(item => {
        return item.status.indexOf('STARTED') !== -1;
      });
      setRun(run);
    } else {
      setRun([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      const tomorrow = bookinglistdata.bookings.filter(item => {
        var nextDate = new Date();
        nextDate.setDate(new Date().getDate() + 1);
        var datetwo = new Date(item.tripdate);
        return nextDate.getDate() === datetwo.getDate();
      });
      setTomorrow(tomorrow);
    } else {
      setTomorrow([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(cancelBooking({
        reason: value,
        booking: selectedBooking
      }));
    }
    setOpenConfirm(false);
  }

  return (
    bookinglistdata.loading ? <CircularLoading /> :
      <div className={classes.root}>
        <AppBar position="static" style={{ background: 'white' }}>
          <Tabs value={value} onChange={handleChange} indicatorColor={'primary'} textColor={'primary'}>
            <Tab label="Upcoming Booking" {...a11yProps(0)} />
            <Tab label="Run" {...a11yProps(1)} />
            <Tab label="Tomorrow" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MaterialTable
            title={""}
            columns={columns}
            data={data}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 10
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    if (newData.distance) {
                      newData.estimateDistance = newData.distance;
                    }
                    if (newData.trip_cost) {
                      newData.estimate = newData.trip_cost;
                    }
                    if (newData.total_trip_time) {
                      newData.estimateTime = newData.total_trip_time;
                    }
                    resolve();
                    dispatch(editBooking(oldData, newData));
                  });
                }),
            }}
            actions={[
              rowData => ({
                icon: 'cancel',
                tooltip: language.cancel_booking,
                disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? false : true,
                onClick: (event, rowData) => {
                  if (features.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
                    setSelectedBooking(rowData);
                    setOpenConfirm(true);
                  } else {
                    alert(language.demo_mode);
                  }
                }
              }),
            ]}
          />

        </TabPanel>
        <TabPanel value={value} index={1}>
          <MaterialTable
            title={""}
            columns={columns}
            data={run}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 10
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    if (newData.distance) {
                      newData.estimateDistance = newData.distance;
                    }
                    if (newData.trip_cost) {
                      newData.estimate = newData.trip_cost;
                    }
                    resolve();
                    dispatch(editBooking(oldData, newData));
                  });
                }),
            }}
            actions={[
              rowData => ({
                icon: 'cancel',
                tooltip: language.cancel_booking,
                disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? false : true,
                onClick: (event, rowData) => {
                  if (features.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
                    setSelectedBooking(rowData);
                    setOpenConfirm(true);
                  } else {
                    alert(language.demo_mode);
                  }
                }
              }),
            ]}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MaterialTable
            title={""}
            columns={columns}
            data={tomorrow}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              pageSize: 10
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    if (newData.distance) {
                      newData.estimateDistance = newData.distance;
                    }
                    if (newData.trip_cost) {
                      newData.estimate = newData.trip_cost;
                    }
                    resolve();
                    dispatch(editBooking(oldData, newData));
                  });
                }),
            }}
            actions={[
              rowData => ({
                icon: 'cancel',
                tooltip: language.cancel_booking,
                disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? false : true,
                onClick: (event, rowData) => {
                  if (features.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
                    setSelectedBooking(rowData);
                    setOpenConfirm(true);
                  } else {
                    alert(language.demo_mode);
                  }
                }
              }),
            ]}
          />
        </TabPanel>
        <ConfirmationDialogRaw
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />
      </div>
  );
}

export default BookingHistory;
