import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import HeaderLogo from "components/Header/HeaderLogo.js";
import Footer from "components/Footer/Footer.js";
import HeaderTransparent from "components/Header/HeaderTransparent.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { language } from 'config';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Terms(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <HeaderLogo
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderTransparent />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
            <h2 className={classes.title}>{language.terms_and_conditions}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para1}</p>
            <p className={classes.description}>{language.terms_and_conditions_para2}</p>
            <p className={classes.description}>{language.terms_and_conditions_para3}</p>
            <p className={classes.description}>{language.terms_and_conditions_para4}</p>
            <p className={classes.description}>{language.terms_and_conditions_para5}</p>
            <p className={classes.description}>{language.terms_and_conditions_para6}</p>
            <h2 className={classes.title}>{language.terms_definitions}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para7}</p>
            <p className={classes.description}>{language.terms_and_conditions_para8}</p>
            <p className={classes.description}>{language.terms_and_conditions_para9}</p>
            <p className={classes.description}>{language.terms_and_conditions_para10}</p>
            <p className={classes.description}>{language.terms_and_conditions_para11}</p>
            <p className={classes.description}>{language.terms_and_conditions_para12}</p>
            <p className={classes.description}>{language.terms_and_conditions_para13}</p>
            <p className={classes.description}>{language.terms_and_conditions_para14}</p>
            <p className={classes.description}>{language.terms_and_conditions_para15}</p>
            <p className={classes.description}>{language.terms_and_conditions_para16}</p>
            <p className={classes.description}>{language.terms_and_conditions_para17}</p>
            <p className={classes.description}>{language.terms_and_conditions_para18}</p>
            <p className={classes.description}>{language.terms_and_conditions_para19}</p>
            <p className={classes.description}>{language.terms_and_conditions_para20}</p>
            <p className={classes.description}>{language.terms_and_conditions_para21}</p>
            <p className={classes.description}>{language.terms_and_conditions_para22}</p>
            <p className={classes.description}>{language.terms_and_conditions_para23}</p>
            <p className={classes.description}>{language.terms_and_conditions_para24}</p>
            <p className={classes.description}>{language.terms_and_conditions_para25}</p>
            <h2 className={classes.title}>{language.terms_eligibility}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para26}</p>
            <p className={classes.description}>{language.terms_and_conditions_para27}</p>
            <h2 className={classes.title}>{language.terms_account}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para28}</p>
            <p className={classes.description}>{language.terms_and_conditions_para29}</p>
            <p className={classes.description}>{language.terms_and_conditions_para30}</p>
            <p className={classes.description}>{language.terms_and_conditions_para31}</p>
            <p className={classes.description}>{language.terms_and_conditions_para32}</p>
            <p className={classes.description}>{language.terms_and_conditions_para33}</p>
            <p className={classes.description}>{language.terms_and_conditions_para34}</p>
            <p className={classes.description}>{language.terms_and_conditions_para35}</p>
            <h2 className={classes.title}>{language.terms_services}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para36}</p>
            <p className={classes.description}>{language.terms_and_conditions_para37}</p>
            <p className={classes.description}>{language.terms_and_conditions_para38}</p>
            <p className={classes.description}>{language.terms_and_conditions_para39}</p>
            <p className={classes.description}>{language.terms_and_conditions_para40}</p>
            <p className={classes.description}>{language.terms_and_conditions_para41}</p>
            <p className={classes.description}>{language.terms_and_conditions_para42}</p>
            <p className={classes.description}>{language.terms_and_conditions_para43}</p>
            <p className={classes.description}>{language.terms_and_conditions_para44}</p>
            <p className={classes.description}>{language.terms_and_conditions_para45}</p>
            <p className={classes.description}>{language.terms_and_conditions_para46}</p>
            <p className={classes.description}>{language.terms_and_conditions_para47}</p>
            <h2 className={classes.title}>{language.terms_booking}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para48}</p>
            <p className={classes.description}>{language.terms_and_conditions_para49}</p>
            <p className={classes.description}>{language.terms_and_conditions_para50}</p>
            <h2 className={classes.title}>{language.terms_cancel}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para51}</p>
            <p className={classes.description}>{language.terms_and_conditions_para52}</p>
            <p className={classes.description}>{language.terms_and_conditions_para53}</p>
            <p className={classes.description}>{language.terms_and_conditions_para54}</p>
            <p className={classes.description}>{language.terms_and_conditions_para55}</p>
            <p className={classes.description}>{language.terms_and_conditions_para56}</p>
            <p className={classes.description}>{language.terms_and_conditions_para57}</p>
            <p className={classes.description}>{language.terms_and_conditions_para58}</p>
            <p className={classes.description}>{language.terms_and_conditions_para59}</p>
            <h2 className={classes.title}>{language.terms_violation}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para60}</p>
            <p className={classes.description}>{language.terms_and_conditions_para61}</p>
            <h2 className={classes.title}>{language.terms_customer}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para62}</p>
            <p className={classes.description}>{language.terms_and_conditions_para63}</p>
            <p className={classes.description}>{language.terms_and_conditions_para64}</p>
            <p className={classes.description}>{language.terms_and_conditions_para65}</p>
            <h2 className={classes.title}>{language.terms_emergency}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para66}</p>
            <p className={classes.description}>{language.terms_and_conditions_para67}</p>
            <p className={classes.description}>{language.terms_and_conditions_para68}</p>
            <p className={classes.description}>{language.terms_and_conditions_para69}</p>
            <p className={classes.description}>{language.terms_and_conditions_para70}</p>
            <p className={classes.description}>{language.terms_and_conditions_para71}</p>
            <p className={classes.description}>{language.terms_and_conditions_para72}</p>
            <h2 className={classes.title}>{language.terms_indemnification}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para73}</p>
            <h2 className={classes.title}>{language.terms_liability}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para74}</p>
            <p className={classes.description}>{language.terms_and_conditions_para75}</p>
            <p className={classes.description}>{language.terms_and_conditions_para76}</p>
            <p className={classes.description}>{language.terms_and_conditions_para77}</p>
            <p className={classes.description}>{language.terms_and_conditions_para78}</p>
            <p className={classes.description}>{language.terms_and_conditions_para79}</p>
            <p className={classes.description}>{language.terms_and_conditions_para80}</p>
            <p className={classes.description}>{language.terms_and_conditions_para81}</p>
            <h2 className={classes.title}>{language.terms_links}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para82}</p>
            <h2 className={classes.title}>{language.terms_license}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para83}</p>
            <p className={classes.description}>{language.terms_and_conditions_para84}</p>
            <p className={classes.description}>{language.terms_and_conditions_para85}</p>
            <p className={classes.description}>{language.terms_and_conditions_para86}</p>
            <h2 className={classes.title}>{language.terms_invalidity}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para87}</p>
            <h2 className={classes.title}>{language.terms_conflict}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para88}</p>
            <h2 className={classes.title}>{language.terms_disclaimer}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para89}</p>
            <p className={classes.description}>{language.terms_and_conditions_para90}</p>
            <p className={classes.description}>{language.terms_and_conditions_para91}</p>
            <p className={classes.description}>{language.terms_and_conditions_para92}</p>
            <p className={classes.description}>{language.terms_and_conditions_para93}</p>
            <p className={classes.description}>{language.terms_and_conditions_para94}</p>
            <h2 className={classes.title}>{language.terms_modification}</h2>
            <p className={classes.description}>{language.terms_and_conditions_para95}</p>
            <p className={classes.description}>{language.terms_and_conditions_para96}</p>
            <br/>
        </div>
        </div>

      <Footer />
    </div>
  );
}
