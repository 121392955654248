import React, { useState, useEffect, useContext, useCallback } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
  features,
  language
} from 'config';
import { FirebaseContext } from 'common';

export default function CarTypes() {
  const { api, carTypeDocsRef } = useContext(FirebaseContext);
  const {
    editCarType,
    addCarType,
    deleteCarType
  } = api;

  const [selectedImage, setSelectedImage] = useState([]);
  const [unSelectedImage, setUnSelectedImage] = useState([]);


  const editComponent1 = useCallback(() => {
    return <input type="file" onChange={onSelectedImage} />;
  }, [])

  const editComponent2 = useCallback(() => {
    return <input type="file" onChange={onUnSelectedImage} />;
  }, [])

  const preventRerender1 = useCallback(
    (rowData) => rowData.selectedImage ? <img alt='profile' src={rowData.selectedImage} style={{ width: 100, height: 100 }} /> : null,
    []
  );
  const preventRerender2 = useCallback(
    (rowData) => rowData.unSelectedImage ? <img alt='profile' src={rowData.unSelectedImage} style={{ width: 100, height: 100 }} /> : null,
    []
  );
  const onSelectedImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setSelectedImage(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };
  const onUnSelectedImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      var blob = '';
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async function (e) {
        blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            alert(language.alert, language.image_upload_error);
          };
          xhr.responseType = 'blob';
          xhr.open('GET', e.target.result, true);
          xhr.send(null);
        });
        setUnSelectedImage(blob);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };
  const columns = [
    { title: language.selectedImage, field: 'selectedImage', editComponent: editComponent1, render: preventRerender1 },
    { title: language.unselectedImage, field: 'unSelectedImage', editComponent: editComponent2, render: preventRerender2 },
    { title: language.name, field: 'name' },
    { title: language.base_fare, field: 'base_fare', type: 'numeric' },
    { title: language.rate_per_unit_distance, field: 'rate_per_unit_distance', type: 'numeric' },
    { title: language.rate_per_hour, field: 'rate_per_hour', type: 'numeric' },
    { title: language.min_fare, field: 'min_fare', type: 'numeric' },
    { title: language.convenience_fee, field: 'convenience_fees', type: 'numeric' },
    { title: "Max Commision limit", field: 'max_commission_limit', type: 'numeric' },
    { title: "Min Commision limit", field: 'min_commission_limit', type: 'numeric' },
    {
      title: language.convenience_fee_type,
      field: 'convenience_fee_type',
      lookup: { flat: language.flat, percentage: language.percentage },
    },
    { title: language.extra_info, field: 'extra_info' }
  ];
  const [data, setData] = useState([]);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars);
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  return (
    cartypes.loading ? <CircularLoading /> :
      <MaterialTable
        title={language.car_type}
        columns={columns}
        data={data}
        options={{
          exportButton: true
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              if (features.AllowCriticalEditsAdmin) {
                setTimeout(() => {
                  resolve();
                  newData.selectedImage = selectedImage;
                  newData.unSelectedImage = unSelectedImage;
                  dispatch(addCarType(newData));
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              if (features.AllowCriticalEditsAdmin) {
                setTimeout(() => {
                  resolve();
                  if (selectedImage instanceof Blob) {
                    newData.selectedImage = selectedImage;
                  }
                  if (unSelectedImage instanceof Blob) {
                    newData.unSelectedImage = unSelectedImage;
                  }
                  dispatch(editCarType(oldData, newData));
                  // dispatch(editCarType(tblData), "Update");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              if (features.AllowCriticalEditsAdmin) {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteCarType(oldData.id));
                  // const tblData = data;
                  // tblData.splice(tblData.indexOf(oldData), 1);
                  // dispatch(editCarType(tblData), "Delete");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
        }}
      />
  );
}