/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerTransparentStyle.js";
import { useSelector } from "react-redux";
import { language } from "config";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);

export default function HeaderTransparent(props) {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.info]);

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push('/') }}
        >
          {language.home}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push('/bookings') }}
        >
          {loggedIn ?
            language.myaccount
            :
            language.login_signup
          }
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push('/about-us') }}
        >
          {language.about_us}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="white"
          className={classes.button}
        >
          {language.book_now}
        </Button>
      </ListItem>
    </List>
  );
}
