import {
  Circle,
  InfoWindow,
  Marker
} from "react-google-maps";
import React, { useRef, useState } from 'react';

import { Image } from 'react-native';
import caricons from '../assets/img/caricons.png';

class MarkerComponent extends React.Component {
  state = {
    progress: [],
    text: false
  };

  path = this.props.locations;

  velocity = 100;

  initialDate = new Date();

  carRef = React.createRef([]);

  getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - this.initialDate) / 1000; // pass to seconds
    return differentInTime * this.velocity; // d = v*t -- thanks Newton!
  };

  componentDidMount = () => {
    this.interval = window.setInterval(this.moveObject, 1000);

    setTimeout(() => window.clearInterval(this.interval), 2000)
  };

  // componentWillUnmount = () => {
  //   window.clearInterval(this.interval);
  // };

  moveObject = () => {
    const distance = this.getDistance();
    if (!distance) {
      return;
    }

    let progress = this.path.filter(
      coordinates => coordinates.distance < distance
    );

    const nextLine = this.path.find(
      coordinates => coordinates.distance > distance
    );

    if (!nextLine) {
      this.setState({ progress });
      return; // it's the end!
    }

    const lastLine = progress[progress.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    progress = progress.concat(position);

    this.setState({ progress });
  };

  componentWillMount = async () => {

    this.path = this.path.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      );

      return { ...coordinates, distance };
    });

    this.moveObject();
  };

  componentDidUpdate = async () => {

    const distance = this.getDistance();
    if (!distance) {
      return;
    }

    let progress = this.path.filter(
      coordinates => coordinates.distance < distance
    );

    const nextLine = this.path.find(
      coordinates => coordinates.distance > distance
    );

    let point1, point2;

    if (nextLine) {
      point1 = progress[progress.length - 1];
      point2 = nextLine;
    } else {
      // it's the end, so use the latest 2
      point1 = progress[progress.length - 2];
      point2 = progress[progress.length - 1];
    }

    const point1LatLng = new window.google.maps.LatLng(point1?.lat, point1?.lng);
    const point2LatLng = new window.google.maps.LatLng(point2?.lat, point2?.lng);

    const angle = window.google.maps.geometry.spherical.computeHeading(
      point1LatLng,
      point2LatLng
    );
    const actualAngle = angle - 90;

    const markerUrl =
      "https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png";
    const marker = document.querySelector(`[src="${markerUrl}"]`);

    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${actualAngle}deg)`;
    }

  };

  render = () => {
    const icon = {
      url:
        "https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png",
      scaledSize: new window.google.maps.Size(20, 20),
      anchor: { x: 10, y: 10 }
    };
    const icon1 = {
      url: caricons,
      scaledSize: new window.google.maps.Size(40, 40),
      anchor: { x: 10, y: 10 }
    };
    if(this.props.marker.id === 14){
      console.log(this.state.progress)
    }
    // console.log('1')
    return (
      <>
        {this.state.progress && (
          <>
            {this.props.marker.drivername ?
              <Marker
                // ref={(ref) => {
                //   this.carRef[this.props.marker.id] = ref
                // }}
                icon={icon}
                position={this.state.progress[0]}
              >
                <InfoWindow>
                  <div>{this.props.marker.drivername}</div>
                </InfoWindow>
              </Marker>
              :
              <Marker
                icon={icon1}
                position={this.state.progress[this.state.progress.length - 1]}
                onClick={() => this.setState({ text: true })}
              >
                {this.props.marker.name && this.state.text ?
                  <InfoWindow onCloseClick={() => this.setState({ text: false })}>
                    <div>
                      <div style={{
                        backgroundColor: '#ccc6c6',
                        height: 20,
                        width: 20,
                        borderRadius: 20 / 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: '700',
                        color: '#000000',
                        marginTop: 5
                      }}>{this.props.marker.count}</div>
                      <div style={{ marginTop: 5, color: '#000000', fontSize: 14 }}>Driver Name : {this.props.marker.name}</div>
                      <div style={{ marginTop: 5, color: '#000000', fontSize: 14 }}>Driver Id : {this.props.marker.id}</div>
                      {this.props.marker.startDate ?
                        <div style={{ marginTop: 5, color: '#000000', fontSize: 14 }}>Start Time : {new Date(this.props.marker.startDate).toLocaleString()}</div>
                        : null}
                      <div style={{ marginTop: 5, color: '#000000', fontSize: 14 }}>Status : {this.props.marker.status === '' ? '-' : this.props.marker.status === 2 ? 'IDLE' : this.props.marker.status === 0 ? 'CANCELLED' : 'ACCEPTED'}</div>
                    </div>
                  </InfoWindow>
                  : null}
              </Marker>
            }
          </>
        )
        }
      </>
    );
  };
}

export default MarkerComponent;
