import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {
  features,
  dateStyle,
  language,
  Google_Map_Key
} from 'config';
import { FirebaseContext } from 'common';
import {
  IconButton
} from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Map from '../components/Map';

const useStyles = makeStyles(theme => ({
  paper2: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-20px',
    right: '-20px',
    overflow: 'scroll',
    "@media (max-width: 480px)": {
      right: 0,
    },
  },
  paper: {
    width: '70%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 10,
    "@media (max-width: 480px)": {
      width: '25%',
    }
  },
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15
  }
}));

const BookingHistory = () => {
  const { api, bookingRequestRef, fullBookingRequestRef } = useContext(FirebaseContext);
  const {
    cancelBooking,
    editBooking
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const [role, setRole] = useState(null);
  const [drivers, setDrivers] = useState({});
  const [modal, setModal] = useState(false)
  const [mylocation, setMylocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [detail, setDetail] = useState(null);
  const usersdata = useSelector(state => state.usersdata);

  useEffect(() => {
    if (mylocation == null) {
      navigator.geolocation.getCurrentPosition(
        position => setMylocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }),
        err => console.log(err)
      );
    }
  }, [mylocation]);

  useEffect(() => {
    if (detail) {
      bookingRequestRef(detail.id).on("value", (snapshot) => {
        if (snapshot.val()) {
          let data = snapshot.val()
          const requestedDriverArray = Object.values(data)
          let locs = [];
          if (requestedDriverArray[0].bookingStatus !== 1) {
            for (let i = 0; i < requestedDriverArray.length; i++) {
              locs.push({
                lat: requestedDriverArray[i].coordinates.lat,
                lng: requestedDriverArray[i].coordinates.lng,
                name: requestedDriverArray[i].driverName,
                id: requestedDriverArray[i].driverId,
                count: i + 1,
                status: requestedDriverArray[i].bookingStatus,
                startDate: requestedDriverArray[i].startTime,
              })
            }
            setLocations(locs)
          } else {
            fullBookingRequestRef(detail.id).on("value", (snapshot) => {
              let data = snapshot.val()
              const requestedDriverArray = Object.values(data)
              let locs = [];
              for (let i = 0; i < requestedDriverArray.length; i++) {
                console.log(requestedDriverArray[i]);
                locs.push({
                  lat: requestedDriverArray[i].coordinates.lat,
                  lng: requestedDriverArray[i].coordinates.lng,
                  name: requestedDriverArray[i].driverName,
                  id: requestedDriverArray[i].driverId,
                  count: i + 1,
                  status: requestedDriverArray[i].bookingStatus,
                  startDate: requestedDriverArray[i].startTime,
                })
              }
              setLocations(locs)
            })
          }
        }
      })
    }
  }, [detail]);



  const getTime = (estimateTime) => {
    let hour = estimateTime / 3600;
    var statement = "";
    if (hour > 1) {
      statement = parseFloat(hour).toFixed(2) + "h"
    } else {
      var min = parseFloat(hour).toFixed(2);
      var minarray = min.split('.');
      statement = minarray[1] + "m";
    }
    return statement;
  }

  const getRentalTime = (estimateTime) => {
    return parseFloat(estimateTime) + "h";
  }

  const bookingstatus = {
    NEW: 'NEW',
    ACCEPTED: 'ACCEPTED',
    ARRIVED: 'ARRIVED',
    STARTED: 'STARTED',
    REACHED: 'REACHED',
    PENDING: 'PENDING',
    PAID: 'PAID',
    COMPLETE: 'COMPLETE',
    CANCELLED: 'CANCELLED'
  }

  const setMapDate = (item) => {
    setModal(true)
    setDetail(item)
  }

  useEffect(() => {
    if (usersdata.users) {
      let obj = {};
      const drv = usersdata.users.filter(user => user.usertype === 'driver');
      drv.map((driver) => {
        let name = driver.firstName + " " + driver.lastName;
        obj[name] = name;
        return name;
      });
      setDrivers(obj);
    }
  }, [usersdata.users]);

  const columns = [
    { title: language.booking_id, field: 'id', editable: 'never', },
    { title: language.booking_date, field: 'tripdate', editable: 'never', render: rowData => rowData.tripdate ? new Date(rowData.tripdate).toLocaleString(dateStyle) : null },
    { title: language.car_type, field: 'carType', editable: 'never', render: rowData => rowData.carTypeName.toLowerCase() === 'mini' && rowData.carType !== rowData.carTypeName.toLowerCase() ? 'Mini' : rowData.carType },
    { title: language.customer_name, field: 'customer_name', editable: 'never' },
    { title: language.pickup_address, field: 'pickupAddress', editable: 'never' },
    { title: language.drop_address, field: 'dropAddress', editable: 'never' },
    { title: language.assign_driver, field: 'driver_name', lookup: drivers },
    { title: language.booking_status, field: 'status', lookup: bookingstatus },
    { title: language.cancellation_reason, field: 'reason', editable: 'never' },
    { title: 'Start Otp', field: 'startOtp', editable: 'never', render: rowData => rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? <span>{rowData.startOtp}</span> : null },
    { title: 'End Otp', field: 'endOtp', editable: 'never', render: rowData => rowData.status === 'REACHED' ? <span>{rowData.endOtp}</span> : null },
    { title: language.book_type, field: 'book_type', editable: 'never' },
    { title: language.no_passengers, field: 'passenger_count', editable: 'never' },
    { title: language.trip_cost, field: 'trip_cost', render: rowData => rowData.status === 'PAID' || rowData.status === 'COMPLETE' ? "₹" + rowData.trip_cost : "₹" + rowData.estimate },
    { title: language.trip_start_time, field: 'trip_start_time' },
    { title: language.trip_end_time, field: 'trip_end_time' },
    { title: language.total_time, field: 'total_trip_time', render: rowData => rowData.status === 'PAID' || rowData.status === 'COMPLETE' ? rowData.book_type === "rental" ? getRentalTime(rowData.estimateTime) : getTime(rowData.total_trip_time) : rowData.book_type === "rental" ? getRentalTime(rowData.estimateTime) : getTime(rowData.estimateTime) },
    { title: language.distance, field: 'distance', render: rowData => rowData.status === 'PAID' || rowData.status === 'COMPLETE' ? rowData.distance + "km" : rowData.estimateDistance + "km" },
    { title: language.vehicle_no, field: 'vehicle_number' },
    { title: language.trip_cost_driver_share, field: 'driver_share' },
    { title: language.convenience_fee, field: 'convenience_fees' },
    { title: language.discount_ammount, field: 'discount' },
    { title: language.Customer_paid, field: 'customer_paid' },
    // { title: language.payment_mode, field: 'payment_mode', editable: 'never' },
    // { title: language.payment_gateway, field: 'gateway', editable: 'never' },
    { title: language.cash_payment_amount, field: 'cashPaymentAmount' },
    { title: language.card_payment_amount, field: 'cardPaymentAmount' },
    // { title: language.wallet_payment_amount, field: 'usedWalletMoney' },
    { title: language.comments, field: 'comments' },
    {
      title: 'Driver View', field: 'driverView', render: rowData => <IconButton
        size="small"
        aria-label="Example"
        onClick={() => setMapDate(rowData)}
        style={{
          width: '25px',
          height: '25px',
          borderRadius: 25 / 2,
          backgroundColor: '#ffffff',
          alignSelf: 'center',
        }}
      >
        <RemoveRedEyeIcon
          style={{
            width: '25px',
            height: '25px',
            borderRadius: 25 / 2,
            color: '#000000',
            alignSelf: 'center'
          }}
        />
      </IconButton>
    }
  ];
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState('');
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const settings = useSelector(state => state.settingsdata.settings);


  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(cancelBooking({
        reason: value,
        booking: selectedBooking
      }));
    }
    setOpenConfirm(false);
  }

  function viewModal(item) {
    return (
      <div className={classes.paper}>
        <div className={classes.paper2}>
          <div style={{ height: 'auto', backgroundColor: '#ffffff', borderRadius: 10, padding: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: 16 }}>Driver Locations {locations.length === 0 ? ' : No Driver Found' : null}</div>

              <IconButton
                size="small"
                aria-label="Example"
                onClick={() => setModal(false)}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: 25 / 2,
                  backgroundColor: '#ffffff'
                }}
              >
                <CancelIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: 25 / 2,
                    color: '#000000',
                    alignSelf: 'flex-end',
                    display: 'flex'
                  }}
                />
              </IconButton>
            </div>
            <Map mapcenter={mylocation} locations={locations} detail={detail} radius={parseInt(settings.searchDriverDistance) * 1000}
              googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
              loadingElement={<div style={{ height: `750px` }} />}
              containerElement={<div style={{ height: `750px` }} />}
              mapElement={<div style={{ height: `750px` }} />}
            />
          </div>

        </div>
      </div>
    )
  }

  const viewOpenClose = () => {
    setModal(false)
  }

  return (
    bookinglistdata.loading ? <CircularLoading /> :
      <div>
        <MaterialTable
          title={language.booking_title}
          columns={columns}
          data={data}
          options={{
            sorting: true,
            actionsColumnIndex: -1,
            pageSize: 10
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  if (newData.status === "NEW" || newData.status === "ACCEPTED" || newData.status === "CANCELLED") {
                    if (newData.distance) {
                      newData.estimateDistance = newData.distance;
                    }
                    if (newData.trip_cost) {
                      newData.estimate = newData.trip_cost;
                    }
                  }
                  resolve();
                  dispatch(editBooking(oldData, newData));
                });
              }),
          }}
          // actions={[
          //   rowData => ({
          //     icon: 'delete',
          //     tooltip: 'Delete User',
          //     onClick: (event, rowData) => alert("You want to delete ")
          //   },
          //   {
          //     icon: 'cancel',
          //     tooltip: language.cancel_booking,
          //     disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? false : true,
          //     onClick: (event, rowData) => {
          //       if (features.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
          //         setSelectedBooking(rowData);
          //         setOpenConfirm(true);
          //       } else {
          //         alert(language.demo_mode);
          //       }
          //     }

          //   })
          // ]}

          actions={[
            rowData => ({
              icon: 'cancel',
              tooltip: language.cancel_booking,
              disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ? false : true,
              onClick: (event, rowData) => {
                if (features.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
                  setSelectedBooking(rowData);
                  setOpenConfirm(true);
                } else {
                  alert(language.demo_mode);
                }
              }
            }),
          ]}
        />
        < ConfirmationDialogRaw
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />
        <div>
          <Modal
            open={modal}
            className={classes.Modal}
            onClose={viewOpenClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {viewModal(detail)}
          </Modal>
        </div>
      </div>

  );
}

export default BookingHistory;
