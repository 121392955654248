export const MainConfig = {
    AppDetails: {
        app_name: "ZEBU DRIVER",
        app_description: "Driver",
        app_identifier: "com.appcode.taxidriver",
        ios_app_version: "1.0.0",
        android_app_version: 1
    },
    FirebaseConfig: {
        apiKey: "AIzaSyCB-pXVn_eeb7RBeJOs4utORAXRCjZh_hs",
        authDomain: "taxi-clone-7e483.firebaseapp.com",
        databaseURL: "https://taxi-clone-7e483-default-rtdb.firebaseio.com",
        projectId: "taxi-clone-7e483",
        storageBucket: "taxi-clone-7e483.appspot.com",
        messagingSenderId: "170712352196",
        appId: "1:170712352196:web:8254dcb10ce760d5d01091"
    },
    Google_Map_Key: "AIzaSyAsf-AfDErHlsVGFClrk4YUeKhL-fm5TOQ",
    facebookAppId: "1078239539416891",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "",
        Buyer_Email_Address: ""
    }

}