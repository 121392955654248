import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import assets from 'assets';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { language } from "config";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [secondLogo, setSecondLogo] = React.useState(false);
  const settings = useSelector(state => state.settingsdata.settings);
  React.useEffect(() => {

    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const trigger = useScrollTrigger();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
      setSecondLogo(true);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      setSecondLogo(false);
    }
  };
  const { color, rightLinks, leftLinks, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button
    href="/"
    className={classes.title}>
    {secondLogo ? <img src={assets.logo192x192_blue} alt="Logo" /> :
      <img src={assets.logo192x192_blue} alt="Logo" />
    }
  </Button>;
  return (
    <div>
      <AppBar className={appBarClasses} style={{ height: 116, width: '100%', marginTop: trigger ? 0 : 30 }}>
        {!trigger ? (
          <AppBar>
            <GridContainer className={classes.topColor}>
              <GridItem xs={12} className={classes.topMargin}>
                <PhoneEnabledIcon fontSize="small" className={classes.iconColor1} />
                <div className={classes.number}>+91 1234567890</div>
                {settings && settings.TwitterHandle ?
                  <Tooltip
                    id="instagram-twitter"
                    title={language.follow_twitter}
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      href={settings.TwitterHandle}
                      target="_blank"
                      color="primary"
                      className={classes.navLink}
                    >
                      <TwitterIcon fontSize="small" className={classes.iconColor} />
                    </Button>
                  </Tooltip>
                  : null}
                <LinkedInIcon fontSize="small" className={classes.iconColor} />

                {settings && settings.FacebookHandle ?
                  <Tooltip
                    id="instagram-facebook"
                    title={language.follow_facebook}
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="primary"
                      href={settings.FacebookHandle}
                      target="_blank"
                      className={classes.navLink}
                    >
                      <FacebookIcon fontSize="small" className={classes.iconColor} />
                    </Button>
                  </Tooltip>
                  : null}
                <PinterestIcon fontSize="small" className={classes.iconColor} />
              </GridItem>
            </GridContainer>
          </AppBar>
        ) : null}
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden smDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
          </div>
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </div>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};