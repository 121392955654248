import { container, title } from "assets/jss/material-kit-react.js";
import footer from '../../../img/footer.png';
import Car from '../../../img/car_1.png';
import BgImage from '../../../img/bg_image.png';

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
    // display: 'flex',
    // "@media (max-width: 480px)": {
    //   display: 'block'
    // },
    // alignSelf: 'center'
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  main2: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised2: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  items: {
    margin: 0,
    width: '98%',
    padding: 40,
    fontSize: 20,
    fontWeight: '600'
  },
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15
  },
  bodyPaper: {
    width: '30%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 10,
    height:300,
    display:'flex',
    alignSelf:'center',
    "@media (max-width: 480px)": {
      width: '100%',
    }
  },
  buttonPaper: {
    marginTop: 15,
    textTransform: 'capitalize',
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
    width: 100
  },
  bodyPaper2: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-20px',
    right: '-20px',
    overflow: 'scroll',
    "@media (max-width: 480px)": {
      right: 0,
    },
  },
  menuItems: {
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#E8E8E8",
    fontSize: 16,
    padding: '10px 2px 10px 10px',
    color: "#000"
  },
  inputdimmed: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#E8E8E8",
    fontSize: 16,
    padding: '10px 26px 10px 10px',
    color: "#737373"
  },
  commonInputStyle: {
    borderRadius: 4,
    backgroundColor: "#E8E8E8",
  },
  carphoto: {
    height: '16px',
    marginRight: '10px'
  },
  book_cab: {
    "@media (max-width: 480px)": {
      marginTop: 0,
      marginLeft: 0
    },
    // height: 210,
    marginTop: 75,
    marginLeft: 30,
    objectFit: 'contain',
    maxWidth: '100%',
  },
  gridContainer: {
    backgroundColor: '#363e7d',
    height: '100px',
    width: "100%",
    marginTop: 0
  },
  footerImageStyle: {
    backgroundImage: `linear-gradient(to bottom, rgba(47, 55, 187, 0.3), rgba(47, 55, 187, 0.3)), url(${footer})`,
    height: 'auto',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // opacity: 0.3,
    marginTop: 10
  },
  carStyle: {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${Car})`,
    height: '40vw',
    "@media (max-width: 480px)": {
      height: '700px',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: 145,
    width: '100%'
  },
  bgimageStyle: {
    backgroundImage: `linear-gradient(to bottom, rgba(47, 55, 187, 0.3), rgba(47, 55, 187, 0.3)), url(${BgImage})`,
    height: '755px',
    "@media (max-width: 480px)": {
      height: '1150px',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    // marginTop:20
  },
  con: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    "@media (min-width: 576px)": {
      maxWidth: "97%"
    },
    "@media (min-width: 768px)": {
      maxWidth: "95%"
    },
    "@media (min-width: 992px)": {
      maxWidth: "95%"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "80%"
    }
  },
  middleStyle: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    paddingTop: 110,
    paddingBottom: 60,
    ...container
  },
  typoStyle1: {
    fontSize: '43px',
    color: '#0d0f39',
    fontWeight: 600,
    fontFamily: "Poppins",
    textAlign: 'center',
    lineHeight: '50px',
    //paddingTop: 110
  },
  typoStyle2: {
    fontSize: '20px',
    color: '#a0a1ae',
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: 'center',
    paddingBottom: 70
  },
  tabSection: {
    borderRadius: 10,
    padding: 20,
    backgroundColor: '#f2f2f2'
  },
  paper: {
    fontSize: '30px',
    lineHeight: '40px',
    color: '#0d0f39',
    fontWeight: 600,
    fontFamily: "Poppins"
  },
  paper1: {
    fontSize: '16px',
    color: '#b4b5c6',
    fontWeight: 300,
    fontFamily: "Poppins",
  },
  paper2: {
    fontSize: '16px',
    lineHeight: '27px',
    color: '#494a5a',
    fontWeight: 600,
    fontFamily: "Poppins",
    marginTop: 20,
    height: '120px'
  },
  grid: {
    backgroundColor: '#ffffff',
    margin: 10
  },
  innerIcon: {
    marginRight: 40,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    width: 80,
    height: 60
  },
  button: {
    fontSize: '23px',
    letterSpacing: '-1px',
    lineHeight: '35px',
    color: '#ffffff',
    fontWeight: 600,
    fontFamily: "Poppins",
    height: '52px',
    borderRadius: '26px',
    backgroundColor: '#d9d9e5',
    textTransform: 'capitalize',
    marginLeft: 15,
    marginBottom: 40
  },
  button1: {
    fontSize: '23px',
    letterSpacing: '-1px',
    lineHeight: '35px',
    color: '#ffffff',
    fontWeight: 600,
    fontFamily: "Poppins",
    height: '52px',
    borderRadius: '26px',
    //backgroundColor: '#d9d9e5',
    textTransform: 'capitalize',
    marginLeft: 15,
    marginBottom: 40
  },
  buttonGrid: {
    justifyContent: 'center',
    "@media (max-width: 480px)": {
      display: 'block',
    },
    display: 'flex'
  },
  card: {
    width: '565px',
    "@media (max-width: 480px)": {
      marginTop: 0,
      width: '100%'
    },
    marginTop: 100,
    padding: 10
  },
  book_cab1: {
    objectFit: 'contain',
    maxWidth: '100%',
  },
  book_cab2: {
    objectFit: 'contain',
    //maxWidth: '100%',
    "@media (max-width: 480px)": {
      maxWidth: '100%',
    },

  },
  whiteText: {
    fontSize: '43px',
    letterSpacing: -'1px',
    lineHeight: '50px',
    color: '#ffffff',
    fontWeight: 600,
    fontFamily: "Poppins",
    textAlign: 'center',
    paddingTop: 60
  },
  whiteSmall: {
    fontsize: '17px',
    letterSpacing: '1px',
    color: '#ffffff',
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: 'center'
  },
  address: {
    fontSize: '30px',
    lineHeight: '40px',
    color: '#ffffff',
    fontWeight: 600,
    fontFamily: "Poppins",
    marginLeft: 40,
  },
  whiteSmall1: {
    fontsize: '17px',
    letterSpacing: '0px',
    color: '#ffffff',
    fontWeight: 400,
    fontFamily: "Poppins",
    marginLeft: 40,
    marginTop: 15,
    width: 250
  },
  callcon: {
    ...container,
    marginTop: 100,
  },
  callcon1: {
    ...container,
    // //display: 'flex',
    //height: 740
  },
  callcon2: {
    display: 'flex',
    // "@media (max-width: 480px)": {
    //   display: 'block'
    // },
  },
  split: {
    display: 'flex',
    // "@media (max-width: 480px)": {
    //   justifyContent: 'flex-end'
    // },
    justifyContent: 'center'
  },
  appText: {
    fontSize: '20px',
    color: '#a0a1ae',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  darkText: {
    // width: '553px',
    //height: '175px',
    fontSize: '17px',
    lineHeight: '27px',
    color: '#494a5a',
    fontFamily: "Poppins",
    fontWeight: 400,
    marginLeft: 10
  },
  orderTaxi: {
    fontSize: '43px',
    color: '#0d0f39',
    fontWeight: 600,
    fontFamily: "Poppins",
    lineHeight: '50px',
    marginLeft: 10,
    letterSpacing: '-1px'
    //paddingTop: 110
  },
  orderTaxi1: {
    fontSize: '20px',
    color: '#a0a1ae',
    fontWeight: 400,
    fontFamily: "Poppins",
    paddingBottom: 50,
    marginLeft: 10,
    marginTop: 10
  },
  bookTaxi: { color: 'white', fontWeight: 400, fontSize: '17px', marginTop: 120, marginLeft: 10, fontFamily: "Poppins" },
  numberStyle: { color: 'white', fontWeight: 700, fontSize: '51px', marginLeft: 10, fontFamily: 'Poppins' },
  quickStyle: { color: 'white', fontWeight: 600, fontSize: '18px', fontFamily: 'Poppins' },
  listStyle: { color: 'white', fontSize: '16px', marginLeft: -15, fontFamily: 'Poppins', fontWeight: 400 },
  customerStyle: { color: 'white', marginLeft: 10, fontSize: '20px', fontWeight: 600, justifyContent: 'flex-start', fontFamily: "Poppins" },
  supportStyle: { color: 'white', fontSize: '20px', fontWeight: 600, justifyContent: 'center', fontFamily: "Poppins" },
  ratedStyle: { color: 'white', fontSize: '20px', fontWeight: 600, justifyContent: 'flex-end', fontFamily: "Poppins" }
};


export default landingPageStyle;