import "assets/scss/material-kit-react.scss?v=1.9.0";

import {
  FirebaseProvider,
  store
} from "common";
import { Route, Router, Switch } from "react-router-dom";

import AboutUs from "./views/AboutUs";
import AddBookings from './views/AddBookings';
import AddMoney from "./views/AddMoney";
import AuthLoading from './views/AuthLoading';
import BookingHistory from './views/BookingHistory';
import CancellationReasons from './views/CancellationReasons';
import CarTypes from './views/CarTypes';
import Dashboard from './views/Dashboard';
import DriverEarning from './views/DriverEarning';
import Drivers from './views/Drivers';
import DriversStatus from './views/DriversStatus';
import Earningreports from './views/Earningreports';
import Fare from './views/Fare';
import FleetAdmins from './views/FleetAdmins';
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import MyProfile from './views/MyProfile';
import Notifications from './views/Notifications';
import Operators from './views/Operators';
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import Promos from './views/Promos';
import ProtectedRoute from './views/ProtectedRoute';
import { Provider } from "react-redux";
import React from "react";
import RegisterPage from './views/RegisterPage';
import Riders from './views/Riders';
import Settings from './views/Settings';
import Terms from "./views/Terms.js";
import Transactions from './views/Transactions';
import UpcomingBooking from './views/UpcomingBooking';
import Vehicles from './views/Vehicles';
import Withdraws from './views/Withdraws';
import { createBrowserHistory } from "history";
import { features } from 'config';

var hist = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <FirebaseProvider>
        <AuthLoading>
          <Router history={hist}>
            <Switch>
              <ProtectedRoute exact component={BookingHistory} path="/bookings" permit={"rider,admin,driver,fleetadmin,operator"} />
              <ProtectedRoute exact component={MyProfile} path="/profile" permit={"rider,admin,driver,fleetadmin,operator"} />
              <ProtectedRoute exact component={Dashboard} path="/dashboard" permit={"admin,fleetadmin,operator"} />
              <ProtectedRoute exact component={CarTypes} path="/cartypes" permit={"admin"} />
              <ProtectedRoute exact component={CancellationReasons} path="/cancelreasons" permit={"admin"} />
              <ProtectedRoute exact component={AddBookings} path="/addbookings" permit={"admin"} />
              <ProtectedRoute exact component={Promos} path="/promos" permit={"admin"} />
              <ProtectedRoute exact component={Riders} path="/riders" permit={"admin"} />
              <ProtectedRoute exact component={Drivers} path="/drivers" permit={"admin,fleetadmin,operator"} />
              <ProtectedRoute exact component={Operators} path="/operators" permit={"admin,fleetadmin"} />
              <ProtectedRoute exact component={Vehicles} path="/vehicles" permit={"admin,fleetadmin,operator"} />
              <ProtectedRoute exact component={FleetAdmins} path="/fleetadmins" permit={"admin"} />
              <ProtectedRoute exact component={DriverEarning} path="/driverearning" permit={"admin,fleetadmin"} />
              <ProtectedRoute exact component={Notifications} path="/notifications" permit={"admin"} />
              <ProtectedRoute exact component={Earningreports} path="/earningreports" permit={"admin"} />
              <ProtectedRoute exact component={AddMoney} path="/addtowallet" permit={"admin"} />
              <ProtectedRoute exact component={Withdraws} path="/withdraws" permit={"admin"} />
              <ProtectedRoute exact component={Settings} path="/settings" permit={"admin"} />
              <ProtectedRoute exact component={Fare} path="/fare" permit={"admin,fleetadmin"} />
              <ProtectedRoute exact component={UpcomingBooking} path="/upcomingbookings" permit={"admin,fleetadmin"} />
              <ProtectedRoute exact component={DriversStatus} path="/driverstatus" permit={"admin,fleetadmin"} />
              <ProtectedRoute exact component={Transactions} path="/transactions" permit={"admin,fleetadmin"} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms" component={Terms} />
              {features.WebsitePagesEnabled ?
                <Route path="/register" component={RegisterPage} />
                : null}
              {features.WebsitePagesEnabled ?
                <Route path="/login" component={LoginPage} />
                : null}
              {features.WebsitePagesEnabled ?
                <Route path="/" component={LandingPage} />
                :
                <Route path="/" component={LoginPage} />
              }
            </Switch>
          </Router>
        </AuthLoading>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;